import React, {useState, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useForumList } from "../common/ForumListContext";
import { get } from "jquery";
import { apiPath } from "../../config";

function GenerateResponse(props) {
    const { user, getIdTokenClaims } = useAuth0();
    const [processingRequest, setProcessingRequest] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [forumName, setForumName] = useState('');
    const [newResponseID, setNewResponseID] = useState('');
    const {forumIds} = useForumList();

    const MAX_RETRIES = 3; // You can adjust the number of retries as needed
    let retryCount = 0;

    const requestResponse = async () => {
      try {
        const getidToken = await getIdTokenClaims();
                const idToken = getidToken.__raw;
            //   console.log('idToken-', idToken);
          
              const options = {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  'Content-Type': 'application/json'
                },
              };
        setProcessingRequest(true);
        
        setTimeout(() => {
          document.getElementById("searchingLoader").style.display = "none";
          document.getElementById("tagsLoader").style.display = "block";
        }, 3000);
    
        setTimeout(() => {
          document.getElementById("tagsLoader").style.display = "none";
          document.getElementById("boardsLoader").style.display = "block";
        }, 6000);
    
        setTimeout(() => {
          document.getElementById("boardsLoader").style.display = "none";
          document.getElementById("responseLoader").style.display = "block";
        }, 9000);
    
        const path = `/generate_response?thread_id=${props.threadId}`;
        const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
        // console.log(result.response_id)
        // Check if the response_id is defined
        if (result.response_id !== undefined) {
          // console.log("started");
          setNewResponseID(result.response_id);
          // getResponse(result.response_id);
        }
    
      } catch (error) {
        console.error("Error requesting response:", error);
      }
    };
    

    const getResponse = async () => {
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
    
        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
        };
    
        const getPath = `/response?response_id=${newResponseID}`;
        const url = `${apiPath()}${getPath}`;
        const response = await fetch(url, options);
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const getResult = await response.json();
        console.log('Response result:', getResult);
    
        if (getResult.response_id === null) {
          console.log(`Retrying (${retryCount + 1}/${MAX_RETRIES})...`);
          retryCount++;
          // You may want to add a delay between retries using setTimeout
          setTimeout(() => {
            getResponse();
          }, 10000); // Retry after 10 seconds (adjust as needed)
        } else {
          setResponseData(getResult);
          setProcessingRequest(false);
        }
      } catch (error) {
        console.error("Error getting response:", error);
        // Check if we should retry
        if (retryCount < MAX_RETRIES) {
          console.log(`Retrying (${retryCount + 1}/${MAX_RETRIES})...`);
          retryCount++;
          // You may want to add a delay between retries using setTimeout
          setTimeout(() => {
            getResponse();
          }, 5000); // Retry after 5 seconds (adjust as needed)
        } else {
          console.error("Max retries exceeded. Giving up.");
          // Handle the case where maximum retries are reached
        }
      }
    };
    

      useEffect(() => {
        if(newResponseID !== '') {
          getResponse();
        }
      }, [newResponseID]);

      useEffect(() => {
        if(responseData.response_id !== null) { 
            // props.fetchData();
            console.log("gene id", responseData.generator_id)
            props.setGeneratorId(responseData.generator_id)
            props.setResponsePostID(responseData.post_id)
            props.setResponseId(responseData.response_id)
            props.setResponseDate(responseData.date)
            props.setData(responseData.response_text);
            // console.log(responseData.date) 
            props.setResponseLinks(responseData.links);
            
        }
      }, [responseData]);
      useEffect(() => {
       
        try {
            const forumsArray = forumIds;
      
            const selectedForumId = Number(props.selectedForumId); // Replace with the forum_id you want to find
      
            const matchedForum = forumsArray.find((forum) => forum.forum_id === selectedForumId);
      
            if (matchedForum) {
              // console.log("Matched Forum:", matchedForum);
              setForumName(matchedForum.name);
            } else {
              // console.log("Forum not found with forum_id:", selectedForumId);
              setForumName("");
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
      },[props.selectedForumId]);

      const styles = {
        responseItems:{
            display: 'none',
        }
      };
      useEffect(() => { 
        requestResponse();
        }
        , []);

    return (
        <div>
            <div className="response-wrapper">
            {/* <div className="response-main-head">
                Proposed responses
            </div> */}
                <div className="generate-response-wrap">
                {processingRequest ?  (
                    <div className="response-generator-loader">
                          <div className="chat-loader">
                              <span className="bar"></span>
                              <span className="bar"></span>
                              <span className="bar"></span>
                          </div>
                          <div id="searchingLoader" className="processing loading-text">Searching {forumName}...</div>    
                        <div id="tagsLoader" className="processing loading-text" style={styles.responseItems}>Analyzing Tags ...</div>   
                        <div id="boardsLoader" className="processing loading-text" style={styles.responseItems}>Analyzing Boards ...</div>              
                        <div id="responseLoader" className="generating loading-text" style={styles.responseItems}>Generating response...</div>
                        
                    </div>                 
                    ):(<div></div>)}
                </div>
            </div>
        </div>
    )
}

export default GenerateResponse;