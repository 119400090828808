import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { isEmptyObject } from "jquery";
import MainAppWrap from "../../../layout/MainAppWrap";
import SimpleHeader from "../../SimpleHeader";
import { Container, Row, Col, Toast } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import SettingsLoader from "../../common/SettingsLoader";
import { apiPath } from "../../../config";

function UpdateKsc(props) {
    const navigate = useNavigate();
  const { ksc_id } = useParams();
  const { getIdTokenClaims, user } = useAuth0();
  const [kscDetails, setKscDetails] = useState([]);
  const [kscName, setKscName] = useState("");
  const [kscDescription, setKscDescription] = useState("");
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedConfidential, setSelectedConfidential] = useState(false);
  const [isLoadingKS, setIsLoadingKS] = useState(true);
  const [isLoadingKSC, setIsLoadingKSC] = useState(true);
  const [sources, setSources] = useState([]);
  const [kscId, setKscId] = useState(ksc_id);
  const [preSelectSource, setPreSelectSource] = useState([]);

  const handleSelectedSource = (selectedOptions) => {
    setSelectedSource(selectedOptions);
  };

  const getKsc = async () => {
    try {
      setIsLoadingKSC(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source_configuration/${ksc_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setKscDetails(result);
      // console.log(result);
      setKscName(result.name);
      setKscDescription(result.description);
      setPreSelectSource(result.ks_ids);
      setSelectedConfidential(result.confidential);

      setIsLoadingKSC(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKSC(false);
    }
  };

  const fetchSources = async () => {
    try {
      setIsLoadingKS(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/ks_list`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log("ks- ", result);
      setSources(result);
      setIsLoadingKS(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKS(false);
    }
  };

  useEffect(() => {
    if (kscDetails.length === 0 && user) {
      getKsc();
    }
    if (sources.length === 0 && user) {
      fetchSources();
    }
  }, [user, ksc_id]);

  useEffect(() => {
    if (preSelectSource.length > 0) {
      const selected = sources.filter((source) =>
        preSelectSource.includes(source.ks_id)
      );
      setSelectedSource(
        selected.map((source) => ({
          value: source.ks_id,
          label: source.name,
        }))
      );
    }
  }, [preSelectSource, sources]);

  const toastHandler = (message, type) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const UpdateKsc = async (data) => {
    try {
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      // console.log(idToken);
      const path = `/knowledge_source_configuration?kscid=${kscId}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      toast.update(toastid, {
        render: "Knowledge source configration updated successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      document
        .getElementById("ksc-submit-btn-update")
        .classList.remove("disabled");
      setKscName("");
      setKscDescription("");
      setSelectedSource([]);
      setSelectedConfidential(false);
        navigate("/settings/knowledge-source-configurations");
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Something went wrong!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      document
        .getElementById("ksc-submit-btn-update")
        .classList.remove("disabled");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // add validation here
    if (kscName.length === 0) {
      toastHandler("Please input source name", "error");
      return;
    }
    if (kscDescription.length === 0) {
      toastHandler("Please input source description", "error");
      return;
    }
    if (selectedSource.length === 0) {
      toastHandler("Please select source", "error");
      return;
    }

    if (e.target.classList.contains("disabled")) {
      return null;
    }
    e.target.classList.add("disabled");

    let data = {};
    data.name = kscName;
    data.description = kscDescription;
    data.ks_ids = selectedSource.map((ksc) => Number(ksc.value));
    data.confidential = selectedConfidential;
    data = JSON.stringify(data);
    // console.log(selectedConfidential);
    // console.log(data);
    UpdateKsc(data);
  };

  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader />
        <Container fluid="xxl" className="ks-form">
          <Row>
            <Col className="settings-header">
              <h1>Edit Knowledge Source Configuration</h1>
            </Col>
          </Row>
          <Row>
            {!isLoadingKS && !isLoadingKSC && (
              <Col>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="source-name">
                        Source name:
                        <input
                          type="text"
                          className="form-control"
                          id="conf-source-name"
                          name="conf-source-name"
                          placeholder=""
                          value={kscName}
                          onChange={(e) => setKscName(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="source-name">
                        Description:
                        <input
                          type="text"
                          className="form-control"
                          id="conf-source-des"
                          name="conf-source-des"
                          placeholder=""
                          value={kscDescription}
                          onChange={(e) => setKscDescription(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="source-type">
                        Select Source:
                        <Select
                          id="select-source"
                          onChange={handleSelectedSource}
                          value={selectedSource}
                          options={sources.map((source, index) => ({
                            value: source.ks_id,
                            label: source.name,
                          }))}
                          isSearchable
                          placeholder="Select Source"
                          isMulti
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <div className="col-xs-12 custom-response-checkbox td-relative">
                        <label htmlFor="pop-source-confidentials-con-update">
                          <span className="checkbox-label">Confidential</span>
                          <input
                            type="checkbox"
                            id="pop-source-confidentials-con-update"
                            name="pop-source-confidentials-con-update"
                            checked={selectedConfidential}
                            onChange={(e) =>{
                            if(kscDetails.must_stay_confidential === true){
                              toast.error("This source must stay confidential");
                              return;
                            }
                              setSelectedConfidential(e.target.checked)
                            }
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="delete-user-modal-footer">
                    <Link to="/settings/knowledge-source-configurations" className="delete-user-modal-btn cancel">Cancel</Link>
                      <a
                        href="#"
                        id="ksc-submit-btn-update"
                        className="delete-user-modal-btn update-btn"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Update
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {isLoadingKS && <SettingsLoader />}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </MainAppWrap>
  );
}

export default UpdateKsc;
