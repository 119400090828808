import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import SimpleHeader from "../../SimpleHeader";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MainAppWrap from "../../../layout/MainAppWrap";
import { apiPath } from "../../../config";

function AddKsc(props) {
    const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [kscName, setKscName] = useState("");
  const [kscDescription, setKscDescription] = useState("");
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedConfidential, setSelectedConfidential] = useState(false);
    const [sources, setSources] = useState([]);
    const [isLoadingKS, setIsLoadingKS] = useState(false);

  const handleSelectedSource = (selectedOptions) => {
    setSelectedSource(selectedOptions);
  };

  const toastHandler = (message, type) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const fetchSources = async () => {
    try {
      setIsLoadingKS(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
       const options = {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${idToken}`,
           'Content-Type': 'application/json'
         },
       };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/ks_list`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log("ks- ",result);
      setSources(result);
      setIsLoadingKS(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKS(false);
    }
  };

  useEffect(() => {
    if (sources.length === 0 && user) {
      fetchSources();
    }
    }, [user]);

  const PutKsc = async (data) => {
    try {
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source_configuration`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      toast.update(toastid, {
        render: "Knowledge source configration added successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      document.getElementById("ksc-submit-btn").classList.remove("disabled");
      setKscName("");
      setKscDescription("");
      setSelectedSource([]);
      setSelectedConfidential(false);
      navigate("/settings/knowledge-source-configurations");
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Something went wrong!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      document.getElementById("ksc-submit-btn").classList.remove("disabled");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // add validation here
    if (kscName.length === 0) {
      toastHandler("Please input source name", "error");
      return;
    }
    if (kscDescription.length === 0) {
      toastHandler("Please input source description", "error");
      return;
    }
    if (selectedSource.length === 0) {
      toastHandler("Please select source", "error");
      return;
    }

    // check if selected source list is confidential 
    let anyConfidential = sources.filter((source) => { 
      return selectedSource.some((selected) => selected.value === source.ks_id && source.confidential === true);
    });
    if (anyConfidential.length > 0 && !selectedConfidential) {
      toastHandler("Selected KS is confidential. Create a confidential KSC to add confidential KS.", "error");
      return;
    }


    if (e.target.classList.contains("disabled")) {
      return null;
    }
    e.target.classList.add("disabled");

    let data = {};
    data.name = kscName;
    data.description = kscDescription;
    data.kss = selectedSource.map((ksc) => ksc.value);
    data.confidential = selectedConfidential;
    data = JSON.stringify(data);
    // console.log(data);
    PutKsc(data);
  };

  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader />
        <Container fluid="xxl" className="ks-form">
          <Row>
            <Col className="settings-header">
              <h1>Create Source Configuration</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row">
                <div className="input-group">
                  <label htmlFor="source-name">
                    Configuration Name:
                    <input
                      type="text"
                      className="form-control"
                      id="conf-source-name"
                      name="conf-source-name"
                      placeholder=""
                      value={kscName}
                      onChange={(e) => setKscName(e.target.value)}
                    />
                  </label>
                </div>
                <div className="input-group">
                  <label htmlFor="source-name">
                    Description:
                    <input
                      type="text"
                      className="form-control"
                      id="conf-source-des"
                      name="conf-source-des"
                      placeholder=""
                      value={kscDescription}
                      onChange={(e) => setKscDescription(e.target.value)}
                    />
                  </label>
                </div>
                <div className="input-group">
                  <label htmlFor="source-type">
                    Select Source:
                    <Select
                      id="select-source"
                      onChange={handleSelectedSource}
                      value={selectedSource}
                      options={sources.map((source, index) => ({
                        value: source.ks_id,
                        label: source.name,
                      }))}
                      isSearchable
                      placeholder="Select Source"
                      isMulti
                    />
                  </label>
                </div>
                <div className="input-group">
                  <div className="col-xs-12 custom-response-checkbox td-relative">
                    <label htmlFor="pop-source-confidentials-con">
                      <span className="checkbox-label">Confidential</span>
                      <input
                        type="checkbox"
                        id="pop-source-confidentials-con"
                        name="pop-source-confidentials"
                        checked={selectedConfidential}
                        onChange={(e) =>
                          setSelectedConfidential(e.target.checked)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div className="delete-user-modal-footer">
                <Link to="/settings/knowledge-source-configurations" className="delete-user-modal-btn cancel">Cancel</Link>
                  <a
                    href="#"
                    id="ksc-submit-btn"
                    className="delete-user-modal-btn done"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </MainAppWrap>
  );
}

export default AddKsc;
