import React from "react";

function SettingsLoader(props) {
    return(
        <div className='settings-loader'>
            <div className="chat-loader">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
        </div>
    );
}

export default SettingsLoader;