import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../layout/MainAppWrap";
import SimpleHeader from "./SimpleHeader";
import ghostImgae from "../images/ghost.svg";


function Ghost(props) {
    return (
        <MainAppWrap>
        <div className="main-content response-detail-page">
          <SimpleHeader />
          <Container fluid="xxl">
            <Row>
              <Col>
                <div className="ghost-box">
                    <img src={ghostImgae} alt="Ghost" />
                    {props.title && (<h3>{props.title}</h3>)}
                    {props.description && (<div dangerouslySetInnerHTML={{ __html: props.description }}></div>)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </MainAppWrap>
    );
}

export default Ghost;