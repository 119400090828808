import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Threads from "./Threads";
import HeroFilter from "./HeroFilter";
import ThreadListV2 from "./ThreadListV2";
import FiltersV2 from "./FiltersV2";
import { useAuth0 } from "@auth0/auth0-react";
import {useParams, useNavigate} from 'react-router-dom';
import { useForumList } from '../common/ForumListContext';
import { apiPath } from "../../config";

function Dashboard(props){
    const navigate = useNavigate();
    const { forumIds, selectedForumId, setSelectedForumId, setSelectForumName, selectForumName } = useForumList();
    const { forumid } = useParams();
    const {pagenumber} = useParams();
    const {threadid} = useParams();
    const {forumname} = useParams();
    const { getIdTokenClaims } = useAuth0();
    const [allthreads, setAllthreads] = useState(true);
    const [noresponse, setNoresponse] = useState(false);
    const [unsolved, setUnsolved] = useState(false);
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [boards, setBoards] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState([]);
    const [postNumbers, setPostNumbers] = useState([]);
    const [selectedPostNum, setSelectedPostNum] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [threadId, setThreadId] = useState('');
    const [threadsDate, setTthreadsDate] = useState(1);
    const [switchlisttoThread, setSwitchlisttoThread] = useState(false);
    const [currentPage, setCurrentPage] = useState(pagenumber);
    const [selectedFilter, setSelectedFilter] = useState("all_filter");
    const [threadsResultMessage, setThreadsResultMessage] = useState("");
    const [selectedSort, setSelectedSort] = useState("last_post_date");

    useEffect(() => {
        if(forumid !== undefined && forumid !== null) {
            setSelectedForumId(forumid);
        }
        if(forumIds && forumIds.length > 0) {
            const forum = forumIds.find(forum => forum.value === Number(forumid));
            if(forum) {
                setSelectForumName(forum.label);
            }
        }
    }, [forumid, forumIds, threadid]);

    const getFiltersList = async () => {
        try {
          // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
      //   console.log('idToken-', idToken);
    
        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
        };
        const getPath = `/tags_and_boards?forum_id=${selectedForumId}`;
        const url = `${apiPath()}${getPath}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const getResult = await response.json();
             
        //  console.log("new filters: ",getResult);
         setTags(getResult.tags);
            setBoards(getResult.boards);
          
        } catch (error) {
          console.error("Error getting filters list:", error);
        }
      };
    useEffect(() => {
        if(forumid !== '' && forumid !== null) {
            setSelectedTag([]);
            setSelectedBoard([]);
            setSelectedSort("last_post_date");
            setSelectedFilter("all_filter");
            setAllthreads(true);
            setUnsolved(false);
            setNoresponse(false);
            setThreadId("");
            setSwitchlisttoThread(false);
            setCurrentPage(1);
            getFiltersList();
        }
    }, [forumid]);
  
    const handleTagChange = (selectedOptions) => {
        console.log(selectedOptions);
        setSelectedTag(selectedOptions);
      };

    const handleBoardChange = (selectedOptions) => {
        setSelectedBoard(selectedOptions);
      };
  
    const handlePostNumChange = (event) => {
        setSelectedPostNum(event.target.value);
    };
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleThreadlist = (val) => {
        setThreadId(val)
    };
    const handleThreadDate = (val) => {
        setTthreadsDate(val)
    };

    const handleSortChange = (selectedOption) => {
        setSelectedSort(selectedOption.value);
    };
    useEffect(() => {
        if(threadId !== '' && threadId !== null){
          navigate(`/thread_id/${threadId}`);
        }
      },[threadId]);
    useEffect(()=>{
        console.log(threadId, threadsDate, unsolved, selectedTag, selectedBoard, noresponse, selectedSort, allthreads);
    }, [threadId, threadsDate, unsolved, selectedTag, selectedBoard, noresponse, selectedSort, allthreads]);
    return (
        <div>
            <Container fluid className="">
                <Container fluid="xxl">
                    <Row>
                        <Col className="no-padding">
                            <HeroFilter
                            selectedForumId={selectedForumId}
                            selectForumName={selectForumName}
                            handleForumChange={props.handleForumChange}
                            forumIds={forumIds}
                            setThreadId={setThreadId}
                            setSwitchlisttoThread={setSwitchlisttoThread}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid="xxl" className="dashboard-container">
                
                    {!switchlisttoThread && (
                        <Row>
                    <Col sm={12}>
                    <FiltersV2
                        unsolved={unsolved}
                        setUnsolved={setUnsolved}
                        selectedTag={selectedTag}
                        setSelectedTag={setSelectedTag}
                        handleTagChange={handleTagChange}
                        selectedBoard={selectedBoard}
                        setSelectedBoard={setSelectedBoard}
                        handleBoardChange={handleBoardChange}
                        noresponse={noresponse}
                        setNoresponse={setNoresponse}
                        tags={tags}
                        setTags={setTags}
                        boards={boards}
                        setBoards={setBoards}
                        selectedForumId={selectedForumId}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={setSelectedFilter}
                        threadsResultMessage={threadsResultMessage}
                        selectedSort={selectedSort}
                        handleSortChange={handleSortChange}
                        setAllthreads={setAllthreads}
                        setCurrentPage={setCurrentPage}
                    />
                    </Col>
                    {/* <Col sm={4}>

                    </Col> */}
                    </Row>
                    )}
                    
                    {!switchlisttoThread && ( 
                        <Row>
                    <Col sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <Row className="">
                                        <Col>
                                            <ThreadListV2
                                                selectedForumId={selectedForumId} 
                                                setThreadId={setThreadId}
                                                unsolved={unsolved}
                                                setUnsolved={setUnsolved}
                                                tags={tags}
                                                setTags={setTags}
                                                boards={boards}
                                                setBoards={setBoards}
                                                selectedTag={selectedTag}
                                                setSelectedTag={setSelectedTag}
                                                handleTagChange={handleTagChange}
                                                selectedBoard={selectedBoard}
                                                setSelectedBoard={setSelectedBoard}
                                                handleBoardChange={handleBoardChange}
                                                noresponse={noresponse}
                                                setNoresponse={setNoresponse}
                                                setSwitchlisttoThread={setSwitchlisttoThread}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                selectedFilter={selectedFilter}
                                                setSelectedFilter={setSelectedFilter}
                                                setThreadsResultMessage={setThreadsResultMessage}
                                                selectedSort={selectedSort}
                                                allthreads={allthreads}
                                                forumid={forumid}
                                                selectForumName={selectForumName}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        
                    </Col>
                    {/* <Col sm={4}>
                        <div className="info-box">
                            <div>
                                <img src={infoImage} alt="info-icon" />
                            </div>
                            <div>
                                 <h3>Response Studio</h3>
                            </div>
                            <div>
                                <p>Click on any thread in the left <br />to get the AI response</p>
                            </div>
                        </div>
                    </Col> */}
                    </Row>
                    )}
                    <Row>
                    <Col>
                    {/* {switchlisttoThread &&(
                         <Threads 
                         threadId={threadId} 
                         setThreadId={setThreadId}
                         selectedForumId={selectedForumId}  
                         selectedTag={selectedTag}
                         selectedBoard={selectedBoard}
                         unsolved={unsolved}
                         setSwitchlisttoThread={setSwitchlisttoThread}
                         />
                         )} */}
                         </Col>
                </Row>
               
            </Container>
        </div>
    );
}
export default Dashboard;