import React, {useEffect, useState} from "react";

function SourcesActionGrid({ item, userGroups, handleActions, handleActionButtons }) {

    return (
        <div className="sources-action-grid">
            {userGroups == "Admin" && (
                    <div className="table-action">
                      <a
                        href="#"
                        className="action_btn_dots"
                        onClick={handleActionButtons}
                      ></a>
                      <div className={`action_btns 
                      ${item.tags && item.tags.includes("boosted") ? "show-unboosted" : ""}
                      ${item.tags && item.tags.includes("blocked") ? "show-unblocked" : ""}
                        `}>

                          <a
                            href="#"
                            className="action_btn_unboost"
                            onClick={handleActions}
                            data-action="unboost"
                            data-item-url={item.url}
                            data-ks-id={item.ks_id}
                          >
                            Un-boost
                          </a>
                            <a
                              href="#"
                              className="action_btn_boost"
                              onClick={handleActions}
                              data-action="boost"
                              data-item-url={item.url}
                              data-ks-id={item.ks_id}
                            >
                              Boost
                            </a>
                          <a
                            href="#"
                            className="action_btn_unblock"
                            onClick={handleActions}
                            data-action="unblock"
                            data-item-url={item.url}
                            data-ks-id={item.ks_id}
                          >
                            Un-block
                          </a>

                            <a
                              href="#"
                              className="action_btn_block"
                              onClick={handleActions}
                              data-action="block"
                              data-item-url={item.url}
                              data-ks-id={item.ks_id}
                            >
                              Block
                            </a>
                      </div>
                    </div>
                  )}
        </div>
    );
}

export default SourcesActionGrid;