import React from "react";
import Table from "react-bootstrap/Table";
import SettingsLoader from "../../common/SettingsLoader";
import {convertDateIntoLocalDate} from "../../common/Functions";


function VersionsList(props) {

    const handleActionButtons = (e) => {
        e.preventDefault();
        let ele = e.target;
        let action_btns = ele.nextElementSibling;

        let other_action_btns = document.querySelectorAll('.action_btns');
        other_action_btns.forEach(function (ele) {
                ele.style.display = 'none';
            });
    
        // Check if the display property is either "none" or not set (empty string).
        if (action_btns.style.display === "block") {
            action_btns.style.display = "none";
        } else {
            // If it's "none" or empty, set it to "block"
            action_btns.style.display = "block";
        }
    };

    const transformString = (inputString) => {
        // Split the string by underscores, capitalize each word, and join them with spaces
        return inputString
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };
  return (
    <div>
      <div className="table-box versions-box-table">
        <Table hover> 
          <thead>
            <tr>
              <th className="table-name-th">Name</th>
                <th></th>
              <th>Ingest Date</th>
              <th>Stats</th>
              <th className="table_action">Action</th>
            </tr>
          </thead>
          <tbody>
            {props.versions &&
                props.versions.map((version, index) => (
                <tr key={index}>
                  <td className="table-name-td">{version.ingest_ks_event_id} </td>
                    <td>
                    {version.is_active && <span className="details-status">Active</span>}
                    </td>
                    <td>
                        {convertDateIntoLocalDate(version.ingest_date)}
                    </td>
                    <td>
                    {Object.entries(version.stats)
                        .map(([key, value]) => (
                          <span key={key}>
                            {key === "updated_at" ? (
                              <><b>{transformString(key)}:</b> <UnixDateConverter unixTimestamp={value}/></>
                            ) : (
                              <><b>{transformString(key)}:</b> {value.toLocaleString()}</>
                            )}
                            <br />
                          </span>
                        ))}
                    </td>
                  <td>
                  {!version.is_active && (
                    <div className="table-action">
                        <a href="#" className="action_btn_dots" onClick={handleActionButtons}></a>
                        <div className="action_btns" style={{display: "none"}}>
                            <a href="#" className="action_btn action_btn_activate" 
                            data-event-id={version.ingest_ks_event_id} 
                            onClick={(e)=>{props.handleVersions(e, "activate")}}>Activate</a>
                            <a href="#" className="action_btn action_btn_delete" 
                            data-event-id={version.ingest_ks_event_id}
                            onClick={(e)=>{props.handleVersions(e, "delete")}}>Delete</a>
                        </div>
                    </div>
                  )}
                  </td>
                </tr>
              ))}
              {/* If no items are found, display a message */}
              {/* {props.versions.length == 0 && (
              <tr>
                <td colSpan="7">
                    <div className="no-items">No Versions found</div>
                </td>
              </tr>
              )} */}
          </tbody>
        </Table>
        {/* {isLoadingItems && <SettingsLoader />} */}
        <div className="table-footer"></div>
      </div>
    </div>
  );
}

export default VersionsList;