import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { parseISO, startOfWeek, endOfWeek, format } from 'date-fns';

function UserFeedbackChartCom({ data }) {
    useEffect(() => {
        if (!data || !data.labels || data.labels.length === 0) {
            return; // Don't attempt to render the chart if there's no data
        }

        const chartDom = document.getElementById('feed-chart');
        const myChart = echarts.init(chartDom);

        // Given JSON data
        const jsonData = data;

        // Prepare the data
        const weekRanges = {};
        jsonData.labels.forEach((label, index) => {
            const date = parseISO(label);
            const startOfWeekDate = startOfWeek(date);
            const endOfWeekDate = endOfWeek(date);
            const weekRange = `${format(startOfWeekDate, 'MMM d')} - ${format(endOfWeekDate, 'd')}`;
            if (!weekRanges[weekRange]) {
                weekRanges[weekRange] = {};
            }

            Object.keys(jsonData.data).forEach(key => {
                if (!weekRanges[weekRange][key]) {
                    weekRanges[weekRange][key] = 0;
                }
                weekRanges[weekRange][key] += jsonData.data[key][index];
            });
        });

        const weekLabels = Object.keys(weekRanges);
        const colors = {
            response_likes: '#96D4E5',
            response_dislikes: '#F3934E',
            other: ['#053F98', '#4A558A', '#4D88A3']
        };

        const seriesData = Object.keys(jsonData.data).map((key, index) => {
            let color;
            if (key === 'response_likes') {
                color = colors.response_likes;
            } else if (key === 'response_dislikes') {
                color = colors.response_dislikes;
            } else {
                color = colors.other[index % colors.other.length];
            }

            return {
                name: key,
                type: 'bar',
                stack: 'total',
                data: weekLabels.map(week => weekRanges[week][key] || 0),
                itemStyle: {
                    color: color
                }
            };
        });

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow' // Default is 'line', but you can also set to 'shadow'
                }
            },
            legend: {
                data: seriesData.map(item => item.name), // Automatically use labels from the data
                type: 'scroll',
                orient: 'horizontal',
                top: 10,
                bottom: 'auto',
            },
            xAxis: {
                type: 'category',
                data: weekLabels
            },
            yAxis: {
                type: 'value'
            },
            series: seriesData
        };

        myChart.setOption(option);

        // Handle window resize
        const handleResize = () => {
            myChart.resize();
        };

        window.addEventListener('resize', handleResize);

        // Clean up the chart and event listener on unmount or when data changes
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', handleResize);
        };
    }, [data]);

    return <div id="feed-chart" style={{ height: 400, width: '100%' }}></div>;
}

export default UserFeedbackChartCom;
