import React, { useState, useEffect } from 'react';

import { useAuth } from '../components/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import logo from '../images/logo-black.svg';

function ForgotPassword(){
        const [username, setUsername] = useState('');
        const [isLoading, setIsLoading] = useState(false);
        const [alertMessage, setAlertMessage] = useState('');
        const [showAlert, setShowAlert] = useState(false);
        const navigate = useNavigate();

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoading(true); // set loading state to true
            try {
              await Auth.forgotPassword(username);
              console.log('Password recovery initiated successfully.');
              setIsLoading(false); // set loading state to false
              setShowAlert(true); // show the alert component
              setAlertMessage('Password recovery initiated successfully.');
              setUsername(''); // clear the username input
          
              
              navigate('/reset-password', { state: { username } });

            } catch (error) {
              setIsLoading(false); // set loading state to false
              console.log('Error forgot password', error);
              setShowAlert(true); // show the alert component
              setAlertMessage('Failed to initiate password recovery. Please recheck your username');
              // handle sign-in error
            }
          };
          
      const handleAlertDismiss = () => {
        setShowAlert(false); // hide the alert component
      };
    return (
        <div>
            <div className="container">
            <div className="row">
                <form className='sign_form' id="signinform" onSubmit={handleSubmit}>
                <div className='col-12 form-group text-center'>
                <img className='form-logo' src={logo} alt="logo" />
                </div>
                
                {showAlert && (
                    <div className='col-12 form-group'>
                        <Alert variant={alertMessage === 'Password recovery initiated successfully.' ? 'success' : 'danger'} onClose={handleAlertDismiss} dismissible>
                        {alertMessage}
                        </Alert>
                    </div>
                )}
                
                <div className='col-12 form-group'>
                    <label htmlFor="sign_user">Enter username:</label>
                    <input id="sign_user" className='form-control' type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </div>
                <div className='col-12 form-group'>
                    <Button disabled={isLoading} as="input" type="submit" value={isLoading ? "Submitting..." : "Submit"} />{' '}
                </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default ForgotPassword;