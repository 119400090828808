import React from "react";
import like_icon from "../../../images/like.svg";
import dislike_icon from "../../../images/dislike.svg";
import like_source from "../../../images/like_source.svg";
import dislike_source from "../../../images/dislike_source.svg";

function SourcesFeedbackGrid({ item, handleRanking }) {
  const likes = item.likes || 0; // Default to 0 if undefined
  const dislikes = item.dislikes || 0; // Default to 0 if undefined
  const currentUrl = window.location.href; // Full URL
  const pathname = window.location.pathname; // Pathname only
  const response_id = pathname.split('/').pop(); // Extract last segment
  
  

  return (
    <div className="sources-feedback-grid">
      <img
        onClick={handleRanking}
        className="table-like-img"
        data-id={response_id}
        data-value="like"
        data-url={item.url}
        src={item.user_feedback === "like" ? like_source : like_icon}
        alt="like icon"
      />{" "}
      {item.likes > 0 ? (
        <span className="item-likes">{item.likes}</span>
      ) : (
        <span className="item-likes"></span>
      )}
      <img
        onClick={handleRanking}
        data-id={response_id}
        data-value="dislike"
        data-url={item.url}
        className="table-dislike-img"
        src={item.user_feedback === "dislike" ? dislike_source : dislike_icon}
        alt="dislike icon"
      />{" "}
      {item.dislikes > 0 ? (
        <span className="item-dislikes">{item.dislikes}</span>
      ) : (
        <span className="item-dislikes"></span>
      )}
    </div>
  );
}

export default SourcesFeedbackGrid;
