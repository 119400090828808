import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Arrow } from '../images/v2/breadcrumb.svg';
import { Link, useNavigate } from "react-router-dom";

function BreadCrumbs({ crumbs }) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  }
  return (
    <div className="breadcrumbs">
      <Container fluid="xxl">
        <Row>
          <Col>
            <div>
              {crumbs.map((crumb, index) => (
                <span key={index}>
                  {crumb.url ? (
                    <a href="#" onClick={handleBack} className="breadcrumb-text">{crumb.label}</a>
                  ) : (
                    <span className="breadcrumb-text">{crumb.label}</span>
                  )}
                  {index < crumbs.length - 1 && <Arrow className="breadcrumb-sp" />}
                </span>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BreadCrumbs;
