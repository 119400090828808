import React from "react";
import { convertDateIntoLocalDate } from "../../common/Functions";
import { Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import "./css/ResponseDetailsHeader.css";

function ResponseDetailsHeader({ response }) {
    const { user } = useAuth0();
    console.log(response);

    return (
        <div className="response-details-header">
            <Row>
                <Col className="response-ids" xs={5}>
                    <div className="response-user-id">
                        <p>User</p>
                        <h3>{response.user}</h3>
                    </div>
                    <div className="response-id">
                        <p>Response ID</p>
                        <h3>{response.response_id}</h3>
                    </div>
                </Col>
                <Col className="response-channel-detail" xs={4}>
                    <div className="response-channel">
                        <p>Channel</p>
                        <h3>{response.channel_name}</h3>
                    </div>
                    <div className="conversation-id">
                        <p>Conversation ID</p>
                        <h3>{response.conversation_id}</h3>
                    </div>
                </Col>
                <Col className="response-tag" xs={3}>
                    <div>
                        <p>Tag</p>
                        <div className="response-details-tags">
                            {response.tags
                                .filter((tag) => tag.tag_type === 'action')
                                .map((tag, index) =>
                                tag.tag_value === 'Investigate' ? (
                                    <span key={`action-${index}`} className="tabletag for_review">
                                    For Review
                                    </span>
                                ) : null
                                )}

                            {/* Inline rendering for feedback tags */}
                            {response.tags
                                .filter((tag) => tag.tag_type === 'feedback')
                                .map((tag, index) =>
                                tag.feedback_value === true ? (
                                    <span key={`feedback-${index}`} className="tabletag resolved">
                                    Resolved
                                    </span>
                                ) : null
                                )}
                        </div>
                    </div>
                    <div className="response-date">
                        <p>Date</p>
                        <h3>{convertDateIntoLocalDate(response.date)}</h3>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ResponseDetailsHeader;
