import React, { useEffect, useState } from "react";
import { json, useSearchParams, useNavigate } from "react-router-dom";
import SettingsLoader from "../../common/SettingsLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../../config";
import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../../../layout/MainAppWrap";
import SimpleHeader from "../../../components/SimpleHeader";


function DownloadFile() {
    const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [searchParams] = useSearchParams();
  const item_url = searchParams.get("item_url");
  const ks_id = searchParams.get("ks_id");
  const filename = searchParams.get("filename");
  const redirect_url = searchParams.get("redirect_url");
  const [downloadableURL, setDownloadableURL] = useState(null);

  const getDownloadableURL = async (itemurl) => {
    try {
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      const path = `/ks_items?ks_id=${ks_id}&item_url=${itemurl}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      setDownloadableURL(result.url);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (!user) return;
    getDownloadableURL(item_url);
  }, [item_url, user, ks_id]);

  const autoDownloadInit = async () => {
    if (!downloadableURL) return;

    try {
        const response = await fetch(downloadableURL);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        
        const link = document.createElement("a");
        link.href = url;
        
        // Specify the filename for the downloaded file
        link.setAttribute("download", filename); // Replace "filename.pdf" with the desired filename and extension
        
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            navigate(redirect_url); // Uncomment if you want to navigate to another URL after download
        }, 1000);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};


  useEffect(() => {
    autoDownloadInit();
  }, [downloadableURL]);

  return (
    <MainAppWrap>
      <div className="main-content mont-font download-file-page">
        <SimpleHeader title="Downloading Item" />
        <Container fluid="xxl" className="">
          <Row>
            <Col>
                <p>Please wait! we are preparing your file for download!</p>
                <SettingsLoader />
            </Col>
          </Row>
        </Container>
      </div>
    </MainAppWrap>
  );
}

export default DownloadFile;
