import React from "react";

function MainLoader() {
    return(
        <div className='main-loader'>
            <div className="chat-loader">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
        </div>
    );
}

export default MainLoader;