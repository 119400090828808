import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SettingsLoader from "../components/common/SettingsLoader";

function FreeTrial() {
    const { getIdTokenClaims, user } = useAuth0();

    const getSession = async () => {
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const options = {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${idToken}`,
              'Content-Type': 'application/json'
            },
          };
          const path = `/create_checkout_session`;
          const url = `https://api.ept.ai/v2${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          window.location.href = result.redirect_url;
        } catch (error) {
          console.error("Error posting data:", error);
        }
      };
      useEffect(() => {
        if (user){
          getSession();
        }
        
      }, [user]);
    return(
      <div className="main-loader">
          <SettingsLoader />
      </div>
    );
}

export default FreeTrial;
