import React, { useEffect } from 'react';
import { useAuth } from './auth/AuthContext';

const PricingTable = () => {
  const { user } = useAuth();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1NDcSBLXnKdvsfU4iFHWtHCV"
      publishable-key="pk_live_51N1c48LXnKdvsfU4RkX3WzosBWrzmy2oKHviwmEq8VZ3ucTxDx3PXfJFmqFYoePCzD7I6k7XO8cd0Q5GVSIftzKB004CsIu3ut"
      client-reference-id={user?.attributes?.sub}
    />
  );
};

export default PricingTable;
