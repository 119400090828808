import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { json } from "react-router";
import { apiPath } from "../../config";

function AccountName(props) {
  const { getIdTokenClaims } = useAuth0();
  const [content, setContent] = useState(props.accountName || "");
  const [isEditing, setIsEditing] = useState(false);
  const [updatingName, setUpdatingName] = useState(false);

  const postAccountName = async () => {
    try {
      setUpdatingName(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
  //   console.log('idToken-', idToken);
      let postData = {
        account_name: content
      };
      postData = JSON.stringify(postData);
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: postData
    };
      const path = `/account_info`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      setUpdatingName(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setUpdatingName(false);
    }
  };

  const handleInput = (event) => {
    setContent(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      postAccountName();
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    setContent(props.accountName || "");
  };
  

  return (
    <div className="account-name">
        <div className="account-name-title">Account name:</div>
      {isEditing ? (
        <input
          type="text"
          value={content}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus
          className="account-name-input"
        />
      ) : (
        <div onClick={handleEditClick} className={content ? "edit-account-name":"edit-account-name edit-account-name-placeholder"}>{content || "Enter account name..."}</div>
      )}
      {updatingName && 
        <div className="loading-threads">
            <div className="loader loader-sm"></div>
        </div>
      }
    </div>
  );
}

export default AccountName;
