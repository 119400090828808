import React, { useState, useEffect } from "react";
import Select from "react-select";
import Loader from "../common/Loader";

function QuestionHero(props) {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(props.eptChannels.length > 0){
      setIsLoading(false);
    }else{
      setIsLoading(true);
    }
  }
  , [props.eptChannels]);
  return (
    <div className="hero-filter">
      <div className="hero-filter-title">
        {/* <div className="hero-text hero-text-center">Type a question about your products here to get an AI-generated answer based on the selected knowledge sources</div> */}
        {/* <p className="hero-p">You can choose the question from the threads below and AI studio response will create an awesome answer to that questions</p> */}
        
          {isLoading ? (
              <div className="ask-loader-wrap">
                Loading configuration... <Loader />
              </div>
          ):(
            <div className="choose-data-wrap">
              <div className="ask-data-choose">Choose data: </div>
              <Select
                // className="forum-select"
                
                id="forumID"
                onChange={props.handleForumChange}
                value={props.selectedChannel}
                options={props.eptChannels}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.channel}
                placeholder="Select a forum"
                isSearchable={true}
               />
               {props.switchingForum && (
                <div className="switchingForumLoader">
                <Loader />
                </div>
              )}
            </div>
            
          )}
      </div>
    </div>
  );
}

export default QuestionHero;
