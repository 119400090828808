import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../layout/MainAppWrap";
import SimpleHeader from "../components/SimpleHeader";
import Image404 from "../images/404.svg";

function Page404() {
  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader />
        <Container fluid="xxl">
          <div className="page-not-found">
            <Row>
              <Col>
                <img src={Image404} alt="404" className="img-fluid" />
              </Col>
            </Row>
            <Row>
              <Col>
                <h1>Page not found !</h1>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </MainAppWrap>
  );
}

export default Page404;
