import React, { useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";

function ResponseStudioBars(props) {
    const [length, setLength] = useState(0);
    const [expertise, setExpertise] = useState(0);
    const [specificity, setSpecificity] = useState(0);

    return (
        <div className="responseSliders">
            <div className="responseSliderItem">
                <RangeSlider
                    value={length}
                    onChange={changeEvent => setLength(changeEvent.target.value)}
                    min={-1}
                    max={1}
                />
                <div className="responseSliderItemLabel">
                    <span>Shorter</span>
                    <span>Longer</span>
                </div>
            </div>
            <div className="responseSliderItem">
                <RangeSlider
                    value={expertise}
                    onChange={changeEvent => setExpertise(changeEvent.target.value)}
                    min={-1}
                    max={1}
                />
                <div className="responseSliderItemLabel">
                    <span>Factual</span>
                    <span>Explain</span>
                </div>
            </div>
            <div className="responseSliderItem">
                <RangeSlider
                    value={specificity}
                    onChange={changeEvent => setSpecificity(changeEvent.target.value)}
                    min={-1}
                    max={1}
                />
                <div className="responseSliderItemLabel">
                    <span>Novice</span>
                    <span>Expert</span>
                </div>
            </div>
        </div>
    );
}

export default ResponseStudioBars;
