import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col, Form } from "react-bootstrap";
import MainAppWrap from "../../layout/MainAppWrap";
import SimpleHeader from "../SimpleHeader";
import SettingsLoader from "../common/SettingsLoader";
import { toast, ToastContainer } from "react-toastify";
import { apiPath } from "../../config";
import Select from "react-select";
import { se } from "date-fns/locale";

function KscDetails() {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [kscDetails, setKscDetails] = useState([]);
  const { ksc_id } = useParams();
  const [activeVersion, setActiveVersion] = useState("");

  const [isLoadingKS, setIsLoadingKS] = useState(true);

  // Add state variables for inline editing
  const [editingName, setEditingName] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [editingKs, setEditingKs] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newConfidential, setNewConfidential] = useState("");
  const [selectedSource, setSelectedSource] = useState([]);
const [sources, setSources] = useState([]);
const [preSelectSource, setPreSelectSource] = useState([]);
const [showError, setShowError] = useState(false);

  const getKsc = async () => {
    try {
      setIsLoadingKS(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source_configuration/${ksc_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
        console.log(result);
      setKscDetails(result);
      setNewName(result.name);
      setNewDescription(result.description);
      setNewConfidential(result.confidential);
      setPreSelectSource(result.ks_ids);
      setIsLoadingKS(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKS(false);
      setShowError(true);
    }
  };

  const fetchSources = async () => {
    try {
      setIsLoadingKS(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/ks_list`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log("ks- ", result);
      setSources(result);
      setIsLoadingKS(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKS(false);
    }
  };

  const UpdateKsc = async (data) => {
    try {
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source_configuration?kscid=${ksc_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      toast.update(toastid, {
        render: "Knowledge source configuration updated successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setKscDetails(result);

      setEditingName(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInlineEdit = (type, value) => {

    // e.target.classList.add("disabled");

    let data = {};
    if (type === "name") {
      data.name = value;
    }
    if (type === "description") {
      data.description = value;
    }
    if (type === "confidential") {
      data.confidential = value;
    }
    if (type === "ks") {
        data.ks_ids = value.map((ksc) => Number(ksc.value));
    }
    data = JSON.stringify(data);
    // console.log(data);
    UpdateKsc(data);
  };

  useEffect(() => {
    if (kscDetails.length === 0 && user) {
        getKsc();
    }
    if (sources.length === 0 && user) {
      fetchSources();
    }
  }, [user, ksc_id]);

  const handleDeleteWarning = (e) => {
    e.preventDefault();
    document.getElementById("delete_user_modal-alert").classList.add("show");
  };
  const handleCancelDeleteKS = (e) => {
    e.preventDefault();
    document.getElementById("delete_user_modal-alert").classList.remove("show");
  };
  
  
  const handleDelete = async (e) => {
  
    e.preventDefault();
    if (e.target.classList.contains("disabled")) {
        return null;
    }
    e.target.classList.add("disabled");
    try {
        const toastid = toast.loading("Please wait...", {
            position: toast.POSITION.BOTTOM_RIGHT
            })
        // Get the ID token from Auth0
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
         const options = {
           method: 'DELETE',
           headers: {
             Authorization: `Bearer ${idToken}`,
             'Content-Type': 'application/json'
           },
         };
         const path = `/knowledge_source_configuration?kscid=${ksc_id}`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        // console.log(result);
        toast.update(toastid, {
            render: "Knowledge source deleted successfully!",
            type: "success",
            isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000
        });
        document.getElementById("delete_user_modal-alert").classList.remove("show");
        e.target.classList.remove("disabled");
        // Redirect to the knowledge sources page
        setTimeout(() => {
          navigate("/settings/knowledge-source-configurations");
        }, 3200);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
  };

  const handleSelectedSource = (selectedOptions) => {
    setSelectedSource(selectedOptions);
    if(preSelectSource !== selectedOptions.map((source) => source.value)){
        setEditingKs(true);
    }
  };

  useEffect(() => {
    if (preSelectSource.length > 0) {
      const selected = sources.filter((source) =>
        preSelectSource.includes(source.ks_id)
      );
      setSelectedSource(
        selected.map((source) => ({
          value: source.ks_id,
          label: source.name,
        }))
      );
    }
  }, [preSelectSource, sources]);
  

  if (showError) {
    return (
      <MainAppWrap>
        <div className="main-content mont-font">
          <SimpleHeader title="Knowledge Source Configurations Details" />
          <Container fluid="xxl" className="ks-details">
            <Row>
              <Col>
                <p>There was an error fetching the data. Please try again later.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </MainAppWrap>
    );
  }

  return (
    <MainAppWrap>
      <div className="main-content mont-font response-detail-page">
        <SimpleHeader title="Knowledge Source Configurations Details" />
        <Container fluid="xxl" className="">
          <Row>
                <Col>
                  {isLoadingKS && <SettingsLoader />}
                  {!isLoadingKS && (
                    <div className="ks-detail-top">
                  <div className="response-details ks-details">
                    <ul>
                      <li>
                        <h3>KSC ID:</h3>
                        <p>{kscDetails.ksc_id || "-"}</p>
                      </li>
                      <li className="editable-li">
                        <h3>KSC Name:</h3>
                        <div className="inline-edit-wrap">
                          {editingName ? (
                          <Form.Control
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            className="inline-edit-input"
                            autoFocus
                            placeholder="Enter new name"
                          />
                        ) : (
                          <p>
                            {newName || "-"}
                          </p>
                        )}
                        {!editingName && kscDetails.can_edit && (
                          <div className="inline-edit-btn" onClick={() => setEditingName(true)}></div>
                        )}

                        {editingName && (
                          <div className="inline-edit-btns">
                                <button
                                  className="btn btn-primary update-inline-edit"
                                  onClick={() => {
                                    // Call API to update name
                                    handleInlineEdit("name", newName);
                                    setEditingName(false);
                                  }}
                                >
                                </button>
                                <button
                                  className="btn btn-secondary cancel-inline-edit"
                                  onClick={() => {
                                    setEditingName(false);
                                    setNewName(kscDetails.name);
                                  }}
                                >
                                X
                                </button>
                            </div>
                        )}
                        </div>
                      </li>  
                      
                      <li className="editable-li">
                      <h3>Confidential:</h3>
                      <p className="confidential-switch">
                        <input
                          type="checkbox"
                          id="confidentialSwitch"
                          className="form-check-input"
                          checked={newConfidential}
                          onChange={(e) => {
                            if(kscDetails.must_stay_confidential){
                                toast.error("This KSC must stay confidential!", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                return;
                            }
                            if(kscDetails.can_edit){
                            setNewConfidential(e.target.checked);
                            handleInlineEdit("confidential", e.target.checked);
                            }
                          }}
                        />
                        <label htmlFor="confidentialSwitch" className="toggle-label"></label>
                      </p>

                      
                      </li>
                      <li className="editable-li">
                      <h3>Description:</h3>
                      <div className="inline-edit-wrap">
                        {editingDescription ? (
                          <Form.Control
                            type="text"
                            value={newDescription}
                            onChange={(e) => setNewDescription(e.target.value)}
                            className="inline-edit-input"
                            autoFocus
                            placeholder="Enter new description"
                          />
                        ) : (
                          <p>
                            {newDescription || "-"}
                          </p>
                        )}
                        {!editingDescription && kscDetails.can_edit &&  (
                          <div className="inline-edit-btn" onClick={() => setEditingDescription(true)}></div>
                        )}
                        {editingDescription && (
                          <div className="inline-edit-btns">
                            <button
                              className="btn btn-primary update-inline-edit"
                              onClick={() => {
                                // Call API to update description
                                handleInlineEdit("description", newDescription);
                                setEditingDescription(false);
                              }}
                            >
                            </button>
                            <button
                              className="btn btn-secondary cancel-inline-edit"
                              onClick={() => {
                                setEditingDescription(false);
                                setNewDescription(kscDetails.description);
                              }}
                            >
                              X
                            </button>
                          </div>
                        )}
                      </div>
                      </li>
                      {kscDetails.can_edit && (
                      <li>
                        <h3>Delete</h3>
                        <p>
                        
                          <a href="#" className="delete-ks" onClick={(e)=> handleDeleteWarning(e)}></a>
                        
                        </p>
                      </li>
                    )}
                    </ul>
                  </div>
                  
                  </div>
                )}
                </Col>
              </Row>
        </Container>
        {!isLoadingKS && (
        <Container  fluid="xxl">
            <Row>
                <Col>
                <div className="ks-detail-top" style={{padding:15, overflow:'visible'}}>
                <label htmlFor="source-type">
                        Knowledge Sources:
                <Select
                    id="select-source"
                    onChange={handleSelectedSource}
                    value={selectedSource}
                    options={sources.map((source, index) => ({
                    value: source.ks_id,
                    label: source.name,
                    }))}
                    isSearchable
                    placeholder="Select Source"
                    isMulti
                />
                </label>
                {editingKs && (
                <div className="inline-edit-btns">
                    <button
                        className="btn btn-primary update-inline-edit"
                        onClick={() => {
                        // Call API to update name
                        handleInlineEdit("ks", selectedSource);
                        setEditingKs(false);
                        }}
                    >
                    </button>
                    <button
                        className="btn btn-secondary cancel-inline-edit"
                        onClick={() => {
                        setEditingKs(false);
                        if (preSelectSource.length > 0) {
                        const selected = sources.filter((source) =>
                            preSelectSource.includes(source.ks_id)
                        );
                        setSelectedSource(
                            selected.map((source) => ({
                            value: source.ks_id,
                            label: source.name,
                            }))
                        );
                        }
                        }}
                    >
                    X
                    </button>
                </div>
                )}
                </div>
                </Col>
            </Row>
        </Container>
        )}
       
        
      </div>
      <ToastContainer />
      <div id="delete_user_modal-alert" className="delete-user-modal">
            <div className="delete-user-modal-content">
                <div className="delete-user-modal-body">
                    <p>Are you sure you want to delete this Knowledge Source?</p>
                    <h5>{newName}</h5>
                </div>
                <div className="delete-user-modal-footer">
                    <a href="#" className="delete-user-modal-btn cancel" onClick={(e)=>handleCancelDeleteKS(e)}>Cancel</a>
                    <a href="#" className="delete-user-modal-btn done" onClick={(e)=>handleDelete(e)}>Delete</a>
                </div>
            </div>
        </div>
    </MainAppWrap>
  );
}

export default KscDetails;
