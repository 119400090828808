import React, {useState, useEffect} from "react";
import ResponseForm from "./ResponseForm";
import ResponseStudioBars from "./partials/ResponseStudioBars";
import AiContext from "./partials/AiContext";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../config";
import { Link } from "react-router-dom";


function ResponseHandler(props) {
  const { getIdTokenClaims } = useAuth0();

  const postCopyCount = async () => {
    try {
      const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
      //   console.log('idToken-', idToken);
    
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            response_id:props.responseId
          })
        };

      const copyPath = `/response_copy`;
      const url = `${apiPath()}${copyPath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const copyResult = await response.json();
      console.log(copyResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };

    const handleCopy = () => {
        const divId = 'responseAc';
        const divElement = document.getElementById(divId);
        const successElement = document.getElementById('copySuccess');
      
        if (!divElement) {
          console.error(`Could not find element with ID '${divId}'`);
          return;
        }
      
        const range = document.createRange();
        range.selectNodeContents(divElement);
      
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      
        try {
          document.execCommand('copy');
          // Text successfully copied to the clipboard
          successElement.style.display = 'block';
          postCopyCount();
          setTimeout(() => {
            successElement.style.display = 'none';
          }, 3000);
          console.log('Text copied:');
        } catch (error) {
          // Error occurred while copying
          console.error('Failed to copy text:', error);
        }
      
        selection.removeAllRanges();
      };
      useEffect(() => {
        // if(props.responseData.length > 0){
        //   // console.log(props.responseData)
        // }
      }, [props.responseData]);
    return (
        <div>
            {/* <div className="response-main-head">
                Proposed responses
            </div> */}
            <div className="response-wrapper">
              <div className="responseHandleBtn"><span onClick={props.handleResponseStudio} className={(props.colsm !== 5 ? "res_close_icon":"")}><i>Response Studio</i></span></div>
              <div className={(props.isMatchedIDFound ? "response-heading response-box-left-orange":"response-heading")}>
                {/* <span>Response Studio</span> */}
                <div className="response-main-head">
                    Proposed responses
                </div>
                
                {(props.responseData.length > 1) ? (
                  <div className="response-pagination">
                    <div className="matched-response-head">Matched</div>
                    <a href="#" className={props.currentIndex === 0 ? 'response-prev disabled' : 'response-prev'} onClick={(e)=>props.handlePrevResponse(e)}></a>
                    <a href="#" className={props.currentIndex === props.responseData.length - 1 ? 'response-next disabled' : 'response-next'} onClick={(e)=>props.handleNextResponse(e)}></a>
                  </div>
                ):(
                  <div className="matched-response-head">Matched</div>
                )}
                </div>
              <div className={(props.hasNonNullEptResponse && props.matchedPost === props.responseId ? 'response-box response-matched':'response-box') }>
                
                <div className={(props.userGroups[0] === 'Ept-Admin' || props.userGroups[0] === 'Ept-Ranker') ? 'response-icon-wrap':'response-icon-wrap response-icon-wrap-single'}>
                {
                  (props.userGroups[0] === 'Ept-Admin' || props.userGroups[0] === 'Ept-Ranker') && (
                    <div className="generator-wrap">
                      Generator: {props.generatorId}
                      {/* <select>
                        <option value={generatorId}>{generatorId}</option>
                      </select> */}
                    </div>
                  )
                }
                  
                  
                </div>
                <div className="responseStudioContent">
                  <ResponseStudioBars />
                  {/* <AiContext responseLinks={props.responseLinks} /> */}
                  <div className="generateResponseBtnWrap">
                    <div className="generateResponseBtn">Generate</div>
                  </div>
                </div>

                <div className="response-body">
                  <div className="response-box-new">
                    <div id="responseAc" dangerouslySetInnerHTML={{ __html: props.data.replace(/^"|"$/g, '') }} />
                    {props.responseLinks && props.responseLinks.length > 0 && ( 
                      <div className="response-links">
                        <ul>
                          {props.responseLinks.map((link, index) => {
                            return (
                              <li key={index}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="response-icon-wrap-2">
                    <div className="response-icon" onClick={handleCopy}>Copy<span id="copySuccess" className="copySuccess">Copied to clipboard</span></div>
                    <div className="response-details-btn-wrap">
                      <Link to={`/response/${props.responseId}`} className="chat-feedback-detail-btn">Response Details</Link>
                    </div>
                  </div>
                  
                  {props.hideLikes == undefined && (
                    <ResponseForm ranking={props.currentRanking} responseDate={props.responseDate} responseId={props.responseId} threadId={props.threadId} generatorId={props.generatorId} />
                  )}
                </div>
              </div>
            </div>
          </div>
    );
}

export default ResponseHandler;