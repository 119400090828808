import React, {useState, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Table, Row, Col } from "react-bootstrap";
import SettingsLoader from "../common/SettingsLoader";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { apiPath } from "../../config";

function Channels(props) {
    const {tab} = useParams();
    const {getIdTokenClaims} = useAuth0();
    const [channelList, setChannelList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchChannels = async () => {
        try {
          setIsLoading(true);
          // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
           const options = {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${idToken}`,
               'Content-Type': 'application/json'
             },
           };
          // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
          const path = `/channel_list`;
          const url = `${apiPath()}${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
           console.log(result);
          setChannelList(result);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };
      useEffect(() => {
          if(channelList.length === 0 && tab === "channels"){
            fetchChannels();
          }
      }, [tab]);
    
  return (
    <div className="settings-tabs">
    <div className="header-button-adjust">
        <Link to="/settings/channel/create" className="request_btn">Create Channel</Link>
      </div>
    <div className="table-box">
        {/* <div className="table-header">
        <h5>Channels</h5>
        </div> */}
        <Table hover>
            <thead>
            <tr>
                <th className="table-name-th">Name</th>
                <th>Type</th>
                <th>Confidential</th>
                <th>Knowledge Source Configurations</th>
            </tr>
            </thead>
            <tbody>
            {channelList &&
            channelList.map((channel, index) => (
                <tr key={index}>
                    <td className="table-name-td">
                    <Link to={`/settings/channel/${channel.channel}`}>
                      {channel.name || "-"} {channel.channel || "-"}
                    </Link>
                    </td>
                    <td>{channel.type || "-"}</td>
                    <td align="left">
                    <div className="col-xs-12 custom-response-checkbox td-relative">
                        <label htmlFor={"confidential-"+index}>
                        <span className="checkbox-label"></span>
                        <input
                            type="checkbox"
                            name="confidential-check"
                            id={"confidential-"+index}
                            checked={channel.confidential}
                            onChange={(e) => (e.target.checked)}
                            disabled
                        />
                        <span className="checkmark"></span>
                        </label>
                    </div>
                    </td>
                    <td>
                    <Link to={`/settings/ksc-details/${channel.ksc.ksc_id}`}>
                    {channel.ksc.name || "-"} {channel.ksc.ksc_id || "-"}
                    </Link>
                    </td>
                </tr>
                ))}
                {channelList.length === 0 && !isLoading && (
                    <tr>
                        <td colSpan="5" align="center">No channel list available</td>
                    </tr>
                )}
            </tbody>
        </Table>
        {isLoading && (
          <SettingsLoader />
        )}
        <div className="table-footer">
        {/* <a href="https://slack.com/oauth/v2/authorize?client_id=5070992672400.6090172483698&scope=app_mentions:read,channels:history,chat:write,commands,groups:history,groups:read,im:history,im:read,mpim:history,mpim:read,groups:write,im:write,mpim:write&user_scope=">
            <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
            </a> */}
            {/* <a href="#" className="request_btn ticket-btn disable_btn" onClick={(e) => disableBtn(e)}>Custom email</a> */}
            {/* mailto:sales@ept.ai?subject=Add a ticket system to my account */}
            {/* <a href="#" className="request_btn ticket-btn disable_btn" onClick={(e) => disableBtn(e)}>Connect to ticket system</a> */}
        </div>
    </div>
    </div>
  );
}

export default Channels;