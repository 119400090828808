import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../common/Loader";
import { useParams } from "react-router";
import { apiPath } from "../../config";


function Billing(props) {
    const {tab} = useParams();
    const {user, isLoading, getIdTokenClaims } = useAuth0();
    const cancelToken = useRef(null);
    const [location, setLocation] = useState(null);
    const getSession = async () => {
        try {
          // Get the ID token from Auth0
          const getIdToken = await getIdTokenClaims();
          const idToken = getIdToken.__raw;
          console.log(idToken);
          const options = {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`,
              'Content-Type': 'application/json'
            },
          };
          const sessionPath = `/stripe_portal_session`;
          const url = `${apiPath()}${sessionPath}`;
          const response = await fetch(url, options);
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const responseData = await response.json(); // Assuming the response is JSON
          setLocation(responseData.redirect_url);
          console.log(responseData);
          // window.location.href = responseData.redirect_url; // Assuming the redirect URL is in the JSON response
        } catch (error) {
          console.error("Error posting data:", error);
        }
    };

    useEffect(() => {
      if(tab === "billing" && !isLoading && user) {
        getSession();
      }
    }, [user, isLoading, tab]);

    useEffect(() => {
      if(props.urlParam === "billing" && location !== null) {
        window.location.href = location;
      }
    }, [props.urlParam, location]);

    return (
        <Loader />
    );
}

export default Billing;
