import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import GenerateResponse from "./GenerateResponse";
import ResponseHandler from "./ResponseHandler";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import HeroFilter from "./HeroFilter";
import { useForumList } from "../common/ForumListContext";
import MainLayout from "../../layout/MainLayout";
import { apiPath } from "../../config";

function Threads(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  const callback = searchParams.get('callback');
  // console.log('Callback Page:', callback);
  const navigate = useNavigate();
  const { forumIds, selectedForumId, selectForumName } = useForumList();
  const { threadid } = useParams();
  
  const { user, getIdTokenClaims } = useAuth0();
  const userGroups = user?.ept_roles;
  // console.log('User Groups:', userGroups);
  const [threadId, setThreadId] = useState(threadid);
  const [thread, setThread] = useState([]);
  const [data, setData] = useState(null);
  const cancelToken = useRef(null);
  const [noThreadResponse, setNoThreadResponse] = useState('');
  const [generatorId, setGeneratorId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [responseDate, setResponseDate] = useState('');
  const [currentRanking, setCurrentRanking] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [responseId, setResponseId] = useState('');
  const [responsePostID, setResponsePostID] = useState('');
  const [responseCount, setResponseCount] = useState(0);
  const [hasNonNullEptResponse, setHasNonNullEptResponse] = useState(null);
  const [matchedPost, setMatchedPost] = useState(null);
  const [isMatchedIDFound, setIsMatchedIDFound] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [responseLinks, setResponseLinks] = useState([]);

  const [colsm, setColsm] = useState(5);
  const [colmd, setColmd] = useState(7);

  const [rangeValue, setRangeValue] = useState(0);

  const formatResponseText = (responseText) => {
    return responseText.replace(/\n\n/g, "<br />").replace(/\n/g, " ");
  }

  const findPostWithNonNullEptResponse = (posts) => {
    return posts.find((post) => post.ept_response_id !== null);
  }

  const removeSpecificIDsFromString = (inputString, idsToRemove) => {
    // Convert the array of IDs to a regular expression pattern
    const pattern = new RegExp(`\\b(${idsToRemove.join('|')})\\b`, 'gi');

    // Remove the IDs from the string using the pattern
    const outputString = inputString.replace(pattern, '');

    return outputString;
  }
  const fetchData = async () => {
    setIsLoading(true)
    try {
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      //   console.log('idToken-', idToken);

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
      };
      const thePath = `/responses?thread_id=${threadid}`;

      const url = `${apiPath()}${thePath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();


      if (result.length <= 0) {
        console.log("No response found for thread_id ", threadid);
        setNoThreadResponse("No response found for thread_id " + threadid);
        setData("");
        setThread("");
        setIsLoading(false);
        return;
      }
      setNoThreadResponse('');
      setThread(result.thread);
      console.log(result);

      setHasNonNullEptResponse(result.thread.posts.some((post) => post.ept_response_id !== null));
      const matchedPostresID = findPostWithNonNullEptResponse(result.thread.posts);
      // console.log(matchedPostresID)
      if (matchedPostresID !== undefined) {
        setMatchedPost(matchedPostresID.ept_response_id);
      } else {
        setMatchedPost(null);
      }

      // Now you can access the matched post


      setResponseData(result.responses);
      setResponseCount(result.responses.length);
      if (result.responses.length > 0) {
        setGeneratorId(result.responses[0].generator_id);
        setResponseDate(result.responses[0].date);
        setResponseId(result.responses[0].response_id);
        setResponsePostID(result.responses[0].post_id);
        setResponseLinks(result.responses[0].links);
        console.log(result.responses[0].response_id)
        // console.log(result)
        // console.log(result[0].thread);
        const parseData = formatResponseText(result.responses[0].response_text);
        setData(JSON.stringify(parseData));

      } else {
        setData([]);
        setGeneratorId("");
        setResponseData([]);
        setResponseLinks([]);
        setResponseCount(0);
        setData("");
        setShowResponse(true);
      }


      // console.log(thread);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false)
  };
  useEffect(() => {
    if (!threadid || !user) {
      return; // Skip API call if threadId is not defined
    }

    fetchData();
  }, [threadid, user]);



  const fetchRanking = async () => {
    try {
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      //   console.log('idToken-', idToken);

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
      };
      const rankPath = `/response/ranking?response_id=${responseId}`;

      const url = `${apiPath()}${rankPath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const rankResult = await response.json();

      // console.log(rankResult);
      setCurrentRanking(rankResult)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleNextResponse = (e) => {
    e.preventDefault();
    const nextIndex = currentIndex + 1;
    if (nextIndex < responseData.length) {
      const nextData = responseData[nextIndex];
      setCurrentIndex(nextIndex);
      const parseData = formatResponseText(nextData.response_text);
      setData(JSON.stringify(parseData));
      setGeneratorId(nextData.generator_id);
      setResponseDate(nextData.date);
      setResponseId(nextData.response_id);
      setResponsePostID(nextData.post_id);
      setResponseLinks(nextData.links);
      // setData(nextData);
    }
  };

  const handlePrevResponse = (e) => {
    e.preventDefault();
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      const prevData = responseData[prevIndex];
      setCurrentIndex(prevIndex);
      const parseData = formatResponseText(prevData.response_text);
      setData(JSON.stringify(parseData));
      setGeneratorId(prevData.generator_id);
      setResponseDate(prevData.date);
      setResponseId(prevData.response_id);
      setResponsePostID(prevData.post_id);
      setResponseLinks(prevData.links);
    }
  };

  const handleBackButton = () => {
    navigate(`/community-forum/${selectedForumId}/page/${callback !== null ? callback : 1}`);
  };



  useEffect(() => {
    if (responseDate !== '') {
      fetchRanking();
    }
  }, [responseId, threadid]);

  useEffect(() => {
    if (currentRanking.length > 0) {
      //  console.log(currentRanking)
    }
  }, [currentRanking]);

  useEffect(() => {
    if (data !== null) {
      //  console.log("data: ",data)
    }
  }, [data]);



  useEffect(() => {
    const handleContentLoaded = () => {
      const elementsWithClass = document.querySelectorAll('.thread-box-matched-post-id');
      setIsMatchedIDFound(elementsWithClass.length > 0);
    };

    const observeDOM = () => {
      const targetNode = document.body; // You can specify a more specific target if needed

      const observer = new MutationObserver(handleContentLoaded);

      // Configure the observer to watch for changes in the subtree
      const config = { subtree: true, childList: true };

      // Start observing the target node for configured changes
      observer.observe(targetNode, config);

      // Call the handler initially when responsePostID changes
      handleContentLoaded();

      // Clean up the observer when the component unmounts
      return () => {
        observer.disconnect();
      };
    };

    observeDOM();
  }, [responsePostID]);


  const handleResponseStudio = () => {
    if (colsm === 5) {
      setColsm(7);
      setColmd(5);
    } else if (colsm === 7) {
      setColsm(5);
      setColmd(7);
    }
  };

  useEffect(() => {
    if (selectedForumId !== '' && selectedForumId !== null) {

    }

  }, [selectedForumId]);

  useEffect(() => {
    if (threadId !== '' && threadId !== null) {
      // navigate(`/thread_id/${threadId}`);
    }
  }, [threadId]);


  if (!threadid) {
    return null; // Unload the content if threadId is empty
  }
  return (
    <MainLayout>
      <div>
        <Container fluid className="">
          <Container fluid="xxl">
            <Row>
              <Col className="no-padding">
                <HeroFilter
                  selectedForumId={selectedForumId}
                  selectForumName={selectForumName}
                  handleForumChange={props.handleForumChange}
                  forumIds={forumIds}
                  setThreadId={setThreadId}
                />
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid="xxl" className="dashboard-container">
          {isLoading && (
            <Row>
              <Col className="dash-left" sm={7}>
                <div className='chat-loading-wrap-app'>
                  <div className="chat-loader">
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                  </div>
                  <div className='loading-text'>Loading thread ID: {threadid}</div>
                </div>
              </Col>
            </Row>
          )}
          {!isLoading && (
            <Row className="threads-page">
              <Col className="dash-left" sm={colmd}>
                <div className="back-to-threads" onClick={() => handleBackButton()}>Back</div>
                <Row>
                  <Col>
                    <div className="threads-list">
                      {noThreadResponse !== '' ? (
                        <p className="no-thread-found-error">{noThreadResponse}</p>
                      ) : (
                        <p></p>
                      )}
                      <div className="thread-headings">

                      </div>
                      {thread.posts && thread.posts.map((post, index) => (
                        <div className={(index === 0 ? 'thread-box' : 'thread-box thread-box-child')
                          + (post.solved === 1 ? ' thread-box-solved' : '')
                          + (post.ept_response_id !== null ? ' thread-box-ept-res' : '')
                          + (post.post_id == responsePostID ? ' thread-box-matched-post-id' : '')
                        }
                          key={index}>
                          {post.user_id && index === 0 && (
                            <div className="thread-author">
                              <span className="thread-author-name">@{post.user_id}</span>
                              <span className="thread-date">{post.date}</span>
                            </div>
                          )}

                          {thread.title && index === 0 && (
                            <h2 className="thread-title">{thread.title}</h2>
                          )}

                          {thread.tags && index === 0 && (
                            <div className="thread-tags">
                              {thread.tags.map((tag, index) => (
                                <a key={index} href="#">{tag}</a>
                              ))}
                            </div>
                          )}
                          <div className={post.solved === 1 ? 'thread-body thread-body-solved' : 'thread-body'}>
                            {post.solved === 1 && (
                              <div className="post-solved-status">Solved by this answer</div>
                            )}
                            <div dangerouslySetInnerHTML={{ __html: removeSpecificIDsFromString(post.text, ["response-wrap", "responseAc"]) }} />
                          </div>
                          {post.user_id && index > 0 && (
                            <div className="thread-author-bottom">
                              <span className="thread-author-name">By @{post.user_id} </span>
                              <span className="thread-date">{post.date}</span>
                            </div>
                          )}
                          {index === 0 && (
                            <div>
                              <a className="ext_link" href={thread.permalink} target="_blank">See in original forum</a>
                              <a className="thread-like-btn" href="#">
                                {post.likes}
                              </a>
                            </div>
                          )}
                          <div className="thread-status-wrap">
                            <div className="thread-matched-status">Matched</div>
                            {index === 0 && (
                              <div>
                                {thread.solved === 1 && (
                                  <div className="thread-status">Solved</div>
                                )}
                              </div>
                            )}
                          </div>

                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="dash-right response-dash-wrap" sm={colsm}>

                {data && data !== null ? (
                  <ResponseHandler
                    data={data}
                    generatorId={generatorId}
                    responseDate={responseDate}
                    currentRanking={currentRanking}
                    responseData={responseData}
                    currentIndex={currentIndex}
                    responseId={responseId}
                    threadId={threadid}
                    responsePostID={responsePostID}
                    handleNextResponse={handleNextResponse}
                    handlePrevResponse={handlePrevResponse}
                    userGroups={userGroups}
                    hasNonNullEptResponse={hasNonNullEptResponse}
                    matchedPost={matchedPost}
                    isMatchedIDFound={isMatchedIDFound}
                    handleResponseStudio={handleResponseStudio}
                    colsm={colsm}
                    responseLinks={responseLinks}
                  />
                ) : (
                  <div>
                    {showResponse && (
                      <GenerateResponse
                        threadId={threadid}
                        fetchData={fetchData}
                        // selectedForumId={props.selectedForumId}
                        // selectedTag={props.selectedTag}
                        // selectedBoard={props.selectedBoard}
                        // unsolved={props.unsolved}
                        setGeneratorId={setGeneratorId}
                        setResponsePostID={setResponsePostID}
                        setResponseId={setResponseId}
                        setResponseDate={setResponseDate}
                        setData={setData}
                        setResponseLinks={setResponseLinks}
                      />
                    )}
                  </div>
                )}

              </Col>

            </Row>
          )}
        </Container>

      </div>
    </MainLayout>
  );
}

export default Threads;

