import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthContext';
import logo from '../images/logo-black.svg';

function MFASetupScreen() {
    const {user} = useAuth();
    const [mfaMethod, setMfaMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


    const handleSetupMFA = async () => {
        try {
          if (mfaMethod === 'sms') {
            const attributes = { phone_number: phoneNumber };
            await Auth.updateUserAttributes(user, attributes);
            console.log('SMS MFA set up successfully.');
          } else if (mfaMethod === 'authenticator') {
            await Auth.verifyTotpToken(user, authCode);
            console.log('Authenticator app MFA set up successfully.');
          } else {
            console.log('Invalid MFA method chosen.');
          }
      
          navigate('/'); // Redirect to the dashboard or desired page after MFA setup
        } catch (error) {
          console.error('MFA setup error:', error);
          setErrorMessage('Failed to set up MFA. Please try again.');
        }
      };
      

  const handleSkipMFA = () => {
    navigate('/'); // Redirect to the dashboard or desired page after skipping MFA
  };

  return (
    <div>
        <div className="container">
            <div className="row">
                <div className='mfa_form'>
                    <div className='col-xs-12 text-center'>
                        <img className='form-logo' src={logo} alt="logo" />
                    </div>
                    <div className='col-xs-12'>
                        <h2>Multi-Factor Authentication Setup</h2>
                        {errorMessage && <p>{errorMessage}</p>}
                    </div>
                    <div className='col-xs-12 text-center'>
                        <p>Do you want to set up Multi-Factor Authentication?</p>
                        <div className='row'>
                            <div className='col-6'>
                                <button className='btn btn-primary' onClick={() => setMfaMethod('authenticator')}>Authenticator APP</button>
                            </div>
                            <div className='col-6'>
                                <button className='btn btn-primary' onClick={() => setMfaMethod('sms')}>SMS</button>                                
                            </div>
                        </div>
                    </div>
                    {mfaMethod === 'sms' && (
                        <div className='col-xs-12'>
                            <label>Phone Number:</label>
                            <input className='form-control' type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                        </div>
                    )}
                    {mfaMethod === 'authenticator' && (
                        <div className='col-xs-12'>
                            <label>Verification Code: </label>
                            <input className='form-control' type="text" value={authCode} onChange={(e) => setAuthCode(e.target.value)} />
                        </div>
                    )}
                    {mfaMethod && (
                        <div className='col-xs-12'>
                            <button className='btn btn-primary' onClick={handleSetupMFA}>Set up MFA</button>
                        </div>
                    )}
                    <div className='col-xs-12 text-center'>
                        <a className='' href="#" onClick={handleSkipMFA}>Skip</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default MFASetupScreen;
