import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Table from "react-bootstrap/Table";
import SettingsLoader from "../../common/SettingsLoader";
import { useParams } from "react-router-dom";
import {convertDateIntoLocalDate, convertTimeStampToUnix} from '../../common/Functions';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { set } from "date-fns";
import { apiPath } from "../../../config";
import { toast } from "react-toastify";
import { get } from "jquery";

function ItemsList(props) {
    const {ks_id} = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState(searchParams.get("query") || "");
    const [filters, setFilters] = useState(searchParams.get("filters") || "");
    const [startTime, setStartTime] = useState(searchParams.get("start_time") || "");
    const [endTime, setEndTime] = useState(searchParams.get("end_time") || "");
    const [items, setItems] = useState([]);
    const [searchableItems, setSearchableItems] = useState([]);
    const [isLoadingItems, setIsLoadingItems] = useState(true);
    const { getIdTokenClaims, user } = useAuth0();

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ]);
    const [showDatePicker, setShowDatePicker] = useState(false);

    // This function now uses internal state which is synced with URL search params
    const getItems = async () =>{
        let filterString = `?ks_id=${props.ks_id}`;
        if (query) filterString += `&query=${encodeURIComponent(query)}`;
        if (filters) filterString += `&filters=${encodeURIComponent(filters)}`;
        if (startTime) filterString += `&start_time=${encodeURIComponent(startTime)}`;
        if (endTime) filterString += `&end_time=${encodeURIComponent(endTime)}`;

        try {
            setItems([]);
            setIsLoadingItems(true);
            const idToken = await getIdTokenClaims();
            const response = await fetch(`${apiPath()}/ks_items${filterString}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${idToken.__raw}`,
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            const data = await response.json();
            // console.log(data);
            // console.log(data.find(item => item.tags && item.tags.includes("boosted")));
            setItems(data);
            setSearchableItems(data);
        } catch (error) {
            console.error("Error fetching items:", error);
        } finally {
            setIsLoadingItems(false);
        }
    }

    useEffect(() => {
        
        // filter items from setSearchableItems based on the keys in the query switch case below
        let filteredItems = searchableItems;
        if (query) {
            filteredItems = filteredItems.filter((item) => {
                const queryLower = query.toLowerCase();
                return item.title.toLowerCase().includes(queryLower) || item.url.toLowerCase().includes(queryLower);
            }
            );
        }
        if (filters) {
            filteredItems = filteredItems.filter((item) => {
                return item.tags && item.tags.includes(filters);
            }
            );
        }
        if (startTime) {
            filteredItems = filteredItems.filter((item) => {
                return new Date(item.last_updated) >= new Date(startTime);
            }
            );
        }
        if (endTime) {
            filteredItems = filteredItems.filter((item) => {
                return new Date(item.last_updated) <= new Date(endTime);
            }
            );
        }
        setItems(filteredItems);        
        
    }, [query, filters, startTime, endTime, user, searchableItems]); // Dependencies on internal state

    useEffect(() => {
      console.log("state:", props.isFileUploaded)
        if (user && props.ks_id && props.isFileUploaded)  {
            getItems();
            props.setIsFileUploaded(false);
        }
    }, [user, props.ks_id, props.isFileUploaded]);

    useEffect(() => {
      if (user && props.ks_id)  {
        getItems();
      } 
    }, [user, props.ks_id, ]);

    useEffect(() => {
        // Sync state with URL search parameters
        setQuery(searchParams.get("query") || "");
        setFilters(searchParams.get("filters") || "");
        setStartTime(searchParams.get("start_time") || "");
        setEndTime(searchParams.get("end_time") || "");
    }, [searchParams]);
    let searchTimeout; // Rename the variable to avoid conflict with the clearTimeout function

    const handleSearch = (e) => {
        e.preventDefault();
        const newQuery = e.target.value;

        // Clear any existing timeout to prevent multiple search triggers
        if (searchTimeout) clearTimeout(searchTimeout);

        // Set a new timeout to delay setting search params
        searchTimeout = setTimeout(() => {
            setSearchParams({ ...Object.fromEntries(searchParams.entries()), query: newQuery });
        }, 500);
    };


    const handleFiltersButtons = (e) => {
        e.preventDefault();
        // document.querySelectorAll(".filter-link").forEach(button => {
        //     button.classList.remove("active");
        // });
        // e.target.classList.add("active");
        const filter = e.target.getAttribute("data-ref");
        setSearchParams({ ...Object.fromEntries(searchParams.entries()), filters: filter === "All" ? "" : filter.toLowerCase() });
    };
    const handleActionButtons = (e) => {
      e.preventDefault();
      let ele = e.target;
      let action_btns = ele.nextElementSibling;
  
      // Check if the display property is either "none" or not set (empty string).
      if (action_btns.style.display === "block") {
          action_btns.style.display = "none";
      } else {
          // If it's "none" or empty, set it to "block"
          action_btns.style.display = "block";
      }
  };

    const handleDateFilter = (e) => {
        e.preventDefault();
        const days = e.target.value;
        const currentTime = new Date();
        const startTime = new Date(currentTime.setDate(currentTime.getDate() - days));
        const endTime = new Date();
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            start_time: startTime.toISOString(),
            end_time: endTime.toISOString(),
        });
    };
    
    document.addEventListener('click', function (e) {
    //   e.preventDefault();
        if (e.target.className !== 'action_btn_dots') {
            let action_btns = document.querySelectorAll('.action_btns');
            action_btns.forEach(function (ele) {
                ele.style.display = 'none';
            });
        }
        if (e.target.className !== 'toggle-date-picker' 
        && !e.target.closest('.date-picker-box') // Checks if the clicked element or any of its ancestors have the class "date-picker-box"
    ) {
        // console.log('clicked outside');
        setShowDatePicker(false);
    }
    });
    
    const handleActions = (e) => {
        e.preventDefault();
        let ele = e.target;
        let action = ele.getAttribute("data-action");
        let item_url = ele.getAttribute("data-item-url");
        // let ks_id = ele.getAttribute("data-ks-id");
        const mainEle = ele.closest("tr").getAttribute("data-item-id");
        // let action_btns = ele.closest('.action_btns');
        // let action_btn_dots = action_btns.previousElementSibling;
        // action_btns.style.display = 'none';
        // action_btn_dots.style.display = 'block';
        if (action == "boost") {
          // boost item
          console.log("boost item");
          handleActionsAsync(item_url, "boosted", mainEle);
        } else if (action == "unboost") {
          // unboost item
          console.log("unboost item");
          handleActionsAsync(item_url, "", mainEle);
        } else if (action == "block") {
          // block item
          console.log("block item");
          handleActionsAsync(item_url, "blocked", mainEle);
        } else if (action == "unblock") {
          // unblock item
          console.log("unblock item");
          handleActionsAsync(item_url, "", mainEle);
        }
      };
    
      const handleActionsAsync = async (url, reqType, mainEle) => {
        try {
          const idToken = await getIdTokenClaims();
          const bodyParse = {
            tags: [reqType],
            url: url,
          };
          const parseJson = JSON.stringify(bodyParse);
          const path = `/knowledge_source/${ks_id}/tags`;
          const response = await fetch(`${apiPath()}${path}`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken.__raw}`,
              "Content-Type": "application/json",
            },
            body: parseJson,
          });
          if (!response.ok)
            throw new Error(`HTTP error! Status: ${response.status}`);
          const data = await response.json();
          // console.log(data);
          if(data.message === "ok"){
            const element = document.querySelector(`tr[data-item-id="${mainEle}"]`);
            if(reqType === "boosted"){
              // element.querySelector(".table-boosted-td").innerHTML = "<span class='boosted'>Boosted</span>";
              element.querySelector(".action_btns").classList.add("show-unboosted");
              element.querySelector(".action_btns").classList.remove("show-unblocked");
            }
            else if(reqType === "blocked"){
              // element.querySelector(".table-boosted-td").innerHTML = "<span class='blocked'>Blocked</span>";
              element.querySelector(".action_btns").classList.add("show-unblocked");
              element.querySelector(".action_btns").classList.remove("show-unboosted");
            }
            else{
              // element.querySelector(".table-boosted-td").innerHTML = "";
              element.querySelector(".action_btns").classList.remove("show-unboosted");
              element.querySelector(".action_btns").classList.remove("show-unblocked");
            }
            // update searchableItems and items state match the url and add tags to it
            let updatedItems = searchableItems.map((item) => {
              if(item.url === url){
                if(reqType === "boosted"){
                  item.tags = ["boosted"];
                }
                else if(reqType === "blocked"){
                  item.tags = ["blocked"];
                }
                else{
                  item.tags = [];
                }
              }
              return item;
            });
            // setSearchableItems(updatedItems);
            setItems(updatedItems);
          }
        } catch (error) {
          console.error("Error fetching items:", error);
        } finally {
          // setIsLoadingItems(false);
        }
      };
      const handleDateSelection = (ranges) => {
        setState([ranges.selection]);
        console.log("Selected Range:", ranges.selection);
    
        let startDate = new Date(ranges.selection.startDate);
        let endDate = new Date(ranges.selection.endDate);
        // if (startDate.getTime() !== endDate.getTime()) {
        //     setShowDatePicker(false);
        // }
        // Ensure the end date includes the entire day if start and end dates are the same
        if (startDate.getTime() === endDate.getTime()) {
            endDate.setHours(23, 59, 59, 999); // Set to the very end of the day
        }

    
        // Format dates to ISO string for URL search params
        const formattedStartTime = startDate.toISOString();
        const formattedEndTime = endDate.toISOString();
    
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            start_time: formattedStartTime,
            end_time: formattedEndTime,
        });
    
        console.log("Formatted Start Time:", formattedStartTime);
        console.log("Formatted End Time:", formattedEndTime);
    
        // setShowDatePicker(false);  // Hide date picker after selection
    };
    const toggleDatePicker = (e) => {
        e.preventDefault();
        setShowDatePicker(!showDatePicker);
      };

      const deleteItem = async (e) => {
        const toastid = toast.loading("Please wait...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        
        try {
            const itemurl = e.target.getAttribute("data-item-url");
            const encodedItemUrl = encodeURIComponent(itemurl);
            // Get the ID token from Auth0
            const getidToken = await getIdTokenClaims();
            const idToken = getidToken.__raw;
            const options = {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                },
            };
            console.log("encodedItemUrl:", encodedItemUrl);
            const path = `/ks_items?ks_id=${ks_id}&item_url=${encodedItemUrl}`;
            const url = `${apiPath()}${path}`;
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            toast.update(toastid, {
              render: "Item deleted successfully!",
              type: "success",
              isLoading: false,
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
            });
            // console.log(result);
            getItems();

        } catch (error) {
            console.error("Error fetching data:", error);
            toast.update(toastid, {
              render: "Error deleting item!",
              type: "error",
              isLoading: false,
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
            });
        }
    };
  return (
    <div>
        <div className="item-filters">
           <div className="item-filters-buttons">
                <a href="#" className={`filter-link ${filters == '' ? 'active':''}`} onClick={handleFiltersButtons} data-ref="All">All</a>
                <a href="#" className={`filter-link ${filters == 'boosted' ? 'active':''}`} onClick={handleFiltersButtons} data-ref="Boosted">Boosted items</a>
                {props.ks_type !== "contributed_knowledge" && (
                <a href="#" className={`filter-link ${filters == 'blocked' ? 'active':''}`} onClick={handleFiltersButtons} data-ref="Blocked">Blocked items</a>
                )}
           </div>
           <div className="filters-inputs">
                <input className="item-search" type="text" placeholder="Search" onChange={handleSearch} />
                {props.ks_type !== "contributed_knowledge" && (
                <div className="date-picker-wrap date-picker-wrap-items">
                  <div
                  className="toggle-date-picker"
                  onClick={toggleDatePicker}
                  ></div>
                  {showDatePicker && (
                  <div className="date-picker-box">
                      <DateRangePicker
                      editableDateInputs={true}
                      onChange={handleDateSelection}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                      rangeColors={["#053F98"]}
                      />
                  </div>
                  )}
              </div>
              )}
              {props.ks_type === "contributed_knowledge" && (
                    <Link to={`/settings/ks/${ks_id}/new-gkd`} className="braindump-btn">New Guided Knowledge Document</Link>
                )}
           </div>
           
        </div>
      <div className="table-box items-box-table">
        <Table hover>
          <thead>
            <tr>
              <th className="table-name-th">Name</th>
              <th>Type</th>
              <th></th>
              <th>URL</th>
              <th>Uses</th>
              <th>Last updated</th>
              <th className="table_action">Action</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item, index) => (
                <tr key={index} data-item-id={`item-id-${index}`}>
                  <td className="table-name-td">
                  {item.type === "gkd" ? (
                    <Link to={`/settings/ks/${ks_id}/${item.title}`}>{item.title}</Link>
                  ):(
                    item.title
                  )}
                  </td>
                  <td>
                    {item.type}
                  </td>
                    <td className="table-boosted-td">
                        {item.tags && item.tags.includes("boosted") && <span className="boosted">Boosted</span>}
                        {item.tags && item.tags.includes("blocked") && <span className="blocked">Blocked</span>}
                    </td>
                  <td className="table-url-td">
                  {item.type === "gkd" ? (
                    <Link to={`/settings/ks/${ks_id}/${item.title}`}>{item.title}</Link>
                  ):(
                    <Link 
                    to={`/settings/ks/item/download?item_url=${item.url}&ks_id=${props.ks_id}&filename=${item.title}&redirect_url=${window.location.pathname}`} 
                    target="_blank">{item.url}</Link>
                  )}
                  </td>
                  <td>{item.count}</td>
                  <td>{convertDateIntoLocalDate(item.last_updated)}</td>
                  <td>
                  {!props.ks_type === "contributed_knowledge" && (
                    <div className="table-action">
                        <a href="#" className="action_btn_dots" onClick={handleActionButtons}></a>
                        <div className={`action_btns 
                      ${item.tags && item.tags.includes("boosted") ? "show-unboosted" : ""}
                      ${item.tags && item.tags.includes("blocked") ? "show-unblocked" : ""}
                        `}>

                          <a
                            href="#"
                            className="action_btn_unboost"
                            onClick={handleActions}
                            data-action="unboost"
                            data-item-url={item.url}
                          >
                            Un-boost
                          </a>
                            <a
                              href="#"
                              className="action_btn_boost"
                              onClick={handleActions}
                              data-action="boost"
                              data-item-url={item.url}
                              data-ks-id={item.ks_id}
                            >
                              Boost
                            </a>
                          <a
                            href="#"
                            className="action_btn_unblock"
                            onClick={handleActions}
                            data-action="unblock"
                            data-item-url={item.url}
                          >
                            Un-block
                          </a>

                            <a
                              href="#"
                              className="action_btn_block"
                              onClick={handleActions}
                              data-action="block"
                              data-item-url={item.url}
                            >
                              Block
                            </a>
                      </div>
                    </div>
                  )}
                  {props.ks_type === "contributed_knowledge" && (
                    <div className="table-action">
                      <a href="#" className="action_btn_dots" onClick={handleActionButtons}></a>
                      <div className="action_btns">
                        <a
                          href="#"
                          className="action_btn_delete"
                          onClick={deleteItem}
                          data-item-url={item.url}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  )}
                  </td>
                </tr>
              ))}
              {/* If no items are found, display a message */}
              {items.length == 0 && !isLoadingItems && (
              <tr>
                <td colSpan="7">
                    <div className="no-items">No items found</div>
                </td>
              </tr>
              )}
          </tbody>
        </Table>
        {isLoadingItems && <SettingsLoader />}
        <div className="table-footer"></div>
      </div>
    </div>
  );
}

export default ItemsList;
