import React from "react";
import PricingTable from "../components/PricingTable";
import SimpleHeader from "../components/SimpleHeader";

function Pricing(props){
    return(
        <div>
            <SimpleHeader />
            <PricingTable />
        </div>
        
    );
}

export default Pricing;