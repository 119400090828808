import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { apiPath } from "../../config";

function ThreadListV2(props) {
  const navigate = useNavigate();
  const {pagenumber} = useParams();
  const currentPage = Number(pagenumber) || 1;
  const [isLoading, setIsLoading] = useState(true);
  const [threadsSummary, setThreadsSummary] = useState([]);
  const [threadOffset, setThreadOffset] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const { getIdTokenClaims } = useAuth0();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getThreadSummery = async (offset, itemCount, solved, sort_by, unresponded, tags, boards, q, allthreads, signal) => {
    if (props.forumid === '' || props.forumid === null) {
      return;
    }
    try {
      setIsLoading(true);
      props.setThreadsResultMessage("Loading threadlist...");
       // Get the ID token from Auth0
       const getidToken = await getIdTokenClaims();
       const idToken = getidToken.__raw;
          // console.log('idToken-', idToken);
     
       const options = {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${idToken}`,
           'Content-Type': 'application/json'
         },
         signal
       };
      //  console.log('function called ---')

      // Start with the base path
      let getPath = `/thread_summary?forum_id=${props.forumid}&offset=${offset}&item_count=${itemCount}`;
      // console.log("all threads: ", allthreads)
      // Conditionally add parameters
      if (solved ===false && allthreads === false) {
        // console.log("unsolved: ", solved)
        getPath += `&solved=${solved}`;
      }

      if (sort_by && sort_by !== null) {
        getPath += `&sort_by=${sort_by}`;
      }

      if (unresponded) {
        getPath += `&unresponded=${unresponded}`;
      }

      if (tags && tags.length > 0) {
        const tagsArray = tags.map(item => item.value);
        getPath += `&tags=${encodeURIComponent(JSON.stringify(tagsArray))}`;
      }

      if (boards && boards.length > 0) {
        const boardsArray = boards.map(item => item.value);
        getPath += `&boards=${encodeURIComponent(JSON.stringify(boardsArray))}`;
      }

      if (q && q !== null) {
        getPath += `&q=${q}`;
      }
      // console.log("get path: ", getPath)
      const url = `${apiPath()}${getPath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const getResult = await response.json();
      // console.log(getResult);
      if (getResult.threads.length > 0) {
        setThreadsSummary(getResult);
        setTotalCount(getResult.total_count);
        setTotalPages(Math.ceil(getResult.total_count / itemsPerPage));
        setOffset(getResult.next_offset);
        props.setThreadsResultMessage("Displaying page " + pagenumber);
      } else {
        setThreadsSummary([]);
        setTotalCount(0);
        setOffset(0);
        props.setThreadsResultMessage("No threads found!");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error thread summary:", error);
      setErrorMessage("Error thread summary:", error);
    }
  };
  useEffect(() => {
    console.log('Child Component - selectedForumId:', props.selectedForumId);
  }, [props.selectedForumId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

      if (props.forumid !== '' || props.unsolved !== '' || props.noresponse !== '' || props.selectedSort !== '' || props.selectedFilter !== '' || props.selectedTag !== '' || props.selectedBoard !== '') {
        getThreadSummery((currentPage - 1) * itemsPerPage, itemsPerPage, props.unsolved, props.selectedSort, props.noresponse, props.selectedTag, props.selectedBoard, null, props.allthreads, signal );
        scrollToTop();
      }
    

    // Cleanup: clear the timeout when the component unmounts or when dependencies change
    return () => {
      controller.abort(); 
    };
  }, [
    props.unsolved,
    props.noresponse,
    props.selectedSort,
    currentPage,
    props.selectedFilter,
    props.selectedTag,
    props.selectedBoard,
    props.allthreads,
  ]);

  const updateThreadList = (page) => {
    const newOffset = (page - 1) * itemsPerPage;
    getThreadSummery(newOffset, itemsPerPage, props.unsolved, null, props.noresponse, props.selectedTag, props.selectedBoard, null, props.allthreads);
    props.setCurrentPage(page);
    scrollToTop();
  };

  const removeATags = (htmlString) => {
    // This regex finds all <a> tags and their content
    const regex = /<a[^>]*>(.*?)<\/a>/g;
    
    // Replace all occurrences of <a> tags with an empty string
    return htmlString.replace(regex, '');
  }

  if (isLoading) {
    return (
      <div className="threadlist-main-wrap">
        <div className="thread-list-wrap">
          <Row>
            <Col className="dash-left" sm={12}>
              <div className='chat-loading-wrap-app'>
                <div className="chat-loader">
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
                <div className='loading-text'>Loading threadlist...</div>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  }

  const pageRangeSize = 5; // Number of page numbers to display in the range
  console.log('Current Page:', currentPage);

    const startPage = Math.max(1, currentPage - Math.floor(pageRangeSize / 2));
    const endPage = Math.min(totalPages, startPage + pageRangeSize - 1);

    const pageNumbers = [];
    for (let page = startPage; page <= endPage; page++) {
    pageNumbers.push(page);
    }

  const handlePageNumberClick = (page) => {
    if (page !== currentPage) {
      // Update the URL with the new page number
      navigate(`/community-forum/${props.forumid}/page/${page}`);
      // Optionally, call updateThreadList(page) here if you want to
      // fetch data immediately after updating the URL, or rely on
      // the useEffect hook that responds to changes in currentPage
      // which is derived from the URL.
      updateThreadList(page);
    }
  };

  return (
    <div className="threadlist-main-wrap">
      <div className="thread-list-wrap">
        {threadsSummary.threads && threadsSummary.threads.map((thread, index) => (
          <Link className="thread-list-item" 
          to={`/thread_id/${thread.thread_id}/?callback=${currentPage}`}
          key={index} 
          data-threadid={thread.thread_id}>
            {thread.title && (
              <h2 className="thread-title">{thread.title}</h2>
            )}

            {thread.tags && (
              <div className="thread-tags">
                {thread.tags.map((tag, index) => (
                  <a key={index} href="#">{tag}</a>
                ))}
              </div>
            )}

            {thread.text && (
              <div className="thread-text" dangerouslySetInnerHTML={{ __html: thread.text }} />
            )}

            {thread.author && (
              <div className="thread-author">
                <span className="thread-author-name">By <i>@{thread.author}</i></span>
                <span className="thread-date">| {thread.date}</span>
              </div>
            )}

            <div className="thread-status-wrap">
              <div>
                {thread.solved === 1 && (
                  <div className="thread-status">Solved</div>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="page-pagination">
  <button
    onClick={() => handlePageNumberClick(currentPage - 1)}
    disabled={currentPage === 1}
    className="prev-button"
  >
    
  </button>
  {startPage > 1 && (
    <span
      onClick={() => handlePageNumberClick(startPage - 1)}
      className="page-number"
    >
      ...
    </span>
  )}
  {pageNumbers.map((page) => (
  <span
    key={page}
    onClick={() => handlePageNumberClick(page)}
    className={`page-number ${page === currentPage ? 'active' : ''}`}
  >
    {page}
  </span>
))}
  {endPage < totalPages && (
    <span
      onClick={() => handlePageNumberClick(endPage + 1)}
      className="page-number"
    >
      ...
    </span>
  )}
  <button
    onClick={() => handlePageNumberClick(currentPage + 1)}
    disabled={currentPage === totalPages}
    className="next-button"
  >
    
  </button>
</div>
    </div>
  );
}

export default ThreadListV2;
