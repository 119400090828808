import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Table from "react-bootstrap/Table";
import SettingsLoader from "../../components/common/SettingsLoader";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {convertDateIntoLocalDate, convertTimeStampToUnix} from '../common/Functions';
import Select from "react-select";
import { set } from "date-fns";
import { is } from "date-fns/locale";
import { apiPath } from "../../config";
import like_icon from "../../images/like.svg";
import dislike_icon from "../../images/dislike.svg";
import like_source from "../../images/like_source.svg";
import dislike_source from "../../images/dislike_source.svg";
import { se } from "date-fns/locale";


function ResponseItemsList(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [channels, setChannels] = useState([]);
  const [filterChannels, setFilterChannels] = useState(searchParams.get("filterChannels") || "");
  const [startTime, setStartTime] = useState(searchParams.get("start_time") || "");
  const [endTime, setEndTime] = useState(searchParams.get("end_time") || "");
  const [isLoadingResponses, setIsLoadingResponses] = useState(true);
  const { getIdTokenClaims, user, isLoading } = useAuth0();
  const [responseList, setResponseList] = useState([]);
  const [searchableList, setSearchableList] = useState([]);
  const [channel_list, setChannelList] = useState([]);
  const [review, setReview] = useState(searchParams.get("review") || null);
  const [displayReview, setDisplayReview] = useState({
    value: searchParams.get("review") || null,
    label: searchParams.get("review") || "All",
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    }
  ]); 
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const getResponseList = async (query, channels, startTime, endTime, review, signal) => {
    try {
      let parameters = "";
      if (query) {
        parameters += `&query=${query}`;
      }
      if (channels && channels.length > 0) {
        // Get the channel IDs from the selected channels

        const filterChannelsArray = filterChannels.split(",").map(channel => channel.trim());
        console.log(filterChannelsArray); // Output: ["1", "2667"]

        // Append this string to the parameters string
        parameters += `&channel_ids=[${filterChannelsArray}]`;
      }
      
      if (startTime) {
        const start = Number(startTime);
        parameters += `&start_timestamp=${start}`;
      }
      if (endTime) {
        const end = Number(endTime);
        parameters += `&end_timestamp=${end}`;
      }
      
      if (review) {
        parameters += `&filter=${review}`;
      }
      // console.log(parameters);
      setIsLoadingResponses(true);
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        signal,
      };

      const url = `${apiPath()}/response_list?${parameters}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setResponseList(result);
      setSearchableList(result);
      setIsLoadingResponses(false);
      // console.log(result);
    } catch (error) {
      console.error("Error posting data:", error);
      if(error.message === "Failed to fetch"){
        setErrorMessage("You do not have permission to access this page.");
      }
      // setIsLoadingResponses(false);
    }
  };
  // useEffect(() => {
    

  //   if (!user || isLoading) {
  //     return;
  //   }
  //   getResponseList();
  // }, [user, isLoading]);

  const fetchChannels = async () => {
    try {
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
       const options = {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${idToken}`,
           'Content-Type': 'application/json'
         },
       };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/channel_list`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      //  console.log(result);
       setChannelList(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
      if(channel_list.length === 0 && user){
        fetchChannels();
      }
  }, [user]);

  useEffect(() => {
    if (!user || isLoading) {
      return;
    }
    const controller = new AbortController();
    const signal = controller.signal;
    console.log("Query:", query);

    getResponseList(query, channels, startTime, endTime, review, signal);

    return () => {
      controller.abort();
    }
}, [user, isLoading, query, channels, startTime, endTime, review]);






  useEffect(() => {
    setQuery(searchParams.get("query") || "");
    const searchInput = document.querySelector(".item-search");
    searchInput.value = searchParams.get("query") || "";
    // setChannels(searchParams.get("channels") || "");
    setFilterChannels(searchParams.get("filterChannels") || "");
    setStartTime(searchParams.get("start_time") || "");
    setEndTime(searchParams.get("end_time") || "");
    setReview(searchParams.get("review") || null);

    // find matching channel objects from the channel_list
    const filterChannels = searchParams.get("filterChannels") || "";
    const filterChannelArray = filterChannels.split(",").map(channel => channel.trim());
    console.log("Filter Channels array:", filterChannelArray);

    console.log("Channel List:", channel_list);

    // Filter the channels based on the filterChannelArray
    const selectedChannels = channel_list.filter((channel) => {
      const channelStr = String(channel.channel).trim(); // Convert number to string and trim
      const isSelected = filterChannelArray.includes(channelStr);
      console.log(`Checking if "${channelStr}" is in filterChannelArray: ${isSelected}`);
      return isSelected;
    });

    console.log("Selected Channels:", selectedChannels);

    // Set the channels state with the selected channels
    setChannels(selectedChannels.map((channel) => ({
      value: String(channel.channel), // Ensure value is a string
      label: `${channel.channel} - ${channel.name}`,
    })));

    

  }, [searchParams, channel_list]);

  let searchTimeout;

  const handleSearch = (e) => {
    e.preventDefault();
    const newQuery = e.target.value;

    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        query: newQuery,
      });
    }, 500);
  };

  const handleChannelChange = (selectedOptions) => {
    // console.log(selectedOptions);
    setChannels(selectedOptions);
    const options = selectedOptions.map((channel) => channel.value).join(",");
    setFilterChannels(options);
  };
  
  useEffect(() => {
    console.log("Filter Channels:", filterChannels);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      filterChannels: filterChannels,
    });
  }, [filterChannels]);
  

  const handleDateSelection = (ranges) => {
    setState([ranges.selection]);
    // console.log("Selected Range:", ranges.selection);

    let startDate = new Date(ranges.selection.startDate);
    let endDate = new Date(ranges.selection.endDate);

    // Log the initial dates for debugging
    console.log("Initial Start Date:", startDate);
    console.log("Initial End Date:", endDate);
    // Hide the date picker if a selection is made
  //   if (startDate.getTime() !== endDate.getTime()) {
  //     setShowDatePicker(false);
  // }
    // Check if start date and end date are the same
    if (startDate.getTime() === endDate.getTime()) {
        // Add 23 hours and 59 minutes (23 hours * 60 minutes/hour + 59 minutes * 60 seconds/minute * 1000 milliseconds/second)
        endDate = new Date(endDate.getTime() + ((23 * 60 + 59) * 60 + 59) * 1000);
        console.log("Adjusted End Date:", endDate); // Log adjusted end date for debugging
    }

    setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        start_time: convertTimeStampToUnix(startDate),
        end_time: convertTimeStampToUnix(endDate),
    });

    // Log the Unix timestamps for debugging
    console.log("Unix Start Time:", convertTimeStampToUnix(startDate));
    console.log("Unix End Time:", convertTimeStampToUnix(endDate));

    
};

  const handleReviewChange = (selectedOptions) => {
    setDisplayReview(selectedOptions);
    // console.log(selectedOptions);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      review: selectedOptions.value,
    });
  };



  const toggleDatePicker = (e) => {
    e.preventDefault();
    setShowDatePicker(!showDatePicker);
  };

  document.addEventListener('click', function (e) {
    //   e.preventDefault();
    let ele = e.target;
  
    if(ele.className !== "toggle-date-picker" && ele.closest('.date-picker-box') === null){

        setShowDatePicker(false);
    }
    });
  
    if(errorMessage !== "" && !isLoadingResponses){

      return (
        <div>
          <h3 className="main-error-message">{errorMessage}</h3>
        </div>
      )
      }

  return (
    <div>
      <div className="response-filters">
        <div className="filters-inputs">
          <input
            className="item-search"
            type="text"
            placeholder="Search by ID/ Question"
            onChange={handleSearch}
          />
        </div>
        
        <div className="filters-input">
          <div className="date-picker-wrap">
              <div
                className="toggle-date-picker"
                onClick={toggleDatePicker}
              ></div>
              {showDatePicker && (
                <div className="date-picker-box">
                  <DateRangePicker
                    editableDateInputs={true}
                    onChange={handleDateSelection}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    months={1}
                    rangeColors={["#053F98"]}
                  />
                </div>
              )}
            </div>
        </div>
        <div className="filters-input filters-input-sm">
              <Select 
                id="select-reviews"
                onChange={handleReviewChange}
                value={displayReview}
                options={[
                  { value: "", label: "All" },
                  { value: "likes", label: "Likes" },
                  { value: "dislikes", label: "Dislikes" },
                ]}
                isSearchable
                placeholder="Filter by reviews"
                />
        </div>

      </div>
      <div className="response-filters">
        <div className="filters-input">
          <Select
                id="select-channels"
                onChange={handleChannelChange}
                value={channels}
                options={channel_list.map((channel, index) => ({
                  value: channel.channel,
                  label: (channel.channel +" - " +channel.name),
                }))}
                isSearchable
                placeholder="Select Channels"
                isMulti
              />
          </div>
        </div>
      <div className="table-box items-box-table">
        <Table hover>
          <thead>
            <tr>
              <th className="table-name-th">Question</th>
              <th>Channel</th>
              <th></th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
          {responseList &&
            responseList.map((item, index) => (
                  <tr key={index}>
                    <td className="table-name-td">
                      <Link
                        className="response-question-link"
                        to={`/response/${encodeURIComponent(item.response_id)}`}
                        title={item.question}
                      >
                        {item.question}
                      </Link>
                    </td>
                    <td>{item.channel_id} - {item.channel_name}</td>
                    <td className="table-reviews-td">
                    
                      
                      {item.likes > 0 && (
                        <>
                      <img
                      className="table-like-img default-cursor"
                      src={item.user_feedback === 'like' ? like_source : like_icon} alt="like icon" />{" "}
                      {item.likes > 0 ? (<span className="item-likes">{item.likes}</span>) : (<span className="item-likes"></span>)}
                      </>
                      )}
                      {item.dislikes > 0 && (
                      <>
                      <img
                        className="table-dislike-img default-cursor"
                        src={item.user_feedback === 'dislike' ? dislike_source : dislike_icon}
                        alt="dislike icon"
                      />{" "}
                      {item.dislikes > 0 ? (<span className="item-dislikes">{item.dislikes}</span>) : (<span className="item-dislikes"></span>)}
                      </>
                      )}
                    
                  </td>
                    <td>{convertDateIntoLocalDate(item.date)}</td>
                  </tr>
                ))}
            {responseList && responseList.length <= 0 && !isLoadingResponses && (
                <tr>
                  <td colSpan="7">
                    <div  className="no-items">No responses found!</div>
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {isLoadingResponses && <SettingsLoader />}
        <div className="table-footer"></div>
      </div>
    </div>
  );
}

export default ResponseItemsList;
