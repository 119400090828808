import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AccountName from "./AccountName";
import SettingsLoader from "../common/SettingsLoader";
import { apiPath } from "../../config";

function AccountTab(props) {
  const {getIdTokenClaims} = useAuth0();
  const [accountInfo, setAccountInfo] = useState([]);
  const [forums, setForums] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  
  const fetchAccountInfo = async () => {
    try {
       // Get the ID token from Auth0
       const getidToken = await getIdTokenClaims();
       const idToken = getidToken.__raw;
        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
        };

      const path = `/account_info`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      setAccountInfo(result);
      setForums(result.forums)
      // setSources(result.data_sources);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    if(props.urlParam === "account-management") {
      if(accountInfo.length === 0){
        fetchAccountInfo();
      }
    }
  }, [props.urlParam]);


  const openNewForumModel = (e) => {
      e.preventDefault();
      document.getElementById("request_new_forum").classList.add("show");
  };

  const hideNewForumModel = (e) => {
      e.preventDefault();
      document.getElementById("request_new_forum").classList.remove("show");    
  }

 




const disableBtn = (e) => {
  e.preventDefault();
}





  if (isLoading) {
    return (
      <SettingsLoader />
    );
  }

  return (
    <div>
        <div className="account-tab-box">
            <div className="account-tab-header">
                <AccountName accountName={accountInfo.name} />
            </div>
        </div>
        
      </div>
  );
}

export default AccountTab;
