import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import SettingsLoader from "../common/SettingsLoader";

import {marked} from 'marked';

function SalesDataList(props) {
    const {products} = props;
    return (
        <div>
        <Accordion className="sales-guide-summery">
        {products && products.map((product, index) => (
                <Accordion.Item eventKey={index}>
                <Accordion.Header><span>{product.name}</span> {product.performance_criteria && product.performance_criteria.length <= 0 ? <SettingsLoader /> : null }</Accordion.Header>
                <Accordion.Body>
                <Accordion defaultActiveKey={index}>
                        {product.performance_criteria && product.performance_criteria.map((item, index) => (
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>{item.criterion}</Accordion.Header>
                                <Accordion.Body>
                                    <div className="sales-product-details" dangerouslySetInnerHTML={{__html:marked(item.performance_data)}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                </Accordion>
                </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
        </div>
    );
}

export default SalesDataList;