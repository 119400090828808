import React, {useState, useEffect} from "react";
import { Table } from "react-bootstrap";
import InviteUser from "./InviteUser";
import { useAuth0 } from "@auth0/auth0-react";

import { ToastContainer, toast } from 'react-toastify';
import UserRoles from "./partials/UserRoles";
import SettingsLoader from "../common/SettingsLoader";
import { useParams } from "react-router";
import { apiPath } from "../../config";

function UserTable(props) {
    const {tab} = useParams();
    const { getIdTokenClaims } = useAuth0();
    const [users, setUsers] = useState([]);
    const [deleteUserId, setDeleteUserId] = useState("");
    const [newUser, setNewUser] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');

    const getUsers = async () => {
        try {
        // Get the ID token from Auth0
       const getidToken = await getIdTokenClaims();
       const idToken = getidToken.__raw;
        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
        };

        const path = `/users`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        // console.log(result)
        setUsers(result); // Update the state with the retrieved users
        } catch (error) {
        console.error("Error posting data:", error);
        }
    };

    useEffect(() => {
            if(users.length === 0 && tab === "user-management"){
                getUsers();
            }
    }, [tab]);

    const calculateTimeSinceLastLogin = (lastLogin) => {
        if (lastLogin === null) {
        return "Never logged in";
        }

        const currentTime = new Date();
        const lastLoginTime = new Date(lastLogin * 1000); // Convert seconds to milliseconds
        const timeDifference = currentTime - lastLoginTime;

        // Calculate time difference in seconds, minutes, hours, and days
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
        } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        } else {
        return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
        }
    };

    const deleteUser = async (e) => {
        if (e.target.classList.contains("disabled")) {
            return null;
        }
        e.target.classList.add("disabled");
        const userId = e.target.getAttribute("data-ref");
        try {
            const toastid = toast.loading("Please wait...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            const getidToken = await getIdTokenClaims();
            const idToken = getidToken.__raw;
                const options = {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                },
                };
            const path = `/user/${userId}`;
            const url = `${apiPath()}${path}`;
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // const result = await response.json();
            // console.log(response);
            setDeleteUserId("");
            document.getElementById("delete_user_modal").classList.remove("show");
            toast.update(toastid, {
                render: "User deleted successfully!",
                type: "success",
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            getUsers();
            e.target.classList.remove("disabled");
            // Handle the response as needed
        } catch (error) {
            e.target.classList.remove("disabled");
            console.error('Error posting data:', error);
        }
    };
    

    const handleDeleteUser = (e) => {
        e.preventDefault();
        const userId = e.target.getAttribute("data-ref");
        setDeleteUserId(userId);
        document.getElementById("delete_user_modal").classList.add("show");
    };

    const handleCancelDeleteUser = (e) => {
        e.preventDefault();
        setDeleteUserId("");
        document.getElementById("delete_user_modal").classList.remove("show");
    };

    const handleSort = (column) => {
        const sortedUsers = [...users].sort((a, b) => {
          // Compare the values based on the sort order and column
          if (sortOrder === 'asc') {
            return a[column] > b[column] ? 1 : -1;
          } else {
            return a[column] < b[column] ? 1 : -1;
          }
        });
      
        // Update the state variables
        setUsers(sortedUsers);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    useEffect(() => {
        if(newUser){
            getUsers();
            setNewUser(false);
        }
    },[newUser]);

    return(
        <div className="settings-tabs">
            <div className="table-header">
                <InviteUser setNewUser={setNewUser} />
                <div className="users-info">
                    Total users: <span className="users-count">{users.length}</span> plus <span className="users-count">{users.filter((user) => user.last_login === null).length}</span> outstanding invitations
                </div>
            </div>
            <div className="table-box users-table">
                <Table hover>
                    <thead>
                        <tr>
                        <th className="table-name-th"  onClick={() => handleSort('name')}>Name {sortOrder !== 'asc' ? '▲' : '▼'}</th>
                        <th onClick={() => handleSort('groups')}>Roles {sortOrder !== 'asc' ? '▲' : '▼'}</th>
                        <th onClick={() => handleSort('user_id')}>Email address {sortOrder !== 'asc' ? '▲' : '▼'}</th>
                        <th onClick={() => handleSort('last_login')}>Status {sortOrder !== 'asc' ? '▲' : '▼'}</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {(users.length > 0) &&  (
                        users.map((user, index) => (
                            <tr key={index}>
                                <td className="table-name-td">{user.name || "-"}</td>
                                <td>
                                    <UserRoles userGroups={user.groups} userId={user.user_id} />
                                </td>
                                <td>{user.user_id}</td>
                                <td><span className="last_login">{calculateTimeSinceLastLogin(user.last_login)}</span></td>
                                <td><a className="delete-user-btn" data-ref={user.user_id} onClick={(e)=>handleDeleteUser(e)} href="#"></a></td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </Table>
                {users.length === 0 && (
                    <SettingsLoader />
                )}
            </div>
            <div id="delete_user_modal" className="delete-user-modal">
                <div className="delete-user-modal-content">
                    <div className="delete-user-modal-body">
                        <p>Are you sure you want to delete this user?</p>
                        <h5>{deleteUserId}</h5>
                    </div>
                    <div className="delete-user-modal-footer">
                        <a href="#" className="delete-user-modal-btn cancel" onClick={(e)=>handleCancelDeleteUser(e)}>Cancel</a>
                        <a href="#" className="delete-user-modal-btn done" data-ref={deleteUserId} onClick={(e)=>deleteUser(e)}>Delete</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserTable;
