import React from "react";

function LoaderWithText(props) {
    return(
        <div className={props.class ? props.class : 'chat-loading-wrap-app'}>
            <div className="chat-loader">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <div className='loading-text'>{props.text}</div>
        </div>
    );
}

export default LoaderWithText;