import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { isEmptyObject } from "jquery";
import { useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../../../layout/MainAppWrap";
import SimpleHeader from "../../SimpleHeader";
import SettingsLoader from "../../common/SettingsLoader";
import { Link, useNavigate } from "react-router-dom";
import { apiPath } from "../../../config";
function UpdateKs(props) {
    const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const { ks_id } = useParams();
  const [ksDetails, setKsDetails] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [selectedConfidential, setSelectedConfidential] = useState(false);
  const [sourceName, setSourceName] = useState("");
  const [sourceDiscription, setSourceDiscription] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [zendeskUrl, setZendeskUrl] = useState("");
  const [githubOrgName, setGithubOrgName] = useState("");
  const [bucket, setBucket] = useState("");
  const [key, setKey] = useState("");
  const [ksID, setKsID] = useState("");

  const [selectGrey, setSelectGrey] = useState(false);
  const [displayChannelList, setDisplayChannelList] = useState(false);
  const [displayWebsiteUrl, setDisplayWebsiteUrl] = useState(false);
  const [displayZendeskUrl, setDisplayZendeskUrl] = useState(false);
  const [displayGithubOrgName, setDisplayGithubOrgName] = useState(false);
  const [displayCustomDataSource, setDisplayCustomDataSource] = useState(false);
  const [isLoadingKS, setIsLoadingKS] = useState(false);
    const [channelList, setChannelList] = useState([]);

  const handleChannelChange = (selectedOptions) => {
    setSelectedChannel(selectedOptions);
  };

  const handleTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions);
  };


  const toastHandler = (message, type) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const getKs = async () => {
    try {
      setIsLoadingKS(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source/${ks_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      setSourceName(result.name);
      setSourceDiscription(result.description);
      setSelectedType({
        value: result.type,
        label: result.type,
      });
      setKsID(result.ks_id);
      setSelectedConfidential(result.confidential);
      if (result.type == "community_forum") {
        setSelectedChannel({
          value: result.type,
          label: result.type,
        });
      }
      if (result.type == "website") {
        setWebsiteUrl(result.url);
      }
      if (result.type == "zendesk_knowledge_base") {
        setZendeskUrl(result.url);
      }
      if (result.type == "github") {
        setGithubOrgName(result.gihub_org);
      }
      if (result.type == "aws_s3") {
        setBucket(result.aws_s3.bucket);
        setKey(result.aws_s3.key);
      }

      setIsLoadingKS(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKS(false);
    }
  };

  const fetchChannels = async () => {
    try {
        // Get the ID token from Auth0
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            },
        };
        // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
        const path = `/channel_list`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        // console.log(result);
        setChannelList(result);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

  useEffect(() => {
    if (ksDetails.length === 0 && user) {
      getKs();
    }
    if( channelList.length === 0 && user){
        fetchChannels();
    }
  }, [user, ks_id]);

  const UpdateKs = async (data) => {
    try {
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source?ksid=${ksID}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      toast.update(toastid, {
        render: "Knowledge source updated successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setSourceName("");
      setSourceDiscription("");
      setSelectedType([]);
      setSelectedChannel([]);
      setWebsiteUrl("");
      setZendeskUrl("");
      setGithubOrgName("");
      setBucket("");
      setKey("");
      setSelectedConfidential(false);
      document.getElementById("ks-submit-btn-2").classList.remove("disabled");
        navigate("/settings/knowledge-sources");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Function to validate URLs
    const isValidUrl = (url) => {
      // Regular expression for a valid URL
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlRegex.test(url);
    };

    const removeUrlParams = (url) => {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/i;
      if (urlRegex.test(url)) {
        const urlObj = new URL(url);
        // Remove "https://" and trailing slashes from the origin
        const cleanedOrigin = urlObj.origin
          .replace(/^https?:\/\//, "")
          .replace(/\/+$/, "");
        return cleanedOrigin;
      }
      return url;
    };

    // add validation here
    if (sourceName.length === 0) {
      toastHandler("Please input source name", "error");
      return;
    }
    if (sourceDiscription.length === 0) {
      toastHandler("Please input source description", "error");
      return;
    }
    if (selectedType.length === 0) {
      toastHandler("Please select source type", "error");
      return;
    }
    if (
      selectedType.value === "community_forum" &&
      selectedChannel.length === 0
    ) {
      toastHandler("Please select channel", "error");
      return;
    }
    if (selectedType.value === "website" && websiteUrl.length === 0) {
      toastHandler("Please input website url", "error");
      return;
    }
    if (
      selectedType.value === "zendesk_knowledge_base" &&
      zendeskUrl.length === 0
    ) {
      toastHandler("Please input zendesk url", "error");
      return;
    }
    if (selectedType.value === "github" && githubOrgName.length === 0) {
      toastHandler("Please input github organization name", "error");
      return;
    }
    if (selectedType.value === "aws_s3" && bucket.length === 0) {
      toastHandler("Please input bucket", "error");
      return;
    }
    if (selectedType.value === "aws_s3" && key.length === 0) {
      toastHandler("Please input key", "error");
      return;
    }

    // Validate zendesk and website URLs
    if (selectedType.value === "website" && !isValidUrl(websiteUrl)) {
      toastHandler("Please enter a valid website URL", "error");
      return;
    }
    if (
      selectedType.value === "zendesk_knowledge_base" &&
      !isValidUrl(zendeskUrl)
    ) {
      toastHandler("Please enter a valid Zendesk URL", "error");
      return;
    }
    if (e.target.classList.contains("disabled")) {
      return null;
    }
    e.target.classList.add("disabled");

    let data = {};
    data.name = sourceName;
    data.description = sourceDiscription;
    data.type = selectedType.value;
    data.confidential = selectedConfidential;
    data.source_details = data.source_details || {};
    if (selectedType.value === "community_forum") {
      data.source_details.channel = data.source_details.channel || {};
      data.source_details.channel.url = selectedChannel.value;
    }
    if (selectedType.value === "website") {
      data.source_details.url_filter = data.source_details.url_filter || [];
      const filterUrl = removeUrlParams(websiteUrl);
      data.source_details.url = websiteUrl;
      data.source_details.url_filter.push(filterUrl);
    }
    if (selectedType.value === "zendesk_knowledge_base") {
      data.source_details.zendesk_domain = zendeskUrl;
    }
    if (selectedType.value === "github") {
      data.source_details.github_org = githubOrgName;
    }
    if (selectedType.value === "aws_s3") {
      data.source_details.aws_s3 = data.source_details.aws_s3 || {};
      data.source_details.aws_s3.bucket = bucket;
      data.source_details.aws_s3.key = key;
    }
    data = JSON.stringify(data);
    // console.log(data);
    UpdateKs(data);
  };

  useEffect(() => {
    if (channelList && channelList.length > 0) {
      setFilteredChannels(
        channelList
          .filter((channel) => channel.type == "community_forum")
          .map((channel) => ({
            value: channel.url,
            label: channel.url,
          }))
      );
    }
  }, [channelList]);

  useEffect(() => {
    if (filteredChannels && filteredChannels.length > 0) {
      setSelectedChannel(filteredChannels[0]);
    }
  }, [filteredChannels]);

  useEffect(() => {
    if (selectedType.value == "community_forum") {
      setDisplayChannelList(true);
      // console.log(channelList.length);
      if (channelList.length <= 1) {
        setSelectGrey(true);
      } else {
        setSelectGrey(false);
      }
    } else {
      setDisplayChannelList(false);
      setSelectGrey(false);
    }

    if (selectedType.value == "website") {
      setDisplayWebsiteUrl(true);
    } else {
      setDisplayWebsiteUrl(false);
    }

    if (selectedType.value == "zendesk_knowledge_base") {
      setDisplayZendeskUrl(true);
    } else {
      setDisplayZendeskUrl(false);
    }

    if (selectedType.value == "github") {
      setDisplayGithubOrgName(true);
    } else {
      setDisplayGithubOrgName(false);
    }

    if (selectedType.value == "aws_s3") {
      setDisplayCustomDataSource(true);
    } else {
      setDisplayCustomDataSource(false);
    }
  }, [selectedType]);

  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader />
        <Container fluid="xxl" className="ks-form">
          <Row>
            <Col className="settings-header">
            {!isLoadingKS && (
              <h1>Edit Source</h1>
            )}
            </Col>
          </Row>
          <Row>
            <Col>
              {isLoadingKS && <SettingsLoader />}
              {!isLoadingKS && (
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="source-name">
                        Source name:
                        <input
                          type="text"
                          className="form-control"
                          id="source-name"
                          name="source-name"
                          placeholder=""
                          value={sourceName}
                          onChange={(e) => setSourceName(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="source-name">
                        Discription:
                        <input
                          type="text"
                          className="form-control"
                          id="source-des"
                          name="source-des"
                          placeholder=""
                          value={sourceDiscription}
                          onChange={(e) => setSourceDiscription(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="source-type">
                        Type:
                        <Select
                          id="type"
                          onChange={handleTypeChange}
                          value={selectedType}
                          options={[
                            {
                              value: "community_forum",
                              label: "Community forum",
                            },
                            { value: "website", label: "Website" },
                            {
                              value: "zendesk_knowledge_base",
                              label: "Zendesk knowledge base",
                            },
                            { value: "github", label: "Github" },
                            { value: "aws_s3", label: "AWS S3" },
                          ]}
                          isSearchable
                          placeholder="Select Type"
                        />
                      </label>
                    </div>
                  </div>
                  {displayChannelList && (
                    <div className="col-12">
                      <div className="input-group">
                        <label>
                          <Select
                            id="type"
                            className={selectGrey ? "select-grey" : ""}
                            onChange={handleChannelChange}
                            value={selectedChannel}
                            options={filteredChannels}
                            isSearchable
                            placeholder="Select channel"
                            isDisabled={selectGrey}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  {displayWebsiteUrl && (
                    <div className="col-12">
                      <div className="input-group">
                        <label htmlFor="website-url">
                          Website URL:
                          <input
                            className="form-control"
                            id="website-url"
                            name="website-url"
                            placeholder=""
                            value={websiteUrl}
                            onChange={(e) => setWebsiteUrl(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  {displayZendeskUrl && (
                    <div className="col-12">
                      <div className="input-group">
                        <label htmlFor="zendesk-url">
                          Zendesk URL:
                          <input
                            type="text"
                            className="form-control"
                            id="zendesk-url"
                            name="zendesk-url"
                            placeholder=""
                            value={zendeskUrl}
                            onChange={(e) => setZendeskUrl(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  {displayGithubOrgName && (
                    <div className="col-12">
                      <div className="input-group">
                        <label htmlFor="github-org-name">
                          Github organization name:
                          <input
                            type="text"
                            className="form-control"
                            id="github-org-name"
                            name="github-org-name"
                            placeholder=""
                            value={githubOrgName}
                            onChange={(e) => setGithubOrgName(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  {displayCustomDataSource && (
                    <div className="col-12">
                      <div>
                        <div className="input-group">
                          <label htmlFor="custom-data-source">
                            Bucket:
                            <input
                              type="text"
                              className="form-control"
                              id="bucket"
                              name="bucket"
                              placeholder=""
                              value={bucket}
                              onChange={(e) => setBucket(e.target.value)}
                            />
                          </label>
                        </div>
                        <div className="input-group">
                          <label htmlFor="custom-data-source">
                            Key:
                            <input
                              type="text"
                              className="form-control"
                              id="key"
                              name="key"
                              placeholder=""
                              value={key}
                              onChange={(e) => setKey(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <div className="input-group">
                      <div className="col-xs-12 custom-response-checkbox td-relative">
                        <label htmlFor="pop-source-update-confidentials-1">
                          <span className="checkbox-label">Confidential</span>
                          <input
                            type="checkbox"
                            id="pop-source-update-confidentials-1"
                            name="pop-source-confidentials"
                            checked={selectedConfidential}
                            onChange={(e) =>
                              setSelectedConfidential(e.target.checked)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="delete-user-modal-footer">
                      <div>
                      <Link to="/settings/knowledge-sources" className="delete-user-modal-btn cancel">Cancel</Link>
                        <a
                          href="#"
                          id="ks-submit-btn-2"
                          className="delete-user-modal-btn update-btn"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Update
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </MainAppWrap>
  );
}

export default UpdateKs;
