import React, {useState, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Table, Row, Col } from "react-bootstrap";
import CustomDateConverter from "../common/CustomDateConverter";
import { toast } from "react-toastify";
import UnixDateConverter from "../common/UnixDateConverter";
import SettingsLoader from "../common/SettingsLoader";
import { Link } from "react-router-dom";
import {convertDateIntoLocalDate} from "../common/Functions";
import { useParams } from "react-router-dom";
import { apiPath } from "../../config";



function KnowledgeSource(props) {
  const { tab } = useParams(); // Using the URL parameter
    const {getIdTokenClaims} = useAuth0();
    
    const [sources, setSources] = useState([]);
  
    
    const [updateSource, setUpdateSource] = useState([]);
    
    const [isUpdateBox, setIsUpdateBox] = useState(false);
    

    const [channelList, setChannelList] = useState([]);

    const [isLoadingKS, setIsLoadingKS] = useState(false);



    const fetchSources = async () => {
        try {
          setIsLoadingKS(true);
          // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
           const options = {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${idToken}`,
               'Content-Type': 'application/json'
             },
           };
          // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
          const path = `/ks_list`;
          const url = `${apiPath()}${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          // console.log("ks- ",result);
          setSources(result);
          setIsLoadingKS(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoadingKS(false);
        }
      };

      const fetchChannels = async () => {
        try {
          // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
           const options = {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${idToken}`,
               'Content-Type': 'application/json'
             },
           };
          // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
          const path = `/channel_list`;
          const url = `${apiPath()}${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          //  console.log(result);
          setChannelList(result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const handleEditSource = (e) => {
        e.preventDefault();
        const ks_id = e.target.getAttribute("data-id");
        const getSource = sources.filter((source) => source.ks_id === parseInt(ks_id)); // Assuming ks_id is a number
        // console.log(getSource);
        setUpdateSource(getSource[0]);
        setIsUpdateBox(true);
      }
      
      
      
      
      const transformString = (inputString) => {
        // Split the string by underscores, capitalize each word, and join them with spaces
        return inputString
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };

      useEffect(() => {
            if(sources.length === 0 && tab === "knowledge-sources"){
                fetchSources();
            }
            if(channelList.length === 0 && tab === "knowledge-sources"){
                fetchChannels();
            }
      }, [tab]);

  return (
    
    <div className="settings-tabs">
      <div className="header-button-adjust">
        <Link to="/settings/ks/create" className="request_btn">Add a knowledge source</Link>
      </div>
      <div className="table-box">
          {/* <div className="table-header">
            <h5>Knowledge Sources</h5>
          </div> */}
          <Table hover>
              <thead>
              <tr>
                  <th className="table-name-th">Name</th>
                  <th>Type</th>
                  <th>URL</th>
                  {/* <th>Stats</th> */}
                  <th>Confidential</th>
                  <th>Last updated</th>
                  {/* <th className="table_action">Action</th> */}
              </tr>
              </thead>
              <tbody>
              {sources &&
                sources.map((source, index) => (
                  <tr key={index}>
                      <td className="table-name-td">
                      <Link to={`/settings/ks-details/${source.ks_id}`}>
                        {source.name || "-"}
                      </Link>
                      </td>
                      <td>{source.type || "-"}</td>
                      <td>
                      {source.type === "aws_s3" && (
                        <>
                          s3://{source.source_details?.aws_s3?.bucket}/{source.source_details?.aws_s3?.key}
                        </>
                      )}
                      {source.type === "zendesk_knowledge_base" && (
                        <>
                          {source.source_details.zendesk_domain || "-"}
                        </>
                      )}
                      {source.type === "github" && (
                        <>
                          https://github.com/{source.source_details.github_org || "-"}
                        </>
                      )}
                      {source.type === "website" && (
                        <>
                          {source.source_details.url || "-"}
                        </>
                      )}
                      {source.type === "community_forum" && (
                        <>
                          {source.source_details.channel.url || "-"}
                        </>
                      )}
                      </td>
                      {/* <td>
                      {Object.entries(source.stats)
                        .map(([key, value]) => (
                          <span key={key}>
                            {key === "updated_at" ? (
                              <><b>{transformString(key)}:</b> <UnixDateConverter unixTimestamp={value}/></>
                            ) : (
                              <><b>{transformString(key)}:</b> {value.toLocaleString()}</>
                            )}
                            <br />
                          </span>
                        ))}
                      </td> */}
                      <td align="left">
                        <div className="col-xs-12 custom-response-checkbox td-relative">
                          <label htmlFor={"s-confidential-"+index}>
                            <span className="checkbox-label"></span>
                            <input
                              type="checkbox"
                              name="confidential-check"
                              id={"ss-confidential-"+index}
                              checked={source.confidential}
                              onChange={(e) => {
                                if(source.must_stay_confidential === true){
                                  toast.error("This source must stay confidential");
                                  return;
                                }
                                e.target.checked
                                }}
                              disabled
                            />
                            <span className="checkmark"></span>
                          </label>
                      </div>
                      </td>
                      <td>{convertDateIntoLocalDate(source.updated_at)}</td>
                      {/* <td className="table_action">
                        {source.can_edit && (
                          <div>
                            <Link to={`/settings/ks/update/${source.ks_id}`} className="editSourceBtn"></Link>
                            <a href="#" className="delete-user-modal-btn delete_btn" data-id={source.ks_id} data-name={source.name} onClick={(e)=> handleDeleteWarning(e)}></a>
                          </div>
                        )}
                      </td> */}
                  </tr>
                  ))}
                  {sources.length === 0 && !isLoadingKS && (
                      <tr>
                      <td colSpan="6" className="text-center">
                          No knowledge sources found
                      </td>
                      </tr>
                  )}
              </tbody>
          </Table>
          {isLoadingKS && (
            <SettingsLoader />
          )}
          <div className="table-footer">
            
            {/* <UpdateKs
              channelList={channelList}
              fetchSources={fetchSources}
              updateSource={updateSource}
              isUpdateBox={isUpdateBox}
              setIsUpdateBox={setIsUpdateBox}
            /> */}
          </div>
        </div>
    </div>
  );
}

export default KnowledgeSource;