import React, { useState, useEffect, useRef } from "react";
import {useAuth0} from "@auth0/auth0-react";
import { toast } from 'react-toastify';
import { apiPath } from "../../../config";

function UserRoles(props) {
    const { getIdTokenClaims } = useAuth0();
    // Base roles
    const baseRoles = ["Admin", "User"];

    // Filter out base roles from props.userGroups and combine them
    const additionalRoles = props.userGroups.filter(role => !baseRoles.includes(role));
    const allRoles = [...baseRoles, ...additionalRoles];

    // State to manage user groups
    const [userGroups, setUserGroups] = useState(props.userGroups || []);
    const [tempUserGroups, setTempUserGroups] = useState(props.userGroups || []);
    // State for modal visibility
    const [isModalVisible, setIsModalVisible] = useState(false);
    // State to temporarily store proposed role changes
    const [proposedUserGroups, setProposedUserGroups] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const editModeRef = useRef(null); // Ref for the edit mode container

    // Function to close edit mode if clicked outside
    const handleClickOutside = (event) => {
        if (editModeRef.current && !editModeRef.current.contains(event.target)) {
            setIsEditMode(false);
        }
    };

    // Effect to add/remove event listener
    useEffect(() => {
        if (isEditMode) {
            // Attach listener when in edit mode
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            // Remove listener when not in edit mode
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Cleanup function to remove listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEditMode]);

    // Update local state when props.userGroups changes
    useEffect(() => {
        setUserGroups(props.userGroups);
        setTempUserGroups(props.userGroups);
    }, [props.userGroups]);

    const updateRole = async (updatedRoles) => {
        try {
            const toastid = toast.loading("Please wait...", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            const updatedRolesJson = JSON.stringify({ groups: updatedRoles });
            // Get the ID token from Auth0
            const getidToken = await getIdTokenClaims();
            const idToken = getidToken.__raw;
                const options = {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                },
                body: updatedRolesJson,
                };
            const path = `/user/${props.userId}`;
            const url = `${apiPath()}${path}`;
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // const result = await response.json();
            toast.update(toastid, {
                render: "User roles updated successfully!",
                type: "success",
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });

            // Update state on successful API call
            setUserGroups(updatedRoles);
            setIsEditMode(false);
        } catch (error) {
            console.error("Error updating roles:", error);
        }
    };

    const prepareRoleChange = (role) => {
        const updatedUserGroups = tempUserGroups.includes(role)
            ? tempUserGroups.filter(r => r !== role) // Remove role
            : [...tempUserGroups, role]; // Add role
        setTempUserGroups(updatedUserGroups);
    };

    const handleRoleUpdate = (e) => {
        e.preventDefault();
        updateRole(proposedUserGroups);
        setIsModalVisible(false); // Hide modal
    };

    const handleRoleUpdateCancel = (e) => {
        e.preventDefault();
        setIsModalVisible(false); // Simply hide the modal
    };

    const handleEditButton = (e) => {
        e.preventDefault();
        setIsEditMode(isEditMode => !isEditMode);
    };

    const handleCloseBtn = (e) => {
        e.preventDefault();
        setIsEditMode(false);
    };

    const handleUpdateButton = (e) => {
        e.preventDefault();
        setProposedUserGroups(tempUserGroups);
        setIsModalVisible(true); // Show modal for confirmation
    };

    return (
        <div className="user-roles-wrap" ref={isEditMode ? editModeRef : null}>
            <a href="#" className="user-role-dropdown" onClick={handleEditButton}>
                {userGroups.map((role, index) => (
                    <span key={index}>{role}{index < (userGroups.length - 1) ? ', ':''}</span>
                ))}
            </a>
            {isEditMode && (
                <ul className="user-role-list">
                    {allRoles.map((role, index) => (
                        <li key={index} className="custom-checkbox">
                            <label>
                                {role}
                                <input 
                                    type="checkbox" 
                                    checked={tempUserGroups.includes(role)}
                                    onChange={() => prepareRoleChange(role)}
                                    value={role}
                                    className="role-checkbox"
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    ))}
                    <li className="user-role-buttons">
                        <a href="#" className="close-role-btn" onClick={handleCloseBtn}></a>
                        <a href="#" className="update-role-btn" onClick={handleUpdateButton}></a>
                    </li>
                </ul>
            )}

            {isModalVisible && (
                <div id="userRolesModel" className="delete-user-modal">
                    <div className="delete-user-modal-content">
                        <div className="delete-user-modal-body">
                            <p>Are you sure you want to assign <b>"{props.userId}"</b> the following Roles:</p>
                            <h5><span>{proposedUserGroups.join(", ")}</span></h5>
                        </div>
                        <div className="delete-user-modal-footer">
                            <a href="#" className="delete-user-modal-btn cancel" onClick={handleRoleUpdateCancel}>Cancel</a>
                            <a href="#" className="delete-user-modal-btn update-btn" onClick={handleRoleUpdate}>Update</a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserRoles;
