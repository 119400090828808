import React, {useEffect, useState} from "react";
import { Form } from "react-bootstrap";
import { apiPath } from "../../../config";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from "react-toastify";
import { set } from "date-fns";

function AnswerFeedback(props) {
    const [feedback, setFeedback] = useState("");
    const [seccess, setSuccess] = useState(false);
    const [showAnswerFeedback, setShowAnswerFeedback] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const { getIdTokenClaims } = useAuth0();

    const handleAnswerFeedback = () => {
        // console.log("AnswerFeedback");
        // console.log(props.currentRanking);
        setShowAnswerFeedback(!showAnswerFeedback);
    }

    const sendFeedback = async (response_id, feedback) => {
        try {
          let postData = {};
            let thumbs_up = 0;
            let thumbs_down = 0;
          if(props.currentRanking){
            if(props.currentRanking.thumbs_up){
                thumbs_up = props.currentRanking.thumbs_up;
            }
            if(props.currentRanking.thumbs_down){
                thumbs_down = props.currentRanking.thumbs_down;
            }
          }
          if (feedback) {
            postData = {
              response_id: response_id,
              ratings: {
                text: feedback,
                thumbs_down: thumbs_down,
                thumbs_up: thumbs_up,
              },
            };
          } 
          postData = JSON.stringify(postData);
          // console.log("postData-", postData);
    
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const options = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: postData,
          };
    
          const rankPath = `/response/ranking`;
          const url = `${apiPath()}${rankPath}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          console.log("result-", result);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
          return result;
        } catch (error) {
          console.error("Error fetching data:", error);
          return { error: error };
        }
      };

    const handleAnswerFeedbackSubmit = async () => {
      if(disableSubmit){
        return;
      }
        // console.log("feedback- ", feedback);
        if(feedback === "" || feedback === null || feedback === undefined){
            toast.error("Please enter feedback", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            return;
        } else {
          setDisableSubmit(true);
            const toastid = toast.loading("Please wait...", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            try {
                const feedbackResult = await sendFeedback(props.response_id, feedback);
                console.log(feedbackResult)
                if (feedbackResult.comments && feedbackResult.comments.length > 0) {
                  props.setResponse({
                      ...props.response,
                      comments: feedbackResult.comments,
                  });
              }
                setFeedback("");
                setShowAnswerFeedback(!showAnswerFeedback);
                toast.update(toastid, {
                    render: "Feedback submitted successfully!",
                    type: "success",
                    isLoading: false,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                  });
                setDisableSubmit(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setDisableSubmit(false);
            }
        }
        
    }

    useEffect(() => {
        // console.log("props.currentRanking-", props.currentRanking);
        if(props.currentRanking && props.currentRanking.text){
            setFeedback(props.currentRanking.text);
        }
    }, [props.currentRanking]);
    return (
        <div>
            {seccess && (
                <div className="answer-feedback-text">
                    <p>Thanks for providing the feedback!</p>
                </div>
            )}
            {!showAnswerFeedback &&
            <button onClick={handleAnswerFeedback} className="btn btn-primary answer-feedback-btn">Answer Feedback</button>
            }
            {showAnswerFeedback && 
            <div className="answer-feedback-wrap">
                <Form>
                    <Form.Group controlId="answerFeedback.ControlTextarea1">
                        <Form.Control as="textarea" autoFocus rows={3} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                    </Form.Group>
                </Form>
                <div className="feedback-btn-wrap">
                    <a onClick={(e)=>{
                      e.preventDefault(); 
                      handleAnswerFeedback() }} href="#" className="answer-feedback-btn-cancel">Cancel</a>
                    <button onClick={handleAnswerFeedbackSubmit} className={`btn btn-primary answer-feedback-btn-submit ${disableSubmit ? 'disabled':''}`}>Submit</button>
                </div>
            </div>
            }
            
            <ToastContainer />
        </div>
    );
}

export default AnswerFeedback;