// MainAppWrap.js
import React, { useState, useEffect } from 'react';
import SideBarNav from '../components/SideBarNav';

const MainAppWrap = ({ children }) => {
  const [isSidebarActive, setIsSidebarActive] = useState(() => {
    const storedState = localStorage.getItem('sidebarState');
    return storedState ? JSON.parse(storedState).isSidebarActive : true;
  });

  // Save state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('sidebarState', JSON.stringify({ isSidebarActive }));
  }, [isSidebarActive]);

  return (
    <div className={`main-app-wrap gray_bg ${isSidebarActive ? 'sidebar-active' : ''}`}>
        <SideBarNav 
            isSidebarActive={isSidebarActive}
            setIsSidebarActive={setIsSidebarActive}
        />
      {React.Children.map(children, (child) => {
        // Pass additional props to each child component if needed
        return React.cloneElement(child);
      })}
    </div>
  );
};

export default MainAppWrap;
