import React from "react";

function Loader() {
  return(
    <div className="chat-loader">
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
    </div>
  );
}

export default Loader;