import React, {useEffect, useState} from "react";
import LikeIcon from "../../images/v2/responseList/ThumbsUp.svg";
import DislikeIcon from "../../images/v2/responseList/ThumbsDown.svg";
import CopyIcon from "../../images/v2/responseList/copy.svg";
import likedIcon from "../../images/v2/responseList/ThumbsUpFilled.svg";
import dislikedIcon from "../../images/v2/responseList/ThumbsDownFilled.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../config";
import { Link } from "react-router-dom";

function FeedbackAndCopy(props) {
    const { getIdTokenClaims } = useAuth0();
    const [copySuccess, setCopySuccess] = useState(false);

    const postCopyCount = async (res_id) => {
      try {
        console.log('res_id-', res_id);
        const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
        //   console.log('idToken-', idToken);
      
          const options = {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              response_id:res_id
            })
          };
  
        const copyPath = `/response_copy`;
        const url = `${apiPath()}${copyPath}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const copyResult = await response.json();
        console.log(copyResult);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };

    const handleCopy = async (e, id) => {
      e.preventDefault();
      
      const divElement = document.getElementById(id);
      
      if (!divElement) {
          console.error(`Could not find element with ID '${id}'`);
          return;
      }
  
      // Create the plain text version with numbered lists
      const clonedDiv = divElement.cloneNode(true);
      const orderedLists = clonedDiv.querySelectorAll('ol');
  
      orderedLists.forEach(ol => {
          const items = ol.children;
          let counter = 1;
  
          Array.from(items).forEach(item => {
              if (item.tagName === 'LI') {
                  const newItem = document.createElement('div');
                  newItem.textContent = `${counter}. ${item.textContent}`;
                  item.firstChild.replaceWith(newItem);
                  counter++;
  
                  // Preserve any child UL within the LI
                  if (item.querySelector('ul')) {
                      newItem.appendChild(item.querySelector('ul').cloneNode(true));
                  }
              }
          });
      });
  
      const plainText = clonedDiv.textContent || clonedDiv.innerText;
      const htmlText = divElement.outerHTML;
  
      try {
          await navigator.clipboard.write([
              new ClipboardItem({
                  'text/plain': new Blob([plainText], { type: 'text/plain' }),
                  'text/html': new Blob([htmlText], { type: 'text/html' })
              })
          ]);
  
          // Notify the user that the content has been copied
          setCopySuccess(true);
          postCopyCount(id);
  
          setTimeout(() => {
              setCopySuccess(false);
          }, 3000);
  
      } catch (error) {
          console.error('Failed to copy text:', error);
      }
  };
  

      useEffect(() => {
        if(props.feedback)
        {
            if(props.feedback.is_positive)
            {
                document.getElementById(`like_response_id_${props.response_id}`).src = likedIcon;
                document.getElementById(`dislike_response_id_${props.response_id}`).src = DislikeIcon;
            }
            if(!props.feedback.is_positive)
            {
                document.getElementById(`dislike_response_id_${props.response_id}`).src = dislikedIcon;
                document.getElementById(`like_response_id_${props.response_id}`).src = LikeIcon;
            }
        }
      }, [props.feedback]);

    return (
        <div className="feedback-copy">
            <a href="#" className="chat-copy-response" onClick={(e) => handleCopy(e, props.response_id)}>
                <img src={CopyIcon} alt="Copy" />
                {copySuccess && <div className="copy-text-success">Copied!</div>}
            </a>
            <a href="/" className="chat-feedback" onClick={(e) => {
                e.preventDefault(); // Prevent default anchor link behavior
                props.sendFeedback(props.response_id, true); // Send feedback with the response ID
            }}>
                <img id={`like_response_id_${props.response_id}`} src={props.currentRanking?.ratings?.thumbs_up === 1 ? likedIcon : LikeIcon } alt="Like" />
            </a>
            <a href="#" className="chat-feedback" onClick={(e) => {
                e.preventDefault(); // Prevent default anchor link behavior
                props.sendFeedback(props.response_id, false); // Send feedback with the response ID
            }}>
                <img id={`dislike_response_id_${props.response_id}`}  src={props.currentRanking?.ratings?.thumbs_down === 1 ? dislikedIcon : DislikeIcon} alt="Dislike" />
            </a>
            {props.detailLink && 
            <Link to={`/response/${props.response_id}`} target="_blank" className="chat-feedback-detail-btn">Response Details</Link>
            }
        </div>
    );
}

export default FeedbackAndCopy;