import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SimpleHeader from "../components/SimpleHeader";
import MainAppWrap from "../layout/MainAppWrap";
import Select from "react-select";
import SalesViewData from "../components/salesGuide/SalesViewData";
import { useWebSocket } from "../components/common/WebsocketContext";
import LoaderWithText from "../components/common/LoaderWithText";
import { is } from "date-fns/locale";
import { toast, ToastContainer } from "react-toastify";

function SalesGuide() {
    const { sendMessage, addListener, removeListener, webSocketStatus } = useWebSocket();
    const [productName, setProductName] = useState("Wi-Fi chip");
    const [application, setApplication] = useState("battery-operated smart home thermostat");
    const [competitor, setCompetitor] = useState({ value: "NXP", label: "NXP" });
    const [salesGuide, setSalesGuide] = useState({});
    const [isStreamingLoading, setIsStreamingLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);



    const handleCompetitorChange = (selectedOption) => {
        setCompetitor(selectedOption);
      };

      const toastErrorHandlder = (errorMessage) => {
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };


    const handleSubmit = () => {

        if (!productName || !application) {
            toastErrorHandlder("Please enter product name and application");
            return;
        }
        if (!competitor || competitor.value === "selectComp") {
            toastErrorHandlder("Please select a competitor");
            return;
        }
      setMessageSent(true);
        let message = {
            "action": "dynamic_report",
            "report_type": "sales_guide",
            "command": "write",
            "identifier": "1234",
            "competitor": competitor.value,
              "product_type": productName,
              "application": application,
        }
        console.log("Product Name: ", productName);
        console.log("Application: ", application);
        console.log("Competitor: ", competitor);
        if (webSocketStatus.current === 'open') {
            sendMessage(JSON.stringify(message));
        } else {
            setTimeout(() => {
                handleSubmit();
            }, 5000);
        }
    }

        useEffect(() => {
            const handleMessage = (message) => {
                let data = JSON.parse(message);
                
                if(data.action === 'sales_guide' && data.identifier === '1234') {
                    setIsStreamingLoading(true);
                    
                    setSalesGuide((prevData) => {
                      // Helper function to add or update performance criteria for a specific product
                      const updateOrAddPerformanceData = (products = [], targetProductName, newCriterion, newPerformanceData) => {
                          if (!products || products.length === 0) {
                              // Initialize the list if empty
                              return [
                                  {
                                      name: targetProductName,
                                      performance_criteria: [{ criterion: newCriterion, performance_data: newPerformanceData }]
                                  }
                              ];
                          }
                  
                          return products.map((product) => {
                              if (product.name === targetProductName) {
                                  // Check if the performance criterion already exists for the product
                                  const existingCriterionIndex = product.performance_criteria?.findIndex(
                                      (criterion) => criterion.criterion === newCriterion
                                  );
                  
                                  if (existingCriterionIndex !== -1 && product.performance_criteria) {
                                      // Update existing criterion
                                      const updatedPerformanceCriteria = [...product.performance_criteria];
                                      updatedPerformanceCriteria[existingCriterionIndex] = {
                                          ...updatedPerformanceCriteria[existingCriterionIndex],
                                          performance_data:
                                              (updatedPerformanceCriteria[existingCriterionIndex].performance_data || '') +
                                              newPerformanceData
                                      };
                                      return { ...product, performance_criteria: updatedPerformanceCriteria };
                                  } else {
                                      // Add new criterion
                                      const newPerformanceCriteria = [
                                          ...(product.performance_criteria || []),
                                          { criterion: newCriterion, performance_data: newPerformanceData }
                                      ];
                                      return { ...product, performance_criteria: newPerformanceCriteria };
                                  }
                              }
                              return product;
                          });
                      };
                  
                      const newCompanyProducts =
                          data.company_name !== competitor.value && data.product
                              ? updateOrAddPerformanceData(
                                    prevData.company_products || [],
                                    data.product,
                                    data.criteria,
                                    data.performance_criteria
                                )
                              : prevData.company_products;
                  
                      const newCompetitorProducts =
                          data.company_name === competitor.value && data.product
                              ? updateOrAddPerformanceData(
                                    prevData.competitor_products || [],
                                    data.product,
                                    data.criteria,
                                    data.performance_criteria
                                )
                              : prevData.competitor_products;
                  
                      return {
                        ...prevData,

                        // Update company products
                        company_products: data.company_products && data.company_name !== competitor.value
                          ? [
                              ...(prevData.company_products || []),
                              ...data.company_products.map((product) => ({
                                name: product,
                                performance_criteria: [],
                              })),
                            ]
                          : newCompanyProducts,

                        // Update competitor products
                        competitor_products: data.company_products && data.company_name === competitor.value
                          ? [
                              ...(prevData.competitor_products || []),
                              ...data.company_products.map((product) => ({
                                name: product,
                                performance_criteria: [],
                              })),
                            ]
                          : newCompetitorProducts,

                        // Performance criteria array for standalone criteria if applicable
                        performance_criteria:
                          data.performance_criteria &&
                          data.product === undefined
                            ? prevData.performance_criteria
                              ? [
                                  ...prevData.performance_criteria,
                                  ...data.performance_criteria,
                                ]
                              : data.performance_criteria
                            : prevData.performance_criteria,

                        // Append execution summary
                        exec_summary:
                          (prevData.exec_summary || "") +
                          (data.exec_summary || ""),
                      };
                  });
                                    
                    
                    
                    }
                    
                
            };
            addListener(handleMessage);
    
            return () => {
                removeListener(handleMessage);
            };
        }, [addListener, removeListener]);
    
  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader title="Sales Guide" />
        <Container fluid="xxl" className="sales-guide-page">
        {!messageSent && (
          <Row >
          <Col>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="channel-name">
                        Product Type:
                        <input
                          type="text"
                          className="form-control"
                          id="product-type"
                          name="product-type"
                          placeholder=""
                          value={productName}
                          onChange={(e) => setProductName(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="channel-name">
                        Application:
                        <input
                          type="text"
                          className="form-control"
                          id="application"
                          name="application"
                          placeholder=""
                          value={application}
                          onChange={(e) => setApplication(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label>
                        {" "}
                        Select Competitor:
                        <Select
                          id="competitor"
                          onChange={handleCompetitorChange}
                          value={competitor}
                          className="form-select-new"
                          options={[
                            { value: "selectComp", label: "Select Competitor" },
                            { value: "NXP", label: "NXP" },
                          ]}
                          isSearchable
                        />
                      </label>
                    </div>
                  </div>
                  

                  <div className="ks-footer sales-guide-footer">
                    <a
                      href="#"
                      id="ks-submit-btn"
                      className="request_btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Generate
                    </a>
                  </div>

                </div>
              </Col>
          </Row>
            )}
          <Row>
            <Col>

            {messageSent && isStreamingLoading === false && (
                <LoaderWithText
                    text="Processing your request..."
                />
            )}
            {/* {isStreamingLoading && (
                <LoaderWithText
                    text="Streaming sales guide data..."
                />
            )} */}
            </Col>
          </Row>
          <Row>
          {isStreamingLoading && (
            <SalesViewData 
                salesGuide={salesGuide}
                competitor={competitor}
             />
            )}
          </Row>
        </Container>

      </div>
      <ToastContainer />
    </MainAppWrap>
  );
}

export default SalesGuide;
