import React, { useState, useEffect, useRef, useCallback } from "react";
import Footer from "../components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import SimpleHeader from "../components/SimpleHeader";
import MainAppWrap from "./MainAppWrap";
import { useForumList } from '../components/common/ForumListContext';

function MainLayout(props) {
  const { isLoading, user } = useAuth0();
  const { forumIds, selectedForumId, setSelectedForumId, selectForumName } = useForumList();



  console.log("selectedForumId: " + selectedForumId);
  // get forum list
  // const [forumIds, setForumIds] = useState([]);
  // const [selectedForumId, setSelectedForumId] = useState('');
  const cancelToken = useRef(null);
  const handleForumChange = (event) => {
      setSelectedForumId(event.target.value);
      // console.log("raw id: " + event.target.value);
      // Handle thread list update or any other logic here
  };



  useEffect(() => {
    if (!isLoading && user ) {

      // console.log('user:', user);
      const role = user?.ept_roles || '';
      const customAccounts = user?.ept_app_metadata || '';
  
      window.pendo.initialize({
        visitor: {
          id: user?.sub || '',
          email: user?.email || '',
          full_name: user?.name || '',
          role: role,
          customAccounts: customAccounts
        },
        account: {
          id: user?.sub || '',
          name: user?.name || ''
        }
      });
    }
  }, [isLoading, user]);
  
  useEffect(() => {
    // console.log('forumIds:', forumIds);
    // console.log('selectedForumId:', selectedForumId);
  }, [forumIds, selectedForumId]);

  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader
        />
        {/* <Sidebar /> */}
        {React.Children.map(props.children, (child) => {
  // Pass additional props to each child component
  return React.cloneElement(child, {
    selectedForumId: selectedForumId,
    selectForumName: selectForumName,
    forumIds: forumIds,
    handleForumChange: handleForumChange,
  });
})}
        <Footer />
      </div>
      
    </MainAppWrap>
  );
}

export default MainLayout;
