import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// import { useAuth } from './components/auth/AuthContext';
import MainContent from './pages/MainContent';
// import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import MFASetupScreen from './pages/MfaSetup';
import NewPassword from './pages/NewPassword';
import Pricing from './pages/Pricing';
import Settings from './pages/Settings';
import FreeTrial from './pages/FreeTrial';
import AskQuestionEptChat from './pages/AskQuestionEptChat';
import AddKs from './components/settings/partials/AddKs';
import UpdateKs from './components/settings/partials/UpdateKs';
import AddKsc from './components/settings/partials/AddKsc';
import UpdateKsc from './components/settings/partials/UpdateKsc';
import AddChannel from './components/settings/partials/AddChannel';
import ChannelDetail from './components/settings/ChannelDetail';
import Threads from './components/dashboard/Threads';
import KsDetails from './components/settings/KsDetails';
import KscDetails from './components/settings/KscDetails';
import ResponseList from './pages/ResponseList';
import ResponseDetails from './components/responseList/ResponseDetails';
import GostPages from './pages/GostPages';
import Page404 from './pages/Page404';
import DownloadFile from './components/settings/partials/DownloadFile';
import Reporting from './pages/Reporting';
import GkdEditor from './components/settings/partials/GkdEditor';
import BrainDump from './components/settings/partials/BrainDump';
import SalesGuide from './pages/SalesGuide';



function Router(props) {
  // const { user } = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/signin" element={<SignIn />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/mfa-setup" element={<MFASetupScreen />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/settings" element={<Navigate replace to="/settings/account-management" />} />
        <Route path="/settings/:tab" element={<Settings />} />
        <Route path="/settings/ks/create" element={<AddKs />} />
        <Route path="/settings/ks/update/:ks_id" element={<UpdateKs />} />
        <Route path="/settings/ks/item/download/" element={<DownloadFile />} />
        <Route path='/settings/ks/:ks_id/:filename' element={<GkdEditor />} />
        <Route path='/settings/ks/:ks_id/new-gkd/' element={<BrainDump />} />
        <Route path="/settings/ksc/create" element={<AddKsc />} />
        <Route path="/settings/ksc/update/:ksc_id" element={<UpdateKsc />} />
        <Route path='/settings/ks-details/:ks_id' element={<KsDetails />} />
        <Route path='/settings/ksc-details/:ksc_id' element={<KscDetails />} />
        <Route path="/settings/channel/create" element={<AddChannel />} />
        <Route path="/settings/channel/:channel_id" element={<ChannelDetail />} />
        <Route path="/responses" element={<ResponseList />} />
        <Route path="/response/:response_id" element={<ResponseDetails />} />
        <Route path="/free-trial" element={<FreeTrial />} />
        <Route path="/reporting" element={<Reporting />} />
        {/* <Route path="/community-forum" element={<MainContent />} /> */}
        <Route path="/community-forum/:forumid/page/:pagenumber" element={<MainContent />} />
        <Route path="/thread_id/:threadid" element={<Threads />} />

        <Route path="/question-type" element={<GostPages />} />
        <Route path="/ai-voice" element={<GostPages />} />
        {/* <Route path="/reporting" element={<GostPages />} /> */}

        <Route path='/sales-guide' element={<SalesGuide />} />

        <Route index element={<AskQuestionEptChat />} />
        <Route path="/" element={<AskQuestionEptChat />} />
        {/* <Route path="/ask-question-chat" element={<AskQuestionEptChat />} /> */}
        <Route path="*" element={<Page404 />} />
        <Route path="404" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
