import React, { useState } from "react";

function ResponseListFilters({ columnVisibility, setColumnVisibility, options, handleResetFilters }) {
    const [showModal, setShowModal] = useState(false);
    const [tempVisibility, setTempVisibility] = useState([...columnVisibility]);

    const toggleModal = () => setShowModal(!showModal);

    const handleCheckboxChange = (index) => {
        setTempVisibility((prev) => {
            const updatedVisibility = [...prev];
            updatedVisibility[index] = !updatedVisibility[index];
            return updatedVisibility;
        });
    };

    const handleApply = () => {
        setColumnVisibility([...tempVisibility]); // Apply the settings
        toggleModal();
    };

    const handleCancel = () => {
        setTempVisibility([...columnVisibility]); // Reset temporary changes
        toggleModal();
    };

    return (
        <div className="grid-settings-wrap">
            <button onClick={handleResetFilters} className="grid-reset-filters">Reset</button>
            <button onClick={toggleModal} className="grid-column-settings">
                
            </button>
            {showModal && (
                <div className="grid-settings-modal">
                    <h3>Column Preferences</h3>
                    <form>
                        {options.map((option, index) => (
                            <div key={option.id} className="custom-checkbox-for-settings">
                                <span>{option.name}</span>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={tempVisibility[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                    <span className="slider"></span>
                                </label>
                            </div>
                        ))}
                    </form>
                    <div className="modal-buttons">
                        <button onClick={handleCancel} className="grid-settings-cancel">
                            Cancel
                        </button>
                        <button onClick={handleApply} className="grid-settings-save">
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ResponseListFilters;
