import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import AnswerFeedback from "./AnswerFeedback";
import { timeAgo } from "../../common/Functions";
import "./css/ResponseComments.css";


function ResponseComments({ comments, response_id, currentRanking, setResponse, response }) {

    useEffect(() => {
        console.log(comments);
    }, [comments]);

    return (
        <div className="response-comments">
            <Row>
                <Col>
                    <div className="response-comments-header">
                        <h3>Feedback List</h3>
                    </div>
                        <div className="response-comments-list">
                        {comments
                            ?.slice() // Create a shallow copy to avoid mutating the original array
                            .reverse() // Reverse the order of comments
                            .map((comment, index) => (
                                <div key={index} className="response-comment">
                                <div className="comment-user-avatar">
                                    <img src={comment.picture} alt={comment.name} />
                                </div>
                                <div className="comment-user-info-and-comment">
                                    <h3>{comment.name}</h3>
                                    <div className="comment-user-id">{comment.user_id}</div>
                                    <p>{comment.comment}</p>
                                    <div className="comment-date">{timeAgo(comment.date)}</div>
                                </div>
                                </div>
                            ))}

                        </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AnswerFeedback 
                        response_id={response_id} 
                        currentRanking={currentRanking}
                        setResponse={setResponse}
                        response={response}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ResponseComments;