import React, {useState, useRef} from "react";
import {useAuth0} from "@auth0/auth0-react";
import { ToastContainer, toast } from 'react-toastify';
import { apiPath } from "../../config";

function InviteUser(props){
    const { getIdTokenClaims } = useAuth0();
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [message, setMessage] = useState("");
    const [notify, setNotify] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);

    const handleCheckboxChange = (event) => {
        setNotify(event.target.checked);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setIsInvalidEmail(false); // Reset invalid email state when input changes
      };
    
      const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
          event.preventDefault();
          addEmail();
        }
      };
    
      const handleInputBlur = () => {
        addEmail();
      };
    
      const handleRemoveEmail = (email) => {
        setEmails(emails.filter((e) => e !== email));
      };
    
      const isEmailValid = (email) => {
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
      };
    
      const addEmail = () => {
        const newEmail = inputValue.trim();
        if (newEmail && isEmailValid(newEmail) && !emails.includes(newEmail)) {
          setEmails([...emails, newEmail]);
        } else {
          setIsInvalidEmail(true); // Set invalid email state
        }
        setInputValue('');
      };
    
      const inputClassName = isInvalidEmail ? 'invalid' : '';

    const showInviteBox = (e) => {
        e.preventDefault();
        document.querySelector(".invite-user-box").classList.add("show");
    };

    const hideInviteBox = (e) => {
        e.preventDefault();
        document.querySelector(".invite-user-box").classList.remove("show");    
    }
    
    const postUsers = async (e) => {
      e.preventDefault();
      if (e.target.classList.contains("disabled")) {
          return null;
      }
      e.target.classList.add("disabled");
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT
      })
        try {
           // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const userData = {
              notify_user: notify,
              invitation_message: message,
              users: emails.map((email) => ({
                email,
                groups: ['User'],
              })),
            };
            const userDataJson = JSON.stringify(userData);
            const options = {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
              },
              body: userDataJson,
            };
            const path = `/user`;
            const url = `${apiPath()}${path}`;
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            console.log(responseData[0].message);
            if(responseData[0].message === "success"){
              document.querySelector(".invite-user-box").classList.remove("show");  
              toast.update(toastid, { 
                render: "User invited successfully!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
              });
            }else if(responseData[0].message === "User already exists"){
              toast.update(toastid, {
                render: "User already exists!",
                type: "error",
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
              });
            }else {
              toast.update(toastid, {
                render: "Something went wrong! Please try again.",
                type: "error",
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
              });
            }
          
            setEmails([]);
            setMessage("");
            setNotify(false);
            props.setNewUser(true);
            e.target.classList.remove("disabled");
          
          // Handle the response as needed
        } catch (error) {
          console.error('Error posting data:', error);
          e.target.classList.remove("disabled");
          toast.update(toastid, {
            render: "Something went wrong! Please try again.",
            type: "error",
            isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000
          });
        }
      };
    return (
        <div className="invite-user-table">
            <a href="#" className="request_btn" onClick={(e)=>showInviteBox(e)}>Invite Users</a>
            <div className="invite-user-box">
               <div className="invite-user-box-content">
                <div className="invite-user-box-header">
                    <a href="#" className="close-invite-user-box" onClick={(e)=>hideInviteBox(e)}></a>
                    <h3>Invite your team member's/ friends</h3>
                </div>
                <div className="multiple-email-input">
                    {emails.map((email, index) => (
                    <div key={index} className="email-tag">
                        {email}
                        <button
                        className="remove-button"
                        onClick={() => handleRemoveEmail(email)}
                        >
                        ×
                        </button>
                    </div>
                    ))}
                    <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onBlur={handleInputBlur}
                    className={inputClassName}
                    />
                </div>
                <div className="notifycheckbox">
                <label className="notifycheckbox-label">
                    <input
                        type="checkbox"
                        className="notifycheckbox-input"
                        checked={notify}
                        onChange={handleCheckboxChange}
                    />
                    <span className="notifycheckbox-control"></span>
                    <span className="notifycheckbox-label-text">Notify people</span>
                </label>
                </div>
                <div className="message-textarea">
                    <textarea placeholder="Message" className="message-textarea-input form-control" onChange={(e)=> {
                        setMessage(e.target.value);
                    }}></textarea>
                </div>
                <div className="invite-user-box-footer">
                    <a href="#" className="invite-user-box-btn cancel" onClick={(e)=>hideInviteBox(e)}>Cancel</a>
                    <a href="#" className="invite-user-box-btn done" onClick={(e)=>postUsers(e)}>Done</a>
                </div>
               </div>
            </div>
            <ToastContainer />
        </div>
  );
}


export default InviteUser;