import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import logo from '../images/logo-black.svg';

function NewPassword() {
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const user = location.state.user;
    console.log(user);
  
    const handleNewPasswordSubmit = async (e) => {
        e.preventDefault();
    
        try {
          if (user) {
            await Auth.completeNewPassword(user, newPassword);
            console.log('New password set successfully.');
            // Redirect to the dashboard or desired page after setting the new password
          } else {
            console.log('Invalid user object.');
          }
        } catch (error) {
          console.error('Failed to set the new password:', error);
          // Handle the error accordingly
        }
      };
  
    return (
      <div>
        <div className="container">
            <div className="row">
                <form className='sign_form newpass_form' onSubmit={handleNewPasswordSubmit}>
                <div className='col-xs-12 text-center'>
                    <img className='form-logo' src={logo} alt="logo" />
                </div>
                <div className='col-xs-12'>
                    <label htmlFor="newPasswordInput">Set New Password:</label>
                    <input
                    id="newPasswordInput"
                    className='form-control'
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    />
                </div>
                <div className='col-xs-12'>
                    <button className='btn btn-primary' type="submit">Set Password</button>
                </div>
                </form>
            </div>
        </div>
      </div>
    );
  }
  
  export default NewPassword;
  