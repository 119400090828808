import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';

function useAccessToken(eptClientId) {
    const { user, isLoading } = useAuth0();
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        // Function to check token validity
        const isTokenValid = (token) => {
            if (!token) {
                return false; // No token means it's not valid
            }
            try {
                const decoded = jwtDecode(token); // Decoding the JWT token to check expiry
                const currentTime = Date.now() / 1000; // Convert to seconds
                return decoded.exp > currentTime; // Check if the token is still valid
            } catch (error) {
                return false; // An error decoding the token means it's invalid
            }
        };

        if (isLoading) {
            // If Auth0 is still loading, don't do anything
            return;
        }

        // Load token from localStorage
        const storedToken = localStorage.getItem('accessToken');
        const storedUser = localStorage.getItem('auth0User');
        const currentUser = user.sub; // Ensure this is the correct property for the user ID

        if (storedToken && isTokenValid(storedToken) && storedUser === currentUser) {
            // If there's a valid token in localStorage and the user hasn't changed, use it
            setAccessToken(storedToken);
        } else {
            // Token is missing, not valid, or user has changed, fetch a new one
            const chat_api_url = 'https://30tnqqjlr1.execute-api.us-east-1.amazonaws.com/prod'; // Change this to your actual API URL
            const chat_api_endpoint = '/api/v1/access_token';
            const chat_api_request_url = `${chat_api_url}${chat_api_endpoint}?client_id=${eptClientId}`;

            fetch(chat_api_request_url)
                .then(response => response.json())
                .then(data => {
                    if (data.access_token && isTokenValid(data.access_token)) {
                        // Save the new token and user info to localStorage
                        localStorage.setItem('accessToken', data.access_token);
                        localStorage.setItem('auth0User', currentUser); // Set this to the current user's ID
                        setAccessToken(data.access_token);
                    } else {
                        // Handle the absence of a valid access token
                        console.error('Valid access token not received. Got response:', data);
                    }
                })
                .catch(error => {
                    console.error('Error getting access token:', error);
                });
        }
    }, [eptClientId, user, isLoading]); // Dependency array, ensures effect only runs if these values change

    return accessToken;
}

export default useAccessToken;
