export const convertDateIntoLocalDate = (utcDate) => {

    // remove +00:00 at the end of utcDate if exist and replace with Z
    
    if (utcDate === undefined || utcDate === null || utcDate === '') {
        return '';
    }else {
        if (utcDate.includes('+00:00')) {
            utcDate = utcDate.replace('+00:00', '');
        }
    }
    // Create a new Date object using the provided server date in UTC
    const newDate = new Date(utcDate + 'Z'); // Adding 'Z' ensures the date is treated as UTC

    // Define the options for date and time formatting
    const dateOptions = {
        year: '2-digit',    // Two-digit year
        month: '2-digit',   // Two-digit month
        day: '2-digit'      // Two-digit day
    };
    const timeOptions = {
        hour: '2-digit',    // Two-digit hour
        minute: '2-digit',  // Two-digit minute
        second: '2-digit',  // Two-digit second
        hour12: true        // Use 12-hour time format with AM/PM
    };

    // Format the date and time with the specified options
    const localDateString = newDate.toLocaleDateString(undefined, dateOptions);  // Use user's locale for date
    const localTimeString = newDate.toLocaleTimeString(undefined, timeOptions);  // Use user's locale for time

    // Convert AM/PM part to uppercase
    const finalTimeString = localTimeString.replace(/am|pm/i, (match) => match.toUpperCase());

    return localDateString + " " + finalTimeString;
};

export const clearLocalStorageItems = () => {
    const keys = ['eptChannels', 'forumIds', 'selectedForumId', 'selectForumName'];
    keys.forEach(key => {
        localStorage.removeItem(key);
    });
};

export const convertLocalToUTCTimestamp = (localDate) => {
    // Create a new Date object from the input date string
    const date = new Date(localDate);

    // Convert the date to UTC by subtracting the timezone offset
    const utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    // Return the Unix timestamp in seconds
    return utcDate;
};

export const convertTimeStampToUnix = (timestamp) => {
    // console.log('Timestamp:', timestamp);
    const time = convertLocalToUTCTimestamp(timestamp);
    // console.log('Time:', time);
    return Math.floor(new Date(time).getTime() / 1000);
}


export function timeAgo(serverDate) {
    const now = new Date();
    const localDate = convertLocalToUTCTimestamp(serverDate)
    const pastDate = new Date(localDate);

    // Get the difference in seconds
    const diffInSeconds = Math.floor((now - pastDate) / 1000);

    // Define time intervals
    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInWeek = 7 * secondsInDay;
    const secondsInMonth = 30 * secondsInDay; // Approximation
    const secondsInYear = 365 * secondsInDay; // Approximation

    // Convert to appropriate time ago format
    if (diffInSeconds < secondsInMinute) {
        return `${diffInSeconds}s ago`;
    } else if (diffInSeconds < secondsInHour) {
        const minutes = Math.floor(diffInSeconds / secondsInMinute);
        return `${minutes}m ago`;
    } else if (diffInSeconds < secondsInDay) {
        const hours = Math.floor(diffInSeconds / secondsInHour);
        return `${hours}h ago`;
    } else if (diffInSeconds < secondsInWeek) {
        const days = Math.floor(diffInSeconds / secondsInDay);
        return `${days}d ago`;
    } else if (diffInSeconds < secondsInMonth) {
        const weeks = Math.floor(diffInSeconds / secondsInWeek);
        return `${weeks}w ago`;
    } else if (diffInSeconds < secondsInYear) {
        const months = Math.floor(diffInSeconds / secondsInMonth);
        return `${months}m ago`;
    } else {
        const years = Math.floor(diffInSeconds / secondsInYear);
        return `${years}y ago`;
    }
}