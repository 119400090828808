import React, { useState, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

function FiltersV2(props) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleFilterChange = (value) => {
    props.setCurrentPage(1);
    if (value === "all_filter") {
      props.setAllthreads(true);
      props.setUnsolved(false);
      props.setNoresponse(false);
    } else if (value === "unsolved_filter") {
      props.setAllthreads(false);
      props.setUnsolved(false);
      props.setNoresponse(false);
    } else if (value === "noresponsefilter") {
      props.setAllthreads(false);
      props.setNoresponse(true);
      props.setUnsolved(false);
    }
    props.setSelectedFilter(value);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    if (props.threadsResultMessage) {
      console.log(props.threadsResultMessage);
    }
  }, [props.threadsResultMessage]);


  return (
    <div>
      <div className="new-filters">
        <div className="result-message">{props.threadsResultMessage}</div>
        <div className="filtersV2-wrap">
          <ToggleButtonGroup type="checkbox" value={props.selectedFilter}>
            <ToggleButton
              id="all_filter"
              type="checkbox"
              variant="secondary"
              value="all_filter"
              onChange={() => handleFilterChange("all_filter")}
            >
              All
            </ToggleButton>
            <ToggleButton
              id="unsolved_filter"
              type="checkbox"
              variant="secondary"
              value="unsolved_filter"
              onChange={() => handleFilterChange("unsolved_filter")}
            >
              Unsolved
            </ToggleButton>
            <ToggleButton
              id="noresponsefilter"
              type="checkbox"
              variant="secondary"
              value="noresponsefilter"
              onChange={() => handleFilterChange("noresponsefilter")}
            >
              Unresponded
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="filters-right-btns">
            {/* <Link to="/ask-question" className="ask-question-btn">Ask your question</Link> */}
            <div className="filters-open-btn" onClick={togglePopup}>
              <span>Filters</span>
            </div>
          </div>
        </div>
        <div className={isPopupVisible ? "filtersV2-multiselect-popup popup-visible" : "filtersV2-multiselect-popup popup-hidden"}>
          <ul>
            <li>
              <label>Sort by date:</label>
              <Select
                id="sort"
                onChange={props.handleSortChange}
                value={props.selectedSort}
                options={[
                  { value: "last_post_date", label: "Last Post Date" },
                  { value: "created_date", label: "Created Date" },
                ]}
                isSearchable
                placeholder="Select Sort"
              />
            </li>
            <li>
              <label>Select Tags:</label>
              <Select
                id="tags"
                onChange={props.handleTagChange}
                value={props.selectedTag}
                options={props.tags.map((tag, index) => ({
                  value: tag,
                  label: tag,
                }))}
                isMulti
                isSearchable
                placeholder="Search Tags"
              />
            </li>
            <li>
              <label>Select Boards:</label>
              <Select
                id="boards"
                onChange={props.handleBoardChange}
                value={props.selectedBoard}
                options={props.boards.map((board, index) => ({
                  value: board,
                  label: board,
                }))}
                isMulti
                isSearchable
                placeholder="Search Board"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FiltersV2;
