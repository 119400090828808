import React, { createContext, useEffect, useRef, useContext, useCallback, useState } from 'react';
import { websocketPath } from '../../config';
import { useAuth0 } from '@auth0/auth0-react';

// Create WebSocket Context
const WebSocketContext = createContext(null);

// Create a custom hook to use WebSocket Context
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

// Create a Provider Component
export const WebSocketProvider = ({ children }) => {
  const { user, getIdTokenClaims } = useAuth0();
  const socket = useRef(null);
  const listeners = useRef(new Set());
  const pingInterval = useRef(null);
  const reconnectTimeout = useRef(null);
  const webSocketStatus = useRef(null);
  const [error, setError] = useState(null); // Error state for handling connection issues

  // Function to add a listener callback for new messages
  const addListener = useCallback((callback) => {
    listeners.current.add(callback);
  }, []);

  // Function to remove a listener callback
  const removeListener = useCallback((callback) => {
    listeners.current.delete(callback);
  }, []);

  const connectWebSocket = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const idToken = tokenClaims.__raw;

      if (!idToken) {
        console.error("ID token is not available.");
        return;
      }
      const getWebsocketURL = websocketPath();
      const websocket_url = `${getWebsocketURL}?access_token=${idToken}&user_access_token=${idToken}&client_key=ODY8mXQS82`;

      // Initialize WebSocket connection
      socket.current = new WebSocket(websocket_url);

      // Setup event listeners for WebSocket
      socket.current.onopen = () => {
        console.log("WebSocket connected");
        
        webSocketStatus.current = 'open';
        startPing(); // Start the keep-alive ping
        setError(null); // Clear any previous errors
      };

      socket.current.onmessage = (event) => {
        // Call all the listeners with the new message
        listeners.current.forEach((listener) => listener(event.data));
      };

      socket.current.onclose = () => {
        console.warn("WebSocket closed. Reconnecting...");
        webSocketStatus.current = 'closed';
        stopPing(); // Stop keep-alive ping
        attemptReconnect(); // Attempt to reconnect
      };

      socket.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        webSocketStatus.current = 'error';
        socket.current.close(); // Close socket on error to trigger reconnect
      };
    } catch (error) {
      console.error("Error connecting WebSocket:", error);
    }
  };

  // Function to send a keep-alive ping
  const startPing = () => {
    pingInterval.current = setInterval(() => {
      if (socket.current && socket.current.readyState === WebSocket.OPEN) {
        socket.current.send(JSON.stringify(
          {
            action: 'ping'
          }
        )); // Send a ping message to the server
      }
    }, 30000); // Send a ping every 30 seconds
  };

  // Function to stop the keep-alive ping
  const stopPing = () => {
    if (pingInterval.current) {
      clearInterval(pingInterval.current);
      pingInterval.current = null;
    }
  };

  // Function to attempt WebSocket reconnection
  const attemptReconnect = () => {
    reconnectTimeout.current = setTimeout(() => {
      console.log("Attempting to reconnect...");
      connectWebSocket(); // Reconnect the WebSocket
    }, 5000); // Attempt to reconnect every 5 seconds
  };

  // Function to handle tab visibility change
  const handleVisibilityChange = () => {
    if (!document.hidden) {
      // Tab becomes visible, attempt to reconnect the WebSocket
      if (webSocketStatus.current !== 'open') {
        console.log("Tab became visible, attempting to reconnect...");
        connectWebSocket();
      }
    }
  };

  // Cleanup function to stop ping and reconnect attempts
  const cleanup = () => {
    stopPing();
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    connectWebSocket();

    // Add event listener for visibility change to handle tab focus
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup WebSocket connection and event listeners on component unmount
    return () => {
      if (socket.current) {
        socket.current.close();
      }
      cleanup();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [user]);

  // Function to send a message via WebSocket
  const sendMessage = (message) => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(message);
    }
  };

  // Show error message and prompt for page refresh if WebSocket is in error state
  const showError = () => {
    if (error) {
      return (
        <div>
          <p>Error: {error}</p>
          <button onClick={() => window.location.reload()}>Refresh Page</button>
        </div>
      );
    }
  };

  return (
    <WebSocketContext.Provider value={{ sendMessage, addListener, removeListener, webSocketStatus }}>
      {children}
      {showError()}
    </WebSocketContext.Provider>
  );
};
