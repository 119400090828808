import React from "react";
import Ghost from "../components/Ghost";

function GostPages(props) {
    return (
        <div>
            {location.pathname === '/question-type' && (
            <Ghost title="Question Type - Coming soon!" description="<p>ept AI categorizes all questions into Question Types for improved analysis and categorization. Here you will be able manage Question Types.</p>" />
            )}
            {location.pathname === '/ai-voice' && (
            <Ghost title="AI Voice - Coming soon!" description="<p>AI Voice allows you to set the voice used per channel, guiding ept AI to formulate responses using that voice. <br /> <br />For instance, a sales engineering voice may focus on “what is possible with my products” whereas a technical support voice may focus on “here is how you can accurately configure my products”</p>" />
            )}
            {location.pathname === '/reporting' && (
            <Ghost title="Reporting - Coming soon!" description="<p>ept AI reporting features basic reports on overall usage, top users, channels, question types, etc. </p>" />
            )}
        </div>
    );
}

export default GostPages;