import React from 'react';
import { createRoot } from 'react-dom/client';
// import { Amplify } from 'aws-amplify';
// import config from './aws-exports';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';

// Amplify.configure(config);

const container = document.getElementById('root');
const root = createRoot(container); // Create a root with React 18

root.render(
  <React.StrictMode>
    <App />
   </React.StrictMode>
);
