import React, { createContext, useContext, useEffect, useState } from 'react';


const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({ isLoading: true, user: null });
  const [error, setError] = useState(null); // State variable for sign-in error

  useEffect(() => {
    async function checkAuthState() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAuthState({ isLoading: false, user });
      } catch (error) {
        setAuthState({ isLoading: false, user: null });
      }
    }

    checkAuthState();
  }, []);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        setAuthState({ isLoading: false, user: payload.data });
      } else if (payload.event === 'signOut') {
        setAuthState({ isLoading: false, user: null });
      }
    });

    return () => {
      Hub.remove('auth');
    };
  }, []);

  async function signIn(username, password, rememberMe, accessToken) {
    try {
      console.log('Signing in...');
      let user;

      if (accessToken) {
        user = await Auth.signInWithWebUI({ accessToken });
        console.log('Sign in with access token successful.');
      } else {
        const signInOptions = rememberMe ? { rememberDevice: 'true' } : {};
        user = await Auth.signIn(username, password, signInOptions);
        console.log('Sign in successful.');
      }

      setAuthState({ isLoading: false, user });

      // Check if MFA setup is required
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Force user to set a new password
        // const newPassword = prompt('Your password has expired. Please enter a new password:');
        // if (newPassword) {
        //   await Auth.completeNewPassword(user, newPassword); // Await the completion of the new password setup
        //   console.log('New password set successfully.');

        //   // Retrieve the updated user after setting the new password
        //   const updatedUser = await Auth.currentAuthenticatedUser();
        //   setAuthState({ isLoading: false, user: updatedUser });
        //   window.location.href = '/';
        // } else {
        //   console.log('Invalid password.');
        // }
      } else {
        // Check if optional MFA setup is needed
        // if (user.challengeName === 'MFA_SETUP' || user.preferredMFA) {
        //   const shouldSetupMFA = confirm('Do you want to set up Multi-Factor Authentication?');
        //   if (shouldSetupMFA) {
        //     window.location.href = '/mfa-setup';
        //     return; // Stop further execution of the function
        //   }
        // } else {
       
        // }

         window.location.href = '/'; // Redirect to the dashboard or desired page after successful sign-in
      }
    } catch (error) {
      setAuthState({ isLoading: false, user: null });
      console.error('Sign in error:', error);
      setError(error); // Set the sign-in error
      throw error;
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      setAuthState({ isLoading: false, user: null });
    } catch (error) {
      setAuthState({ isLoading: false, user: null });
      throw error;
    }
  }

  async function getUserGroups() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { signInUserSession } = user;
      const accessToken = signInUserSession.getAccessToken();

      // Retrieve user groups from the access token payload
      const { payload } = accessToken;
      const userGroups = payload['cognito:groups'];

      return userGroups;
    } catch (error) {
      console.error('Error retrieving user groups:', error);
      throw error;
    }
  }

  const value = {
    isLoading: authState.isLoading,
    user: authState.user,
    signIn,
    signOut,
    error, // Pass the sign-in error to the context value
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
