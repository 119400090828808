import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { clearLocalStorageItems } from './Functions';
import { apiPath } from '../../config';

const ForumListContext = createContext();

export const ForumListProvider = ({ children }) => {
  const [forumIds, setForumIds] = useState(() => {
    // Load forumIds from local storage if available
    const storedForumIds = localStorage.getItem('forumIds');
    return storedForumIds ? JSON.parse(storedForumIds) : [];
  });
  const [selectedForumId, setSelectedForumId] = useState(
    localStorage.getItem('selectedForumId') || null
  );
  const [selectForumName, setSelectForumName] = useState(
    localStorage.getItem('selectForumName') || null
  );
  const [eptChannels, setEptChannels] = useState(() => {
    // Load eptChannels from local storage if available
    const storedEptChannels = localStorage.getItem('eptChannels');
    return storedEptChannels ? JSON.parse(storedEptChannels) : [];
  });

  const {
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const [redirectHandled, setRedirectHandled] = useState(false);


  // Function to check and renew token
  const checkAndRenewToken = async () => {
    if (!isLoading && !isAuthenticated) {
      // User is not authenticated, redirect to login
      clearLocalStorageItems();
      localStorage.setItem('redirectPath', window.location.pathname);
      loginWithRedirect();
      return;
    }
    if (!isLoading) {
      // console.log("Checking and renewing token");
      try {
          const checkToken = await getIdTokenClaims();
          // console.log(checkToken);
          if (checkToken) {
            const currentTime = Date.now() / 1000; // Current time in seconds
    
            // Check if the token has expired
            if (checkToken.exp < currentTime) {
              console.log("ID Token has expired");
              // Handle the expiration scenario (e.g., log out, renew token)
              clearLocalStorageItems();
              logout({ logoutParams: { returnTo: window.location.origin } });
            } else {
              console.log("ID Token is still valid");
            }
          }
        
      } catch (error) {
        clearLocalStorageItems();
        localStorage.setItem('redirectPath', window.location.pathname);
        loginWithRedirect();
        console.error("Error renewing token: ", error);
        // Log the user out if token renewal fails
        
      }
    }
  };


  // Set up an interval for the token check
  useEffect(() => {
    checkAndRenewToken(); // Check immediately
    
    const intervalId = setInterval(checkAndRenewToken, 15 * 60 * 1000); // Check every 15 minutes
    // const intervalId = setInterval(checkAndRenewToken, 15 * 1000); // Check every 15 seconds


    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [isLoading, getIdTokenClaims, logout, getAccessTokenSilently]);

  useEffect(() => {
    async function fetchData() {
      try {
        // Get the ID token from Auth0
        const idTokenClaims = await getIdTokenClaims();
        if (idTokenClaims === undefined || idTokenClaims === null) {
          console.log('ID token claims are undefined or null');
          // logout({ logoutParams: { returnTo: window.location.origin } });
        }
        const idToken = idTokenClaims.__raw;

        // console.log('idToken', idToken);

        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
        };
        const path = `/forum_list`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();

        const forumOptions = result.map(forum => ({
          value: forum.forum_id,
          label: forum.url,
        }));

        setForumIds(forumOptions);
        setSelectedForumId(forumOptions[0]?.value);
        setSelectForumName(forumOptions[0]?.label);
      } catch (error) {
        console.error('Error fetching forum list:', error);
      }
    }

    async function fetchChannels() {
      try {
        // Get the ID token from Auth0
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        // console.log("idToken", idToken);
         const options = {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${idToken}`,
             'Content-Type': 'application/json'
           },
         };
        // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
        const path = `/ask_a_question_channels`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        // console.log("result", result);
        if (result.length === 0) {
          setEptChannels("access_denied");
        }{
          setEptChannels(result);
        }          
         
         
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const forumData = async () => {
      // Only fetch data if it's not already loaded from local storage
      if (forumIds.length === 0) {
        // Fetch forumIds from API
        fetchData();
      }
      if (eptChannels.length === 0) {
        // Fetch eptChannels from API
        fetchChannels();
      }
    };


    // if (!isLoading && isAuthenticated && !redirectHandled) {
    //   const redirectPath = localStorage.getItem('redirectPath');
    //   if (redirectPath !== null && redirectPath !== window.location.pathname && redirectPath !== '') {
    //     window.location.href = redirectPath;
    //     localStorage.removeItem('redirectPath');
    //   }
    //   setRedirectHandled(true);
    // }

    if (!isLoading && isAuthenticated) {
      forumData();
    }
  }, [isLoading, getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    // Store eptChannels and forumIds to local storage whenever they change
    localStorage.setItem('eptChannels', JSON.stringify(eptChannels));
    localStorage.setItem('forumIds', JSON.stringify(forumIds));
  }, [eptChannels, forumIds]);

  useEffect(() => {
    // Store selectedForumId to local storage whenever it changes
    localStorage.setItem('selectedForumId', selectedForumId);
    localStorage.setItem('selectForumName', selectForumName);
  }, [selectedForumId, selectForumName]);

  const contextValue = {
    forumIds,
    selectedForumId,
    setSelectedForumId,
    selectForumName,
    setSelectForumName,
    eptChannels,
    checkAndRenewToken, // Expose the token renewal function
  };

  return (
    <ForumListContext.Provider value={contextValue}>
      {children}
    </ForumListContext.Provider>
  );
};

export const useForumList = () => {
  return useContext(ForumListContext);
};
