import React, { useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { toast } from "react-toastify";

function CodeTabs() {
  const [key, setKey] = useState('curl');

  const codeSnippets = {
    curl: `curl --request POST \\
  --url https://dev-t45vwan1ilnqniwu.us.auth0.com/oauth/token \\
  --header 'content-type: application/json' \\
  --data '{"client_id":"YOUR_CLIENT_ID","client_secret":"YOUR_CLIENT_SECRET","audience":"wss://ws.api.ept.ai","grant_type":"client_credentials"}'`,
    nodejs: `var request = require("request");

var options = { method: 'POST',
  url: 'https://dev-t45vwan1ilnqniwu.us.auth0.com/oauth/token',
  headers: { 'content-type': 'application/json' },
  body: '{"client_id":"YOUR_CLIENT_ID","client_secret":"YOUR_CLIENT_SECRET","audience":"wss://ws.api.ept.ai","grant_type":"client_credentials"}' };

request(options, function (error, response, body) {
  if (error) throw new Error(error);

  console.log(body);
});`,
    php: `$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "https://dev-t45vwan1ilnqniwu.us.auth0.com/oauth/token",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => "{\"client_id\":\"YOUR_CLIENT_ID\",\"client_secret\":\"YOUR_CLIENT_SECRET\",\"audience\":\"wss://ws.api.ept.ai\",\"grant_type\":\"client_credentials\"}",
  CURLOPT_HTTPHEADER => array(
    "content-type: application/json"
  ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}`,
    python: `import requests

url = "https://dev-t45vwan1ilnqniwu.us.auth0.com/oauth/token"

payload = {
    "client_id": "YOUR_CLIENT_ID",
    "client_secret": "YOUR_CLIENT_SECRET",
    "audience": "wss://ws.api.ept.ai",
    "grant_type": "client_credentials"
}
headers = {
    'content-type': 'application/json'
}

response = requests.post(url, json=payload, headers=headers)

print(response.text)`
  };

  const handleCopy = (language) => {
    navigator.clipboard.writeText(codeSnippets[language]);
    toast.success("Code copied to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
  };

  return (
    <div className="">
      <Tabs
        id="code-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="curl" title="CURL">
          <pre>
            <code>{codeSnippets.curl}</code>
          </pre>
          <Button className="btn btn-outline-secondary client-copy-btn" onClick={() => handleCopy('curl')}></Button>
        </Tab>
        <Tab eventKey="nodejs" title="NodeJS">
          <pre>
            <code>{codeSnippets.nodejs}</code>
          </pre>
          <Button className="btn btn-outline-secondary client-copy-btn" onClick={() => handleCopy('nodejs')}></Button>
        </Tab>
        <Tab eventKey="php" title="PHP">
          <pre>
            <code>{codeSnippets.php}</code>
          </pre>
          <Button className="btn btn-outline-secondary client-copy-btn" onClick={() => handleCopy('php')}></Button>
        </Tab>
        <Tab eventKey="python" title="Python">
          <pre>
            <code>{codeSnippets.python}</code>
          </pre>
          <Button className="btn btn-outline-secondary client-copy-btn" onClick={() => handleCopy('python')}></Button>
        </Tab>
      </Tabs>
    </div>
  );
}

export default CodeTabs;
