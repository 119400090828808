import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import logo from '../images/logo-black.svg';

function ResetPassword() {
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user was redirected from the forgot-password screen
        const isRedirectedFromForgotPassword =
          location.state && location.state.username;
          console.log(isRedirectedFromForgotPassword)
    
        if (!isRedirectedFromForgotPassword) {
          // Redirect the user back to the forgot-password screen
          navigate('/forgot-password');
        }
      }, [location.state, navigate]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if(verificationCode == ''){
        setShowAlert(true); // show the alert component
        setAlertMessage('Please enter the verification code.');
        setTimeout(function(){
            setShowAlert(false);
        },3000);
        return false;
      }
      if(password == ''){
        setShowAlert(true); // show the alert component
        setAlertMessage('Please enter password.');
        setTimeout(function(){
            setShowAlert(false);
        },3000);
        return false;
      }
      if(password < 8){
        setShowAlert(true); // show the alert component
        setAlertMessage('Password must be at least 8 characters long.');
        setTimeout(function(){
            setShowAlert(false);
        },3000);
        return false;
      }
      if(password != confirmPassword){
        setShowAlert(true); // show the alert component
        setAlertMessage('Password does not match.');
        setTimeout(function(){
            setShowAlert(false);
        },3000);
        return false;
      }
      setIsLoading(true); // set loading state to true
      try {
        await Auth.forgotPasswordSubmit(location.state.username, verificationCode, password);
        console.log('Password reset successfully.');
        setIsLoading(false); // set loading state to false
        setShowAlert(true); // show the alert component
        setAlertMessage('Password reset successfully.');
        setPassword(''); // clear the password input
        setConfirmPassword(''); // clear the confirm password input
        setTimeout(function(){
            navigate('/signin')
            window.location.reload();
        }, 3000)
      } catch (error) {
        setIsLoading(false); // set loading state to false
        console.log('Error resetting password', error);
        setShowAlert(true); // show the alert component
        setAlertMessage('Failed to reset password. Please recheck your verification code and try again.');
        // handle password reset error
      }
    };
  
    const handleAlertDismiss = () => {
      setShowAlert(false); // hide the alert component
    };

  return (
    <div>
      <div className="container">
        <div className="row">
          <form className="sign_form" id="signinform" onSubmit={handleSubmit}>
            <div className="col-12 form-group text-center">
              <img className="form-logo" src={logo} alt="logo" />
            </div>

            {showAlert && (
              <div className="col-12 form-group">
                <Alert variant={alertMessage === 'Password reset successfully.' ? 'success' : 'danger'} onClose={handleAlertDismiss} dismissible>
                  {alertMessage}
                </Alert>
              </div>
            )}
            <div className="col-12 form-group">
                <p>Hello, {location.state.username}! <br />Please check your email for the verification code.</p>
                <label htmlFor="verificationCode">Verification Code:</label>
                <input id="verificationCode" className="form-control" type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)}/>
            </div>
            <div className="col-12 form-group">
              <label htmlFor="password">New Password:</label>
              <input id="password" className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className="col-12 form-group">
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input id="confirmPassword" className="form-control" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            </div>
            <div className="col-12 form-group">
              <Button disabled={isLoading} as="input" type="submit" value={isLoading ? 'Submitting...' : 'Submit'} />{' '}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
