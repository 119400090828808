import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Table from "react-bootstrap/Table";
import SettingsLoader from "../../common/SettingsLoader";
import { useParams } from "react-router-dom";
import like_icon from "../../../images/like.svg";
import dislike_icon from "../../../images/dislike.svg";
import like_source from "../../../images/like_source.svg";
import dislike_source from "../../../images/dislike_source.svg";
import { apiPath } from "../../../config";
import { Grid, html, h } from "gridjs";
import ReactDOMServer from "react-dom/server";
import SourcesFeedbackGrid from "./SourcesFeedbackGrid";
import { _ } from "gridjs-react";
import SourcesActionGrid from "./SourcesActionGrid";


function SourcesGrid(props) {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState(props.sources || []);
  const { getIdTokenClaims, user } = useAuth0();
  const [userGroups, setUserGroups] = useState("");
    const gridRef = React.useRef(null);


  useEffect(() => {
    props.sources && setItems(props.sources);
  }, [props.sources]);

  useEffect(() => {
    if (user) {
      const roles = user?.ept_roles;
      if (roles && roles.includes("Admin")) {
        setUserGroups("Admin");
        // console.log("Admin role");
      }
    }
  }, [user]);
  
  const handleActionButtons = (e) => {
    e.preventDefault();
    let ele = e.target;
    let action_btns = ele.nextElementSibling;

    // Check if the display property is either "none" or not set (empty string).
    if (action_btns.style.display === "block") {
      action_btns.style.display = "none";
    } else {
      // If it's "none" or empty, set it to "block"
      action_btns.style.display = "block";
    }
  };

  document.addEventListener("click", function (e) {
    //   e.preventDefault();
    if (e.target.className !== "action_btn_dots") {
      let action_btns = document.querySelectorAll(".action_btns");
      action_btns.forEach(function (ele) {
        ele.style.display = "none";
      });
    }
  });

  const handleActions = (e) => {
    e.preventDefault();
    let ele = e.target;
    let action = ele.getAttribute("data-action");
    let item_url = ele.getAttribute("data-item-url");
    let ks_id = ele.getAttribute("data-ks-id");
    const mainEle = ele.closest("tr");
    // let action_btns = ele.closest('.action_btns');
    // let action_btn_dots = action_btns.previousElementSibling;
    // action_btns.style.display = 'none';
    // action_btn_dots.style.display = 'block';
    if (action == "boost") {
      // boost item
      console.log("boost item");
      handleActionsAsync(item_url, "boosted", ks_id, mainEle);
    } else if (action == "unboost") {
      // unboost item
      console.log("unboost item");
      handleActionsAsync(item_url, "", ks_id, mainEle);
    } else if (action == "block") {
      // block item
      console.log("block item");
      handleActionsAsync(item_url, "blocked", ks_id, mainEle);
    } else if (action == "unblock") {
      // unblock item
      console.log("unblock item");
      handleActionsAsync(item_url, "", ks_id, mainEle);
    }
  };

  const handleActionsAsync = async (url, reqType, ks_id, mainEle) => {
    try {
      const idToken = await getIdTokenClaims();
      const bodyParse = {
        tags: [reqType],
        url: url,
      };
      const parseJson = JSON.stringify(bodyParse);
      const path = `/knowledge_source/${ks_id}/tags`;
      const response = await fetch(`${apiPath()}${path}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken.__raw}`,
          "Content-Type": "application/json",
        },
        body: parseJson,
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      console.log(data);
      if(data.message === "ok"){
        const element = mainEle;
        if(reqType === "boosted"){
          element.querySelector("[data-column-id='tags']").innerHTML = "<span class='boosted'>Boosted</span>";
          element.querySelector("[data-column-id='action']").classList.add("show-unboosted");
          element.querySelector("[data-column-id='action']").classList.remove("show-unblocked");
        }
        else if(reqType === "blocked"){
          element.querySelector("[data-column-id='tags']").innerHTML = "<span class='blocked'>Blocked</span>";
          element.querySelector("[data-column-id='action']").classList.add("show-unblocked");
          element.querySelector("[data-column-id='action']").classList.remove("show-unboosted");
        }
        else{
          element.querySelector("[data-column-id='tags']").innerHTML = "";
          element.querySelector("[data-column-id='action']").classList.remove("show-unboosted");
          element.querySelector("[data-column-id='action']").classList.remove("show-unblocked");
        }
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      // setIsLoadingItems(false);
    }
  };

  const handleRanking = async (e) => {
    e.preventDefault();
    let ele = e.target;
    let value = ele.getAttribute("data-value");
    let response_id = ele.getAttribute("data-id");
    let url = ele.getAttribute("data-url");
    // url = encodeURIComponent(url);
    // console.log("value-", value, "response_id-", response_id, "url-", url)
    if(value === "like"){
      const likes = await props.sendFeedback(response_id, true, url);
      console.log(likes);
      if(likes){
        if(likes.likes > 0){
          ele.closest("td").querySelector(".item-likes").innerHTML = likes.likes;
          ele.closest("td").querySelector(".item-dislikes").innerHTML = likes.dislikes;
          if(likes.user_feedback === "like"){
            ele.closest("td").querySelector(".table-like-img").src = like_source;
            ele.closest("td").querySelector(".table-dislike-img").src = dislike_icon;
          }
        }
      }
    }else {
      const dislikes = await props.sendFeedback(response_id, false, url);
        console.log(dislikes);
      if(dislikes){
        if(dislikes.dislikes > 0){
          ele.closest("td").querySelector(".item-dislikes").innerHTML = dislikes.dislikes;
          ele.closest("td").querySelector(".item-likes").innerHTML = dislikes.likes;
          if(dislikes.user_feedback === "dislike"){
            ele.closest("td").querySelector(".table-dislike-img").src = dislike_source;
            ele.closest("td").querySelector(".table-like-img").src = like_icon;
          }
        }
      }
    }
  }

  const initializeGrid = async () => {
  
    try {
      // Destroy existing Grid.js instance if it exists
      if (gridRef.current) {
        gridRef.current.destroy();
        gridRef.current = null; // Reset the grid reference
      }
  
      // Initialize the Grid.js table
      const grid = new Grid({
        columns: [
            {
                id: "title",
                name: html(`<span style="max-width:300px; width:300px !important; display:block;">Title</span>`),
                formatter: (cell, row) => {
                    return html(`
                        <div style="max-width: 300px; width:100%;">

                            <span>${cell}</span>
                        </div>
                    `);
                }
            },
            {
                id: "ks_type",
                name: "Type",
                formatter: (cell, row) => {
                    return html(`<span>${cell}</span>`);
                }
            },
            {
                id: "tags",
                name: html(`<span style="min-width:100px; display:block;"></span>`),
                formatter: (cell, row) => {
                    if (cell.includes("boosted")) {
                        return html(`<span class="boosted">Boosted</span>`);
                    } else if (cell.includes("blocked")) {
                        return html(`<span class="blocked">Blocked</span>`);
                    }
                }
            },
            {
                id: "uses",
                name: html(`<span style="min-width:100px; display:block;">Uses</span>`),
            },
            {
                id: "ks_id",
                name: html(`<span style="min-width:100px; display:block;">KS ID</span>`),
                formatter: (cell, row) => {
                    return html(`<a href="/settings/ks-details/${cell}">${cell}</a>`);
                }
            },
            {
                id: "url",
                name: "URL",
                formatter: (cell, row) => {
                    return html(`
                            <div style="max-width: 300px; width:300px; word-break:break-all;">
                            <a href="${cell}" target="_blank">${cell}</a>
                            </div>
                        `);
                }
            },
            {
                id: "feedback",   
                name: html(`<span style="min-width:100px; display:block;"></span>`),                                        
            },
            {
                id: "action",
                name: html(`<span style="min-width:80px; display:block;">Actions</span>`),
            }
                       
        ],
        data: items.map((item) => ({
          title: item.title,
          ks_type: item.ks_type,
          tags: item.tags,
          url: item.url,
          uses: item.uses,
          ks_id: item.ks_id,
          feedback:  _(<SourcesFeedbackGrid 
            item={item} 
            handleRanking={handleRanking} 
            />),
            action: _(<SourcesActionGrid
                item={item}
                userGroups={userGroups}
                handleActionButtons={handleActionButtons}
                handleActions={handleActions}
            />)
        }))
      });
  
      grid.render(document.getElementById("grid-wrapper"));
      gridRef.current = grid;
    } catch (error) {
      console.error("Error initializing grid:", error);
    }
  };
  

  useEffect(() => {
    initializeGrid();
  }, [items, userGroups]);

  return (
    <div className="sources-wrap">
      <div className="item-filters">
        <div className="filters-title">Answer sources</div>
        
      </div>
        <div id="grid-wrapper"></div>
    </div>
  );
}

export default SourcesGrid;
