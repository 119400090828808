import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../config";

function ResponseForm(props) {
  const { getIdTokenClaims } = useAuth0();
  const cancelToken = useRef(null);
  const [irrelevant, setIrrelevant] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  const [questionNotAnswered, setQuestionNotAnswered] = useState(0);
  const [offensive, setOffensive] = useState(0);
  const [comments, setComments] = useState("");
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [isUpdatingFromProps, setIsUpdatingFromProps] = useState(true);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [threadId, setThreadId] = useState('');
  const [likeDislikeCount, setLikeDislikeCount] = useState(0);

  useEffect(() => {
    setIrrelevant(props.ranking.irrelevant || 0);
    setIncorrect(props.ranking.incorrect || 0);
    setQuestionNotAnswered(props.ranking.question_not_answered || 0);
    setOffensive(props.ranking.offensive || 0);
    setLikes(props.ranking.thumbs_up || 0);
    setDislikes(props.ranking.thumbs_down || 0);
    setComments(props.ranking.text || "");
    setLikeDislikeCount(props.ranking.count || 0);
  }, [props.ranking]);
  

  const postRanking = async (likebtn) => {
    try {
      let postData = {};
      if(likebtn) {
        if(likes === 1) {
            postData = {
                response_id: props.responseId,
                ratings: {
                  "irrelevant": Number(0),
                  "incorrect": Number(0),
                  "question_not_answered": Number(0),
                  "offensive": Number(0),
                  "thumbs_up": Number(0),
                  "thumbs_down": Number(0),
                  text: "",
                  "date": new Date().toISOString(),
                  "count":likeDislikeCount+1,
                },
              };
              setLikes(0);
        }else {
          postData = {
            response_id: props.responseId,
            ratings: {
              "irrelevant": Number(0),
              "incorrect": Number(0),
              "question_not_answered": Number(0),
              "offensive": Number(0),
              "thumbs_up": Number(1),
              "thumbs_down": Number(0),
              text: "",
              "date": new Date().toISOString(),
              "count":likeDislikeCount+1,
            },
          };
          setLikes(1);
        }
       
            setThreadId(props.threadId);
            setIrrelevant(0);
            setComments("");
            setDislikes(0);
            setIncorrect(0);
            setOffensive(0);
            setQuestionNotAnswered(0);
      }else {
        postData = {
            response_id: props.responseId,
            ratings: {
              "irrelevant": Number(irrelevant),
              "incorrect": Number(incorrect),
              "question_not_answered": Number(questionNotAnswered),
              "offensive": Number(offensive),
              "thumbs_up": Number(likes),
              "thumbs_down": Number(dislikes),
              text: comments,
              "date": new Date().toISOString(),
              "count":likeDislikeCount+1,
            },
          };
      }
      setLikeDislikeCount(likeDislikeCount+1);
      postData = JSON.stringify(postData);
      console.log(postData)
      const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
      //   console.log('idToken-', idToken);
    
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
          body: postData,
        };

      const rankPath = `/response/ranking`;
      const url = `${apiPath()}${rankPath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const rankResult = await response.json();
      console.log(rankResult);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleOutsideClick = (e) => {
    const wrap = document.getElementById("response-wrap");
    const isClickedInsideForm = wrap.contains(e.target);
    // Check if the click target is inside the form
    if (!isClickedInsideForm) {
      hideForm();

      // Remove the click event listener from the document
      document.removeEventListener("click", handleOutsideClick);
    }
  };

  const showForm = () => {
    const form = document.getElementById("responseFormAC");
    form.style.display = "block";
  };
  const hideForm = () => {
    const form = document.getElementById("responseFormAC");
    form.style.display = "";
  };
  const handleLikeBtn = (e) => {
    e.preventDefault();
    setDislikes(0);
    postRanking(true);
    
    // Add click event listener to document
    // document.addEventListener("click", handleOutsideClick);
  };
  const handleDislikeBtn = (e) => {
    e.preventDefault();
    showForm();
    setLikes(0);
    setDislikes(1);
    document.addEventListener("click", handleOutsideClick);
  };
  const handleResponse = async (e) => {
    e.preventDefault();
    try {
      await postRanking();
      setSubmissionStatus('success'); // Set the submission status to 'success'
      setTimeout(() => {setSubmissionStatus('')}, 3000); // Wait 3 seconds
      hideForm();
    } catch (error) {
      console.error('Error posting data:', error);
      setSubmissionStatus('error'); // Set the submission status to 'error' if an error occurs
      hideForm();
    }
  };

  const handleIrrelevantChange = (e) => {
    setIsUpdatingFromProps(false);
    setIrrelevant(e.target.checked ? 1 : 0);
  };
  
  const handleIncorrectChange = (e) => {
    setIsUpdatingFromProps(false);
    setIncorrect(e.target.checked ? 1 : 0);
  };
  
  const handleQuestionNotAnsweredChange = (e) => {
    setIsUpdatingFromProps(false);
    setQuestionNotAnswered(e.target.checked ? 1 : 0);
  };
  
  const handleOffensiveChange = (e) => {
    setIsUpdatingFromProps(false);
    setOffensive(e.target.checked ? 1 : 0);
  };

  const handleCommentsChange = (e) => {
    setIsUpdatingFromProps(false);
    setComments(e.target.value);
  };

  useEffect(() => {

  }, [likes, dislikes]);

  return (
    <div id="response-wrap" className="response-form-wrap">
      <div className="like-dislike-wrap">
        <a className={`like-btn ${likes == 1 ? 'active' : ''}`} href="#" onClick={(e) => handleLikeBtn(e)}></a>
        <a
          className={`dislike-btn ${dislikes == 1 ? 'active' : ''}`}
          href="#"
          onClick={(e) => handleDislikeBtn(e)}
        ></a>
      </div>
      <form
        id="responseFormAC"
        className="rankingForm"
        onSubmit={(e) => {
          handleResponse(e);
        }}
      >
        <div className="col-xs-12 custom-response-checkbox">
          <label htmlFor="irrelevant">
            <span className="checkbox-label">Irrelevant</span>
            <input
              type="checkbox"
              name="irrelevant"
              id="irrelevant"
              checked={irrelevant}
              onChange={handleIrrelevantChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-xs-12 custom-response-checkbox">
          <label htmlFor="incorrect">
            <span className="checkbox-label">Incorrect</span>
            <input
              type="checkbox"
              name="incorrect"
              id="incorrect"
              checked={incorrect}
              onChange={handleIncorrectChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-xs-12 custom-response-checkbox">
          <label htmlFor="question-not-answered">
            <span className="checkbox-label">Question not answered</span>
            <input
              type="checkbox"
              name="question-not-answered"
              id="question-not-answered"
              checked={questionNotAnswered}
              onChange={handleQuestionNotAnsweredChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-xs-12 custom-response-checkbox">
          <label htmlFor="offensive">
            <span className="checkbox-label">Offensive</span>
            <input
              type="checkbox"
              name="offensive"
              id="offensive"
              checked={offensive}
              onChange={handleOffensiveChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-xs-12 text-area-wrap">
          <textarea
            id="comments"
            placeholder="Write your comments here!"
            value={comments}
            onChange={handleCommentsChange}
          >
          </textarea>
        </div>
        <div className="col-xs-12 response-form-btn">
          {/* <input
            className="btn cancel-btn"
            onClick={hideForm}
            type="button"
            value="Cancel"
          /> */}
          <input className="btn submit-btn" type="submit" value="Submit" />
        </div>
      </form>
      <div className="break"></div>
      {submissionStatus === 'success' && <p className="response-success">Ranking submitted successfully!</p>}
      {submissionStatus === 'error' && <p>Error submitting the Ranking. Please try again.</p>}
    </div>
  );
}

export default ResponseForm;
