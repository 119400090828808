import React from "react";
import { Row, Col } from "react-bootstrap";
import SalesDataList from "./SalesDataList";


function CompaniesSections(props) {
    const {competitor, salesGuide} = props;
    return (
        <Row>
            <Col>
                <h3>Company</h3>
                <SalesDataList 
                    products={salesGuide.company_products}
                 />
            </Col>
            <Col>
                <h3>{competitor.value}</h3>
                <SalesDataList
                    products={salesGuide.competitor_products}
                 />
            </Col>
        </Row>
    );
}

export default CompaniesSections;